import React, { useState, useEffect, useCallback } from 'react';
import './style/last4NumberDigitsInput.scss';
import classNames from "classnames";
const Last4NumberDigitsInput = ({ onChange, isError, isPlaceholderHidden = false, }) => {
    const [digits, setDigits] = useState([null, null, null, null]);
    useEffect(() => {
        onChange && onChange(digits.join(''));
        return;
    }, [digits]);
    return (React.createElement("div", { className: 'digit-list' }, digits.map((digit, index) => (React.createElement(Digit, { key: `digit_${index}`, index: index, setDigits: setDigits, digits: digits, isError: isError, isPlaceholderHidden: isPlaceholderHidden })))));
};
const Digit = ({ digits, setDigits, index, isError, isPlaceholderHidden }) => {
    const onChange = useCallback((evtChange) => {
        var _a;
        const target = evtChange.target;
        if (isNaN(target.value))
            return;
        const newDigits = [...digits];
        if (target.value > 9 || ((_a = target.value.split('')) === null || _a === void 0 ? void 0 : _a.length) > 1)
            target.value = digits[index];
        newDigits[index] = target.value;
        setDigits(newDigits);
        if (index < 3)
            target.nextSibling.focus();
    }, [digits, setDigits]);
    const onKeyDown = useCallback((evtKeyDown) => {
        var _a;
        const { target, keyCode } = evtKeyDown;
        if (keyCode === 8) {
            evtKeyDown.stopPropagation();
            evtKeyDown.preventDefault();
            if (digits[index] === null) {
                (_a = target.previousSibling) === null || _a === void 0 ? void 0 : _a.focus();
            }
            else {
                const newDigits = [...digits];
                newDigits[index] = null;
                setDigits(newDigits);
                target.value = "";
            }
        }
    }, [digits, setDigits]);
    return React.createElement("input", { type: "text", onChange: onChange, autoFocus: index === 0, style: { animationDelay: `${index * 50}ms` }, onKeyDown: onKeyDown, placeholder: isPlaceholderHidden ? '' : '0', className: classNames('digit', { 'digit_error': isError }), value: digits[index] || '' });
};
export default React.memo(Last4NumberDigitsInput);
