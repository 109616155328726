import React, { PureComponent, SyntheticEvent } from 'react';
import localize, { t } from '../../../localization';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  getVerificationCode,
  checkVerificationCode,
  getCallsAvailability,
  setCurrentUserPhone,
} from '../../../actions/currentUser.actions';
import { GA } from '../../../pipes/pureFunctions';

import { Modal, Banner, PhoneNumber, Last4NumberDigitsInput, FormFeedback } from '@just-ai/just-ui';
import './style/confirmNumberModal.scss';
import { getUserLanguage } from '../../../pipes/pureFunctions';
import { getCountryList } from '../../PhoneNumberInput/CountryList';
import { formatNumberValue } from '../../PhoneNumberInput/PhoneNumberInput';
import ConfirmationTimer from '../ConfirmationTimer';
import { confirmnumberLocalization } from '../localization/confirmnumber.loc';
import { isEuroInstance } from '../../../isAccessFunction';
import Recaptcha from '../../Recaptcha';
import classes from '../../../views/TemplateWizard/CheckPhoneNumber/CheckPhoneNumber.module.scss';
import PhoneVerificationService from '../service';
import { BeError } from '../../../types';
import { isAxiosError } from '../../../pipes/typeGuards';
import ResendTimer from './ResendTimer';

localize.addTranslations(confirmnumberLocalization);

interface IPhoneNumber {
  code: string;
  number: string;
}

interface ICurrentUser {
  phoneNumberNational: {
    countryCode?: number;
    nationalNumber?: number;
  };
}

interface ConfirmNumberModalState {
  open: boolean;
  okDisabled: boolean;
  step: number;
  failedVerification: boolean;
  phoneNumber: IPhoneNumber;
  verificationCode: string;
  errors: Array<string>;
  errorData: string;
  showSpinner: boolean;
  success: boolean;
  gRecaptchaResponse: any;
  gRecaptchaError: boolean;

  isResendCodeEnabled: boolean;
  smsCode: string;
  smsResendExceeded: boolean;
  dontReceiveSms: boolean;
  isSendSmsCodeButtonDisabled: boolean;
  changePhoneSuccess: boolean;
  addPhoneSuccess: boolean;
}

interface ConfirmNumberModalProps {
  currentUser: ICurrentUser;
  open?: boolean;
  onClose: () => void;
  onSuccess: () => void;
  actions: any;
  customMessageText?: string;
  error?: string;
  errors?: Array<string>;
  appConfig: any;
}

class ConfirmNumberModal extends PureComponent<ConfirmNumberModalProps, ConfirmNumberModalState> {
  timer: ConfirmationTimer | null = null;
  phone?: HTMLElement;
  recaptchaInstance: any = null;

  PhoneVerificationService = new PhoneVerificationService();

  initialState = {
    open: false,
    okDisabled: true,
    step: 1,
    failedVerification: false,
    verificationCode: '',
    errors: [],
    errorData: '',
    gRecaptchaResponse: null,
    gRecaptchaError: false,

    phoneNumber: {
      code: isEuroInstance() ? '1' : '7',
      number: '',
    },
    isResendCodeEnabled: false,
    smsCode: '',
    smsResendExceeded: false,
    dontReceiveSms: false,
    isSendSmsCodeButtonDisabled: false,
    changePhoneSuccess: false,
    addPhoneSuccess: false,
  };

  state = {
    ...this.initialState,
    showSpinner: false,
    success: false,
  };

  open = () => {
    this.setState({
      open: true,
    });
  };

  componentDidMount() {
    const { currentUser } = this.props;

    if (this.props.open) {
      this.open();
    }
    if (currentUser?.phoneNumberNational) {
      const { countryCode, nationalNumber } = currentUser.phoneNumberNational;

      const code = countryCode ? String(countryCode) : '7';
      const number = nationalNumber ? String(nationalNumber) : '';

      this.setState({
        phoneNumber: {
          code,
          number,
        },
      });
    }
  }

  componentDidUpdate = (prevProps: ConfirmNumberModalProps, prevState: ConfirmNumberModalState) => {
    if (!prevState.open && this.state.open && this.phone !== undefined) {
      this.setState({
        open: true,
      });
      this.phone.focus();
    }
    if (!prevProps.open && this.props.open) {
      this.open();
    }
  };

  beforeClose = () => {
    this.setState(this.initialState);
  };

  close = () => {
    this.beforeClose();
    this.props.onClose && this.props.onClose();
  };

  onSuccess = () => {
    this.beforeClose();
    this.props.onSuccess && this.props.onSuccess();
  };

  onChangePhoneNumber = (phoneNumber: IPhoneNumber) => this.setState({ phoneNumber, errors: [] });

  onChangeVerificationCode = (verificationCode: string) => this.setState({ verificationCode, errors: [] });

  onFirstStepSubmit = async (e?: SyntheticEvent) => {
    const { reCaptchaEnabled } = this.props.appConfig;
    e?.preventDefault();

    GA('GAEvent', 'wizard_generate_code', 'next');

    const { phoneNumber } = this.state;

    let phone = phoneNumber.code + phoneNumber.number;
    phone = phone.replace(/\(|\)|\s|-/g, '');

    await this.executeCaptcha();
    try {
      this.setState({ showSpinner: true, errors: [] });
      await this.PhoneVerificationService.sendSmsCodeForPhoneVerification({ phone }, this.state.gRecaptchaResponse);
      this.setState({
        errors: [],
        step: 2,
      });
    } catch (error) {
      if (reCaptchaEnabled) {
        this.resetCaptcha();
      }

      if (isAxiosError(error)) {
        const errorData = error.response?.data;

        if (errorData.error === 'accountsadmin.smscode.too.many.attempts') {
          this.setState({ smsResendExceeded: true });
        }

        const errors = errorData.errors || [errorData];
        this.setState({
          errors: errors,
        });
      }
    }
    this.setState({ showSpinner: false });
  };

  onSecondStepSubmit = async (e?: SyntheticEvent) => {
    e?.preventDefault();

    GA('GAEvent', 'wizard_verify_phone', 'confirm');
    const {
      phoneNumber: { code, number },
      verificationCode,
    } = this.state;

    let phone = code + number;
    phone = phone.replace(/\(|\)|\s|-/g, '');

    if (verificationCode.length < 4) return;

    try {
      this.setState({ showSpinner: true, errors: [] });
      await this.PhoneVerificationService.verifySmsCode({ code: verificationCode, phone: phone });
      this.props.actions.setCurrentUserPhone(phone);
      await this.props.actions.getCallsAvailability();
      this.onSuccess();
    } catch (error) {
      if (isAxiosError(error)) {
        const errorData = error.response?.data;
        if (errorData.error === 'accountsadmin.smscode.too.many.attempts') {
          this.setState({ smsResendExceeded: true });
        } else {
          if (
            errorData.error === 'accountsadmin.smscode.send.again' ||
            errorData.error === 'accountsadmin.smscode.enter.again'
          ) {
            this.setState({
              isResendCodeEnabled: true,
            });
          }
          if (errorData.error === 'accountsadmin.smscode.send.again' || errorData.args?.remainingAttempts === 0) {
            this.setState({ isSendSmsCodeButtonDisabled: true });
          }

          const errors = errorData.errors || [errorData];
          this.setState({
            errors: errors,
          });
        }
      }
    }
    this.setState({ showSpinner: false });
  };

  onSubmit = (e?: SyntheticEvent) => {
    e?.preventDefault();
    const { step } = this.state;
    switch (step) {
      case 1:
        return this.onFirstStepSubmit(e);
      case 2:
        return this.onSecondStepSubmit(e);
    }
  };

  inputAnotherNumber = () =>
    this.setState({
      okDisabled: true,
      step: 1,
      failedVerification: false,
      verificationCode: '',
      errors: [],
    });

  onTimerFinish = () => {
    this.setState({
      errorData: '',
      errors: [],
      isResendCodeEnabled: true,
    });
  };

  resendSms = async () => {
    await this.onFirstStepSubmit();
    this.setState({
      isResendCodeEnabled: false,
      isSendSmsCodeButtonDisabled: false,
    });
  };

  verifyCallback = async (response: any) => {
    GA('GAEvent', 'captcha', 'success');
    await this.setState({
      gRecaptchaResponse: response,
      gRecaptchaError: false,
    });
  };

  executeCaptcha = async () => {
    const { reCaptchaEnabled } = this.props.appConfig;
    const { gRecaptchaResponse } = this.state;
    if (reCaptchaEnabled && this.recaptchaInstance && !gRecaptchaResponse) {
      await this.recaptchaInstance.execute();
    }
  };

  resetCaptcha = () => {
    if (Boolean(this.recaptchaInstance)) {
      this.recaptchaInstance.reset();
      this.setState({ gRecaptchaResponse: null }, async () => {
        await this.executeCaptcha();
      });
    }
  };

  onloadCallback = () => {
    GA('GAEvent', 'captcha', 'shown');
    setTimeout(this.executeCaptcha, 0);
  };

  renderCaptcha = () => {
    const {
      appConfig: { fetched, reCaptchaEnabled, reCaptchaSiteKey },
    } = this.props;

    if (!fetched || !reCaptchaEnabled) return null;

    return (
      <div className='modal__captcha'>
        <Recaptcha
          data-test-id='BasePage.recaptcha'
          ref={instance => (this.recaptchaInstance = instance)}
          className={classes.captcha}
          sitekey={reCaptchaSiteKey}
          size='invisible'
          // @ts-ignore
          verifyCallback={this.verifyCallback}
          onloadCallback={this.onloadCallback}
          expiredCallback={this.resetCaptcha}
          hl={getUserLanguage().substr(0, 2).toUpperCase()}
        />
      </div>
    );
  };

  renderPhoneFieldError = () => {
    const { errors } = this.state;

    return errors.length > 0 ? (
      <>
        {(errors as BeError[]).map(error => {
          let newError = { ...error };
          const remainingAttempts = newError.args?.remainingAttempts;
          if (remainingAttempts === 0) {
            newError.error = 'accountsadmin.smscode.send.again';
          }
          const remainingAttemptsString = localize.decliner(
            ['ConfirmNumber:Attempts:1', 'ConfirmNumber:Attempts:2', 'ConfirmNumber:Attempts:5'],
            remainingAttempts
          );

          return t(
            `ConfirmNumber:BeError:${newError['error']}`,
            newError.error === 'common.error.internal' ? error.uuid : t(remainingAttemptsString, remainingAttempts)
          );
        })}
      </>
    ) : null;
  };

  render() {
    const {
      open,
      phoneNumber: { code, number },
      errors,
      step,
      failedVerification,
      errorData,
      isResendCodeEnabled,
    } = this.state;
    const clearNumber = number.replace(/\D/g, '');

    const isBtnSubmitDisabled = Boolean(
      !code ||
        (code == '7' && clearNumber.length < 10) ||
        clearNumber.length < 4 ||
        failedVerification ||
        (errorData && parseInt(errorData, 10) > 0)
    );

    const lang = getUserLanguage();
    const countryList = getCountryList(lang, isEuroInstance());

    const phoneLocalization = `+ ${code} ${formatNumberValue(code, number)}`;

    return (
      <Modal
        className='ConfirmNumberModal'
        isOpen={open}
        title={t('ConfirmNumber title')}
        onCancelClick={this.close}
        onActionClick={this.onSubmit}
        buttonCancelText={t('Cancel')}
        buttonSubmitText={t('ConfirmNumber confirm')}
        buttonSubmitDisabled={isBtnSubmitDisabled}
        disableActionButtonAutoFocus
        zIndex={1400}
      >
        {step === 1 && (
          <>
            <div className='modal__info'>
              {t('ConfirmNumber initial message', t(isEuroInstance() ? 'tovi' : 'aimy'))}
            </div>
            <div className='modal__banner'>
              <Banner type='info' BannerText={() => t('ConfirmNumber input tooltip')} withIcon />
            </div>
            <div className='modal__phone'>
              <div className='modal__phone-wrapper'>
                <PhoneNumber
                  onChange={this.onChangePhoneNumber}
                  phoneNumber={{ code, number }}
                  placeholderSearch='Search'
                  error={errors.length > 0}
                  selectFixedPosition={true}
                  countryList={countryList}
                  focused={errors.length === 0}
                />
              </div>

              <FormFeedback tag='div' valid={false}>
                {errors?.length > 0 && this.renderPhoneFieldError()}
              </FormFeedback>
            </div>
          </>
        )}

        {step === 2 && (
          <div className='modal__verification'>
            <div className='modal__check-number-info'>
              {t('ConfirmNumber:SmsCode:SendText', phoneLocalization)}
              <span onClick={this.inputAnotherNumber}> {t('ConfirmNumber:SmsCode:ChangeNumber')}</span>
            </div>

            <div className='modal__attempt-info'>
              {t('ConfirmNumber:SmsCode:ContactUs')}{' '}
              <a href={`mailto:${isEuroInstance() ? 'support@ds.tovie.ai' : 'support@just-ai.com'}`}>
                {isEuroInstance() ? 'support@ds.tovie.ai' : 'support@just-ai.com'}
              </a>
            </div>

            <div className='modal__verification-inputs'>
              <Last4NumberDigitsInput onChange={this.onChangeVerificationCode} isError={false} isPlaceholderHidden />
              <FormFeedback tag='div' valid={false} style={{ display: 'block' }}>
                <>
                  {!isResendCodeEnabled ? (
                    <small className='text-muted'>
                      <ResendTimer
                        finishTimer={this.onTimerFinish}
                        timer={60}
                        attemptsCount={4}
                        timerText='ConfirmNumber:SmsCode:HelperText'
                        compact
                      />
                    </small>
                  ) : (
                    <small className='resend-text'>
                      <span onClick={this.resendSms}>{t('ConfirmNumber:ResendSms')}</span>
                    </small>
                  )}
                </>
              </FormFeedback>
            </div>
            <FormFeedback tag='div' valid={false} style={{ display: 'block' }}>
              {this.renderPhoneFieldError()}
            </FormFeedback>
          </div>
        )}
        {this.renderCaptcha()}
      </Modal>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: bindActionCreators(
    {
      getVerificationCode,
      checkVerificationCode,
      getCallsAvailability,
      setCurrentUserPhone,
    },
    dispatch
  ),
});

function mapStateToProps(state: any) {
  return {
    showConfirmNumberModal: state.CurrentUserReducer.showConfirmNumberModal,
    currentUser: state.CurrentUserReducer.currentUser,
    appConfig: { ...state.AppConfigReducer },
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmNumberModal);
