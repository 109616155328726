import React, { useCallback, useEffect, useState } from 'react';
//@ts-ignore
import { Button, AtreidesIcon } from 'altreidsds';
import localize from 'localization/index';
import classNames from 'classnames';
import history from '../../../appHistory';

//@ts-ignore
import { Modal } from 'altreidsds';
import mobileClasses from './MobileHeader.module.scss';
import classes from './NewHeader.module.scss';
import { formatNumberToCurrency } from '@just-ai/formatutils';
import { getMinutesInfo } from '../../../views/PersonalSettings/utils/getMinutesInfo';
import { isEuroInstance } from '../../../isAccessFunction';
import { RCurrentUser } from '../../../types';

import { getPaymentTextAndDates } from '../../../views/PersonalSettings/TariffOptions/utils';

const { translate: t } = localize;

export default function TariffInfo({
  tariff,
  isMobile,
  language,
}: {
  tariff: RCurrentUser['currentUser']['tariff'];
  language: string;
  isMobile?: boolean;
}) {
  const { limitsData } = tariff || {};

  const { uniqueUsers, asrSeconds, justaiSeconds, nluSeconds, sms } = limitsData || {
    uniqueUsers: {},
    asrSeconds: {},
    justaiSeconds: {},
    nluSeconds: {},
    sms: {},
  };

  const [showModal, setShowModal] = useState(false);

  const [blockPopupOnAppear, setBlockPopupOnAppear] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => setBlockPopupOnAppear(false), 1000);
    return () => clearTimeout(timer);
  }, []);

  const asrSecondsTotal = limitsData ? asrSeconds.tariffLimit + asrSeconds.extraLimit - asrSeconds.usedLimit : 0;
  const nluSecondsTotal = limitsData ? nluSeconds.tariffLimit + nluSeconds.extraLimit - nluSeconds.usedLimit : 0;

  const mixedSecondsTotal = asrSecondsTotal >= nluSecondsTotal ? nluSecondsTotal : asrSecondsTotal;
  const justaiSecondsTotal = justaiSeconds.tariffLimit + justaiSeconds.extraLimit - justaiSeconds.usedLimit;

  const toPrices = useCallback(() => history.push('/personal?tab=0'), []);

  const { paymentText, paymentDateString, limitsUpdateDateString } = getPaymentTextAndDates(tariff, language);

  const tariffAdditionalInfo = (
    <>
      <span>
        <span>{t('Header: plan')} </span>
        <span className={classes.blackText}>{t(tariff?.tariffName)}</span>
      </span>
      {!tariff.manualControl && (
        <span className={classes.tariffPrice}>
          {tariff?.price !== 0
            ? t(
                'TariffConnectionModal month price',
                `${formatNumberToCurrency({
                  value: tariff?.price,
                  currencyCode: tariff?.paymentSystem === 'STRIPE' ? 'eng' : 'ru',
                })}`
              )
            : t('TariffInfo tariff free')}
        </span>
      )}
      {limitsUpdateDateString && (
        <span className={classes.slimText}>
          {t('TariffInfo:LimitsUpdateDate')}: {limitsUpdateDateString}
        </span>
      )}
      <div className={classes.delimiter} />
      <span className={classes.slimText}>{t('Uniq clients')}</span>
      <span style={{ marginBottom: 24 }} className={classes.blackText}>
        {uniqueUsers.tariffLimit >= uniqueUsers.usedLimit
          ? (uniqueUsers.tariffLimit - uniqueUsers.usedLimit + uniqueUsers.extraLimit).toLocaleString('ru-RU')
          : 0}
      </span>
      <span className={classes.slimText}>{t('Header popover mixed minutes')}</span>
      <span style={{ marginBottom: 24 }} className={classes.blackText}>
        {getMinutesInfo(mixedSecondsTotal)}
      </span>
      {!isEuroInstance() && (
        <>
          <span className={classes.slimText}>{t('Header popover justai minutes')}</span>
          <span className={classes.blackText} style={{ marginBottom: 24 }}>
            {getMinutesInfo(justaiSecondsTotal)}
          </span>
        </>
      )}
      <span className={classes.slimText}>{t('TariffLimitsTable sms')}</span>
      <span className={classes.blackText}>
        {(sms.tariffLimit + sms.extraLimit - sms.usedLimit).toLocaleString('ru-RU')}
      </span>
      <div className={classes.delimiter} />
      <Button className={classes.button} onClick={toPrices} variant='contained' color='primary' size='small'>
        {t('Tariff management')}
      </Button>
    </>
  );

  return (
    <div className={classNames(classes.paymentContainer, isMobile && classes.mobilePaymentContainer)}>
      <button
        className={classNames(
          classes.paymentText,
          tariff?.price === 0 && !tariff?.manualControl && classes.freeTariff,
          isMobile && classes.mobilePaymentText
        )}
        onClick={() => setShowModal(true)}
      >
        {`${paymentText} ${paymentDateString}`}
        <AtreidesIcon color='default' size='small' name='selectdown' />
      </button>
      {isMobile ? (
        <Modal
          open={showModal}
          className={mobileClasses.popoverContainer}
          classes={{
            modal: mobileClasses.modal,
            modalContent: classNames(
              mobileClasses.modalContent,
              mobileClasses.tariffs,
              classes.tariffPopup,
              classes.mobilePopup
            ),
            modalIconClose: mobileClasses.modalIconClose,
          }}
          onClose={() => setShowModal(false)}
        >
          {tariffAdditionalInfo}
        </Modal>
      ) : (
        <div className={classNames(classes.tariffPopup, classes.popup, blockPopupOnAppear && classes.blocked)}>
          {tariffAdditionalInfo}
        </div>
      )}
    </div>
  );
}
