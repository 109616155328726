var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useState, useRef, useCallback, useEffect, useMemo } from 'react';
import { InputText } from '../InputText';
import { Dropdown, DropdownItem, DropdownMenu } from '../Dropdown';
import './InputWithDropdown.scss';
import { useOnClickOutside } from '../utils';
export function InputWithDropdown(_a) {
    var { dropdownOptions, value, onChange, onOptionClick, menuPositionFixed = false, dontClearOnBlur, 'data-test-id': dataTestId, noMatchesMessage, fetching, forceHideDropdown, showAllOptionsWhenValueIsEmpty } = _a, restProps = __rest(_a, ["dropdownOptions", "value", "onChange", "onOptionClick", "menuPositionFixed", "dontClearOnBlur", 'data-test-id', "noMatchesMessage", "fetching", "forceHideDropdown", "showAllOptionsWhenValueIsEmpty"]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [rect, setRect] = useState(null);
    const ref = useRef(null);
    useOnClickOutside(ref, () => {
        if (isDropdownOpen) {
            setIsDropdownOpen(false);
            !dontClearOnBlur && onChange && onChange('');
        }
    });
    const handleClick = useCallback((option) => {
        onOptionClick && onOptionClick(option);
        setIsDropdownOpen(false);
    }, [onChange]);
    const getRect = (node) => {
        if (node) {
            const coords = node.getBoundingClientRect();
            setRect(coords);
        }
    };
    useEffect(() => {
        getRect(ref.current);
    }, [isDropdownOpen]);
    const filteredDropdownOptions = useMemo(() => {
        if (showAllOptionsWhenValueIsEmpty && !value) {
            return dropdownOptions;
        }
        return value
            ? dropdownOptions.filter(option => option.label.toLowerCase().includes(value.trim().toLowerCase()))
            : [];
    }, [dropdownOptions, showAllOptionsWhenValueIsEmpty, value]);
    const menuStyle = menuPositionFixed
        ? {
            position: 'fixed',
            top: rect ? rect.y + rect.height + 'px' : 0,
            left: (rect === null || rect === void 0 ? void 0 : rect.x) + 'px',
            width: (rect === null || rect === void 0 ? void 0 : rect.width) + 'px',
            maxHeight: rect ? window.innerHeight - rect.bottom - 16 + 'px' : 'none',
            overflow: 'auto',
        }
        : {
            maxHeight: rect ? window.innerHeight - rect.bottom - 16 + 'px' : 'none',
            overflow: 'auto',
        };
    return (React.createElement("div", { className: 'justui_input-with-dropdown', ref: ref },
        React.createElement(InputText, Object.assign({}, restProps, { "data-test-id": dataTestId, onFocus: () => setIsDropdownOpen(true), onChange: onChange, value: value })),
        filteredDropdownOptions && (value || showAllOptionsWhenValueIsEmpty) && !fetching && !forceHideDropdown && (React.createElement(Dropdown, { isOpen: isDropdownOpen, toggle: () => { }, className: 'fixedDropdown', style: menuStyle }, filteredDropdownOptions.length > 0 ? (React.createElement(DropdownMenu, null, filteredDropdownOptions.map(option => (React.createElement(DropdownItem, { key: `option_${option.value}`, onClick: () => handleClick(option), "data-test-id": `${dataTestId}.MenuItem.${option.label}` }, option.label))))) : (noMatchesMessage && (React.createElement(DropdownMenu, null,
            React.createElement(DropdownItem, { "data-test-id": `${dataTestId}.MenuItem.NoMatches` }, noMatchesMessage))))))));
}
