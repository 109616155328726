var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Icon } from '../Icon';
import './style.scss';
export const Badge = (_a) => {
    var { text, color, pastel, iconLeft, iconRight, className, id, onClick } = _a, props = __rest(_a, ["text", "color", "pastel", "iconLeft", "iconRight", "className", "id", "onClick"]);
    return (React.createElement("div", { id: id || '', "data-test-id": props['data-test-id'], className: `justui_badge ${className !== null && className !== void 0 ? className : ''}`, style: {
            backgroundColor: pastel ? `var(--${color}-200)` : `var(--${color}-600)`,
            color: pastel ? `var(--${color}-800)` : 'var(--white)',
        }, onClick: onClick },
        iconLeft && React.createElement(Icon, { className: 'iconLeft', size: 'sm', name: iconLeft }),
        React.createElement("span", null, text),
        iconRight && React.createElement(Icon, { className: 'iconRight', size: 'sm', name: iconRight })));
};
