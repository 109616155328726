import axios from 'axios';
import { dropCurrentUser } from '../actions/currentUser.actions';
import { dropCurrentLoginAccount } from '../actions/accounts.actions';
import store from '../store';
import history from '../appHistory';
import localize from '../localization';
import { getUserLanguage } from './pureFunctions';
import moment from 'moment';

export const returnTariffCurrencySymbol = currency => {
  switch (currency) {
    case 'RUB':
      return '₽';
    case 'USD':
      return '$';
    default:
      return '€';
  }
};

let axiosWithSessionCheck = axios.create({
  headers: {
    language: getUserLanguage().substr(0, 2).toUpperCase(),
    Product: 'aimylogic',
    'Accept-Language': getUserLanguage().substr(0, 2).toLocaleLowerCase(),
  },
  validateStatus: status => {
    if (status === 401) {
      store.dispatch(dropCurrentUser());
      store.dispatch(dropCurrentLoginAccount());
      window.location.href = `/c/login&redirectUrl=${window.location.href}`;
    } else if (status === 403) {
      if (window.location.pathname.includes('/knowledge') === false) {
        history.push({
          pathname: '/pages/403',
        });
      }
      return false;
    } else if (status === 503) {
      history.push({
        pathname: '/503',
      });
      return false;
    } else if (status === 404) {
      if (history.location.pathname.startsWith('/dialogs')) {
        localStorage.removeItem('@clientDialogs');
      } else {
        history.push({
          pathname: '/404',
        });
      }
      return false;
    }
    return status >= 100 && status < 300;
  },
});

axiosWithSessionCheck.interceptors.request.use(request => {
  let modifiedRequest = { ...request };
  modifiedRequest.url = modifiedRequest.url.replace(/\.\.\//g, '');
  return modifiedRequest;
});

axiosWithSessionCheck.interceptors.response.use(response => {
  const { data, config } = response;
  if (typeof data === 'string' && data.endsWith('</html>')) {
    console.log(
      '%cBACKEND / NGINX ERROR!',
      'color: red; font-family: sans-serif; font-size: 4.5em; font-weight: bolder; text-shadow: #000 1px 1px;'
    );
    console.log(
      `%crequest ${config.method.toUpperCase()} to ${config.url} returns html`,
      'color: red; font-family: sans-serif; font-size: 2em; font-weight: normal; text-shadow: #000 1px 1px;'
    );
    return Promise.reject(`BACKEND/NGINX ERROR: request ${config.method.toUpperCase()} to ${config.url} returns html`);
  }
  return response;
});

axiosWithSessionCheck._get = (url, options) => {
  return new Promise((resolve, reject) => {
    axiosWithSessionCheck
      .get(url, options)
      .then(response => {
        response.config = { ...response.config, ...options };
        resolve(response);
      })
      .catch(reject);
  });
};
const postPutDeleteInterceptor = method => (url, data, config) => {
  return new Promise((resolve, reject) => {
    axiosWithSessionCheck[method](url, data, config)
      .then(response => {
        response.config = { ...response.config, ...config };
        resolve(response);
      })
      .catch(reject);
  });
};
axiosWithSessionCheck._post = postPutDeleteInterceptor('post');
axiosWithSessionCheck._put = postPutDeleteInterceptor('put');
axiosWithSessionCheck._delete = postPutDeleteInterceptor('delete');

export function setAxiosHeadersLanguage(val) {
  axiosWithSessionCheck.defaults.headers.language = val;
  axiosWithSessionCheck.defaults.headers['Accept-Language'] = val.toLocaleLowerCase();
}

export const CancelToken = axios.CancelToken;

export { axiosWithSessionCheck as axios };

export function timeoutPromise(timeout, err, promise) {
  return new Promise((resolve, reject) => {
    promise.then(resolve, reject);
    setTimeout(reject.bind(null, err), timeout);
  });
}

export const getUserValidName = (firstName, lastName, email, fullName) => {
  let computedName = '';
  if (!!firstName && !fullName) {
    computedName += firstName + ' ';
  }
  if (!!lastName && !fullName) {
    computedName += lastName;
  }
  if (fullName) {
    computedName = fullName;
  }
  if (computedName === '' && !!email) {
    computedName += email;
  }
  if (computedName === '') {
    computedName += localize.translate('No data specified');
  }
  return computedName;
};

export function startAnimation(callback) {
  window.requestAnimationFrame =
    window.requestAnimationFrame ||
    window.mozRequestAnimationFrame ||
    window.webkitRequestAnimationFrame ||
    window.msRequestAnimationFrame;
  const requestAnimationFrame = window.requestAnimationFrame;

  requestAnimationFrame(() => {
    requestAnimationFrame(() => {
      callback();
    });
  });
}

export const isAdvancedUpload = (function () {
  const div = document.createElement('div');
  return (
    ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
  );
})();

export const detectSelection = () => {
  let txt = '';
  if (window.getSelection) {
    txt = window.getSelection().toString();
  } else {
    // IE, используем объект selection
    txt = document.selection.createRange().text;
  }
  return txt;
};

export function flattenObject(ob) {
  let toReturn = {};

  for (let i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] === 'object') {
      let flatObject = flattenObject(ob[i]);
      for (let x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '.' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export const removeWidgetCode = () => {
  const hide = async () => {
    window.JustWidget ? await window.JustWidget.unmount() : await (() => true)();
  };

  hide().then(() => {
    window.webpackJsonpJustWidget = [];
    let instances = document.querySelectorAll('[data-origin="justwidget"]');
    if (instances.length > 0) {
      for (let i = 0; i < instances.length; i += 1) {
        instances[i].remove();
      }
    }
    let domStarter = document.getElementById('justwidget');
    if (domStarter) {
      domStarter.remove();
    }
    let boundary = document.getElementsByClassName('justwidget')[0];
    if (boundary) {
      boundary.remove();
    }

    window.JustWidget = undefined;
  });
};

export const getWidgetTestMode = botType => {
  if (botType === 'CALLS_BOT') {
    return 'call';
  }

  return 'simple';
};

export const saveWidgetModeToLocalStorage = (projectShortName, mode) => {
  let widgetTestModeData = { ...JSON.parse(localStorage.getItem('WIDGET_TEST_MODE')) };
  widgetTestModeData[projectShortName] = mode;
  localStorage.setItem('WIDGET_TEST_MODE', JSON.stringify(widgetTestModeData));
};

export const getClosest = (elem, selector) => {
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (elem.matches(selector)) return elem;
  }
  return null;
};

export const stringHashCode = str => {
  let hash = 0;
  if (!Boolean(str) || (Boolean(str) && str.length === 0)) {
    return hash;
  } else {
    for (let i = 0; i < str.length; i++) {
      const chr = str.charCodeAt(i);
      hash = (hash << 5) - hash + chr;
      hash |= 0; // Convert to 32bit integer
    }
  }

  return hash;
};

export const checkTariffRestrictions = currentUser => {
  return !(Boolean(currentUser) && Boolean(currentUser.tariff));
};

export const isAdvancedUploadCommon = (function () {
  const div = document.createElement('div');
  return (
    ('draggable' in div || ('ondragstart' in div && 'ondrop' in div)) && 'FormData' in window && 'FileReader' in window
  );
})();

export const blendColors = (c0, c1, p) => {
  let f = parseInt(c0.slice(1), 16),
    t = parseInt(c1.slice(1), 16),
    R1 = f >> 16,
    G1 = (f >> 8) & 0x00ff,
    B1 = f & 0x0000ff,
    R2 = t >> 16,
    G2 = (t >> 8) & 0x00ff,
    B2 = t & 0x0000ff;
  return (
    0x1000000 +
    (Math.round((R2 - R1) * p) + R1) * 0x10000 +
    (Math.round((G2 - G1) * p) + G1) * 0x100 +
    (Math.round((B2 - B1) * p) + B1)
  )
    .toString(16)
    .slice(1);
};

export const getDateOfPayment = (startDate, dueDate, blocked = false, language = 'ru', customFormat = null) => {
  const locale = language.substring(0, 2);
  const format = Boolean(customFormat) ? customFormat : locale === 'en' ? 'MMMM, D' : 'D MMMM';

  const dateOfPaymentMoment = blocked ? moment(startDate).utc() : moment(dueDate).utc();
  const nowMoment = moment().utc();
  return nowMoment > dateOfPaymentMoment ? nowMoment.format(format) : dateOfPaymentMoment.format(format);
};

export const getDateOfDemoEnd = (startDate, dueDate, blocked = false, locale = 'ru', customFormat = null) => {
  const format = Boolean(customFormat) ? customFormat : locale === 'en' ? 'MMMM, D' : 'D MMMM';

  const dateOfPaymentMoment = moment(dueDate);

  return dateOfPaymentMoment.format(format);
};

export const isCISCountryISOCode = code =>
  ['RU', 'BY', 'AZ', 'AM', 'KZ', 'KG', 'TJ', 'TM', 'UA', 'UZ', 'LV', 'LT', 'EE', 'GE', 'MD'].includes(code);

export * from './pureFunctions';
