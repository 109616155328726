import React from 'react';
import localize from 'localization';
import { InputText, JustSelect } from '@just-ai/just-ui';
import BaseEditForm from '../BaseEditForm';
import classes from '../BaseEditForm/BaseEditForm.module.scss';
import axios from 'axios';

const { translate: t } = localize;

export default class LivetexVisitorEditForm extends BaseEditForm {
  constructor(props) {
    super(props);

    this.state = {
      apiKey: this.props.editableChannel.config?.apiKey || '',
      departmentList: [],
      department: '',
      textPlaceholder: 'Channels:LivetexVisitor:NoDepartmentsAvailable',
      timeoutId: null,
      apiKeyError: '',
      isInvalidToken: false,
    };
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.apiKey === this.state.apiKey) return;

    if (this.state.timeoutId) {
      clearTimeout(this.state.timeoutId);
    }
    const newTimeoutId = setTimeout(this.getDestinations, 1000);
    this.setState({ timeoutId: newTimeoutId });
  }

  componentWillMount() {
    if (this.props.isEdit) {
      this.setState({
        apiKey: this.props.editableChannel.customData.apiKey,
        department: '',
      });
    }
  }

  componentDidMount() {
    this.getDestinations();
  }

  getDestinations = async () => {
    const { editableChannel } = this.props;

    if (!!this.state.apiKey) {
      this.setState({
        textPlaceholder: 'Channels:LivetexVisitor:DownloadDepartments',
      });

      try {
        await axios
          .get(`/api/chatadapter/channels/livetex_visitor/getDestinations?key=${this.state.apiKey}`)
          .then(data => {
            if (data.data.departments?.length > 0) {
              this.setState({
                departmentList: data.data.departments.map(({ id, name }) => ({
                  value: id,
                  label: name,
                })),
                department: editableChannel.id ? editableChannel?.config?.destinations : data.data.departments[0].id,
                textPlaceholder: '',
                apiKeyError: '',
                isInvalidToken: false,
              });
            } else {
              this.setState({
                departmentList: [],
                department: '',
                textPlaceholder: 'Channels:LivetexVisitor:DefaultDepartment',
                apiKeyError: '',
                isInvalidToken: false,
              });
            }
            this.props.hideValidationErrors([t('Channels:LivetexVisitor:InvalidToken')]);
          });
      } catch (error) {
        if (error.response?.data.error === 'chatadapter.messaging.livetex_visitor_invalid_response_body') {
          this.setState({
            departmentList: [],
            department: '',
            textPlaceholder: 'Channels:LivetexVisitor:NoDepartmentsAvailable',
            apiKeyError: 'Channels:LivetexVisitor:InvalidToken',
            isInvalidToken: true,
          });
        }
      }
    }
  };

  validate = () => {
    const { apiKey, isInvalidToken } = this.state;
    const { name } = this.form;

    const apiKeyError = !apiKey
      ? 'Channels:LivetexVisitor:RequiredField'
      : isInvalidToken
      ? this.state.apiKeyError
      : '';
    const channelNameError = !name.value ? t('Channels:LivetexVisitor:RequiredField') : '';

    this.setState({ apiKeyError, channelNameError });

    return Boolean(apiKey && name.value && !isInvalidToken);
  };

  createChannel = async () => {
    if (!this.validate()) return;

    const channelData = {
      type: this.props.editableChannel.channelType,
      description: this.form.name.value,
      config: {
        apiKey: this.state.apiKey,
        destinations: this.state.department,
      },
    };

    await this.props.createChannel(channelData);
    this.props.onClose();
  };

  saveChannel = async () => {
    if (!this.validate()) return;

    const channelData = {
      ...this.props.editableChannel,
      description: this.form.name.value,
      config: {
        apiKey: this.state.apiKey,
        destinations: this.state.department,
      },
    };

    await this.props.editChannel(channelData);
    this.props.onClose();
  };

  renderInputs() {
    const { apiKeyError, departmentList, department, textPlaceholder } = this.state;
    return (
      <>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('Access key')}</p>
          <InputText
            id='access_key'
            errorText={t(apiKeyError)}
            defaultValue={this.state.apiKey}
            onChange={value => this.setState({ apiKey: value && value.trim() })}
            placeholder={t('Enter custom', t('Access key').toLowerCase())}
          />
        </div>
        <div className={classes.formControl}>
          <p className={classes.label}>{t('Channels:LivetexVisitor:Department')}</p>
          <JustSelect
            id='access_key'
            fullWidth
            options={departmentList}
            onChange={department => this.setState({ department })}
            inputPlaceholder={t(textPlaceholder)}
            position='fixed'
            disabled={!departmentList?.length}
            value={department}
          />
          <small className='gray-600'>{t('Channels:LivetexVisitor:Department:SupText')}</small>
        </div>
      </>
    );
  }
}
