import React, { useEffect, useMemo } from 'react';
import classes from './Dashboard.module.scss';
import './DashboardStatic.scss';
import { BotType } from './dashboardTypes';
import { BotTypeType } from 'types';
import NoBotsPlaceholder from './Components/NoBotsPlaceholder';
import Bots from './Components/Bots';
import localize from 'localization';

import { Locale } from '@just-ai/just-ui';
import Templates from './Components/Templates';
import { useTagsContext } from './TagsContext';
import StudioBanner, { TypeEnum } from './Components/StudioBanner';
import classNames from 'classnames';
import { isEuroInstance } from '../../isAccessFunction';
import ChatGPTBanner from '../../components/ChatGPTBanner';

const { translate: t } = localize;

interface DashboardInterface {
  botList: BotType[];
  botType: BotTypeType;
  createBot: (values: any, isIVR?: boolean) => void;
  selectBot: (shortName: string) => void;
  cloneBot: (shortName: string) => void;
  deleteBot: (shortName: string) => void;
  finalDelete: (id: number) => void;
  showCreateModal: () => void;
  language: Locale;
  showHelp?: boolean;
  countryIsoCode?: string;
}

export default function Dashboard({
  botList,
  language,
  selectBot,
  botType = 'CHAT_BOT',
  cloneBot,
  deleteBot,
  finalDelete,
  createBot,
  showCreateModal,
  showHelp,
  countryIsoCode,
}: DashboardInterface) {
  const filteredBotList = useMemo<BotType[]>(() => {
    return botList.filter(x => (x.botType || 'CHAT_BOT') === botType);
  }, [botList, botType]);

  const { getAccountAllTags } = useTagsContext();
  useEffect(() => {
    getAccountAllTags();
  }, [getAccountAllTags]);

  return (
    <div className={classNames(classes.container, 'DashboardContainer')}>
      <h2 className={classes.botLabel}>
        {t(
          filteredBotList?.length > 0 ? `Dashboard: main label ${botType}` : `Dashboard: main label no bots`,
          t(isEuroInstance() ? 'tovi' : 'aimy')
        )}
      </h2>
      <div className={classes.bots}>
        {filteredBotList?.length > 0 ? (
          <Bots
            botType={botType}
            showCreateModal={showCreateModal}
            finalDelete={finalDelete}
            countryIsoCode={countryIsoCode}
            cloneBot={cloneBot}
            deleteBot={deleteBot}
            botList={filteredBotList}
            selectBot={selectBot}
            locale={language.substr(0, 2) as 'en'}
          />
        ) : (
          <>
            {botType === 'CHAT_BOT' && <ChatGPTBanner className='margin-bottom-6x' />}
            <NoBotsPlaceholder botType={botType} showCreateModal={showCreateModal} showHelp={showHelp} />
            <StudioBanner countryIsoCode={countryIsoCode} type={TypeEnum.DASHBOARD} />
          </>
        )}
      </div>
      {/* @ts-ignore */}
      <Templates createBot={createBot} botType={botType} />
    </div>
  );
}
