import React from 'react';
import { useLocation } from 'react-router';
function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
}
const OAuthCallback = function () {
    var _a;
    const query = useQuery();
    (_a = window.opener) === null || _a === void 0 ? void 0 : _a.postMessage({ type: 'OAuthResponse', payload: Object.fromEntries(query.entries()) }, window.opener.location);
    return React.createElement("div", null, "Processing...");
};
export default OAuthCallback;
