import React, { useState, useEffect, useRef } from 'react';
import './ImgUpload.scss';
import { Icon } from "../Icon";
import classNames from 'classnames';
function ImgUpload({ progress, uploading = false, onChange, imgUrl }) {
    const [img, setImg] = useState('');
    const fileReader = (file) => {
        const reader = new FileReader();
        reader.onload = () => {
            setImg(reader.result);
        };
        file && reader.readAsDataURL(file);
    };
    const handleChange = (e) => {
        var _a, _b;
        const file = (_b = (_a = e === null || e === void 0 ? void 0 : e.target) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
        fileReader(file);
        onChange && onChange(file);
    };
    const handleDrop = (e) => {
        var _a, _b;
        e.preventDefault();
        const file = (_b = (_a = e === null || e === void 0 ? void 0 : e.dataTransfer) === null || _a === void 0 ? void 0 : _a.files) === null || _b === void 0 ? void 0 : _b[0];
        fileReader(file);
        onChange && onChange(file);
    };
    const handleDragOver = (e) => {
        e.preventDefault();
    };
    return (React.createElement("div", { className: 'justui_img-upload' },
        React.createElement("span", { className: classNames('justui_img-upload-container', { 'has-img': img || imgUrl, 'uploading': uploading }), style: { backgroundImage: `url(${imgUrl || img})` }, onDrop: handleDrop, onDragOver: handleDragOver },
            React.createElement("label", null,
                !uploading
                    ? React.createElement(Icon, { name: 'faUpload', color: 'primary' })
                    : null,
                React.createElement("input", { className: 'hidden', onChange: handleChange, type: "file", accept: "image/*" }))),
        uploading && progress && progress > 0
            ? React.createElement(CircularProgress, { rawPercent: progress })
            : null));
}
function CircularProgress({ rawPercent }) {
    const [percent, setPercent] = useState(0);
    const circle = useRef(null);
    const setPercentVal = () => {
        var _a;
        if (circle) {
            let value = rawPercent;
            if (rawPercent < 0)
                value = 0;
            if (rawPercent > 100)
                value = 100;
            const radius = parseInt((_a = circle === null || circle === void 0 ? void 0 : circle.current) === null || _a === void 0 ? void 0 : _a.getAttribute('r'), 10);
            if (radius) {
                const circumference = Math.PI * (radius * 2);
                const percent = ((100 - value) / 100) * circumference;
                setPercent(percent);
            }
        }
    };
    useEffect(setPercentVal, [rawPercent]);
    return (React.createElement("div", { id: "justui_circular-progress", "data-percent": rawPercent },
        React.createElement("svg", { id: "svg", width: "144", height: "144", "view-port": "0 0 72 72", version: "1.1", xmlns: "http://www.w3.org/2000/svg" },
            React.createElement("circle", { ref: circle, r: "68", cx: "72", cy: "72", fill: "transparent", strokeDasharray: "427.25", strokeDashoffset: "0", style: { strokeDashoffset: percent } }))));
}
export default ImgUpload;
