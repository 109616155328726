import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import localize from '../../localization';
import history from '../../appHistory';
import { AppBar, Toolbar, withStyles } from 'altreidsds';
import { withRouter } from 'react-router-dom';
import { checkTariffRestrictions } from '../../pipes/functions';
import { checkOnlyRussia, GA } from '../../pipes/pureFunctions';
import isAccess, { isEuroInstance, isIncluded } from '../../isAccessFunction';
import CollapsedBotList, { ToggleCollapsedBotList } from './BotList';

import styles from './styles';
import NewHeader from './NewHeaderComponents/NewHeader';
import { headerLocalization } from './localization/header.loc';
import { BASE_WIZARD_PATH } from '../../modules/TemplatesWizard/shared/consts';

localize.addTranslations(headerLocalization);

class Header extends Component {
  state = {
    searchPanelActive: false,
    openMenu: false,
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.location.pathname !== nextProps.location.pathname) {
      this.setState({
        openMenu: false,
        anchorEl: null,
      });
    }
  }

  toggleMenu = () => {
    if (!this.state.openMenu) {
      if (this.props.location.pathname === `/scenario/${this.props.currentProject}`) {
        GA('GAEvent', 'bots_dropdown', 'opened');
      }
    }
    this.setState({ openMenu: !this.state.openMenu });
  };

  closeMenu = async name => {
    const { botList, location, currentProject: prevCurrentProject } = this.props;
    this.setState({ openMenu: false });
    const currentBot = botList.find(bot => bot.shortName === name);
    if (currentBot) {
      await this.props.setCurrentProject(name, currentBot);

      GA('GAEvent', 'bots_dropdown', 'existing_selected');

      history.push(location.pathname.replace(prevCurrentProject, name));
    }
  };

  toggleAddingBot = () => {
    GA('GAEvent', 'bots_dropdown', 'new_created');

    this.toggleMenu();
    history.push({
      pathname: BASE_WIZARD_PATH + `${window.location.pathname.includes('callsbots') ? '/calls-type-select' : ''}`,
      state: {
        isCallBot: window.location.pathname.includes('callsbots'),
      },
    });
  };

  isBotChoosen = () => {
    const pathname = this.props.location.pathname;

    if (pathname.includes('chatwidgetEditor')) return false;

    return (
      this.props.currentProject &&
      ![`/personal`, '/callsbots', '/prices', '/'].includes(pathname) &&
      !(pathname.includes('templatewizard') || pathname.includes('startwizard'))
    );
  };

  isTopMenuShown = () => {
    const pathname = this.props.location.pathname;

    if (pathname.includes('templatewizard') || pathname.includes('startwizard')) {
      return true;
    }

    return (
      [
        '/',
        '/callsbots',
        '/contract',
        '/prices',
        '/personal',
        '/botDevelopmentRequest',
        '/partner',
        '/404',
        '/403',
        '/503',
      ].includes(pathname) ||
      pathname.startsWith('/subscription') ||
      pathname.startsWith(BASE_WIZARD_PATH)
    );
  };

  render() {
    const {
      open,
      currentProject,
      botList,
      currentUser,
      appConfig,
      classes,
      language,
      location: { pathname },
      addSnackbar,
      cloudDomains,
      universalLoginMenuEnabled,
    } = this.props;
    const { openMenu } = this.state;

    const chatwidgetRedactorPage = pathname.includes('chatwidgetEditor');

    const { tariff } = currentUser || {};

    const locale = language.substring(0, 2);

    if (this.isTopMenuShown())
      return (
        <NewHeader
          dataTestId='Header'
          pathname={pathname}
          tariff={currentUser?.tariff || {}}
          avatar={currentUser?.avatarUrl}
          email={currentUser?.email || ''}
          login={currentUser?.login || ''}
          manualControl={currentUser?.tariff?.manualControl}
          logout={this.props.logout}
          logo={appConfig.zenflow.logo}
          language={currentUser?.language}
          isBotsEnabled={isAccess(['ALL'])}
          isCallsEnabled={isAccess(['ALL']) && isIncluded(['zenflow_telephony_feature'])}
          isPricesEnabled={isAccess(['ALL']) && isIncluded(['zenflow_billing_feature'])}
          isPartnerEnabled={
            isIncluded(['zenflow_partner_feature']) && (!checkOnlyRussia({ tariff }) || isEuroInstance())
          }
          isHelpLinkEnabled={Boolean(appConfig.zenflow.showHelp)}
          isTariffEnabled={checkTariffRestrictions(currentUser) == false && isIncluded(['zenflow_billing_feature'])}
          isPartner={currentUser?.account?.partner}
          isOrderBotEnabled={
            pathname !== '/botDevelopmentRequest' && !chatwidgetRedactorPage && !checkOnlyRussia(currentUser)
          }
          addSnackbar={addSnackbar}
          manualControlData={tariff?.manualControlData}
          cloudDomains={cloudDomains}
          universalLoginMenuEnabled={universalLoginMenuEnabled}
          locale={locale}
        />
      );

    return (
      <div id='header' style={{ position: 'relative', height: 57 }}>
        <AppBar key='app_header' color='default' className={classNames(classes.appBar, open && classes.appBarShift)}>
          <Toolbar disableGutters={!open} classes={{ root: classes.toolbar }}>
            {this.isBotChoosen() && (
              <ToggleCollapsedBotList
                pathname={pathname}
                toggleMenu={this.toggleMenu}
                classes={classes}
                openMenu={openMenu}
                botList={botList}
                currentProject={currentProject}
              />
            )}
            <div id='headerBoundPlace' className={classes.headerBoundPlace} />
          </Toolbar>
          <CollapsedBotList
            botList={botList}
            openMenu={openMenu}
            classes={classes}
            toggleAddingBot={this.toggleAddingBot}
            closeMenu={this.closeMenu}
            currentProject={currentProject}
          />
        </AppBar>
      </div>
    );
  }
}

Header.propTypes = {
  theme: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
};

export default withRouter(withStyles(styles, { withTheme: true })(Header));
