import moment from 'moment';
import { getDateOfPayment } from '../../../pipes/functions';
import { t } from '../../../localization';
import { RCurrentUser } from '../../../types';

export const getClearedTariffName = (tariffName: string) => {
  if (!tariffName) return '';
  return tariffName.indexOf('_archived') === -1 ? tariffName : tariffName.slice(0, tariffName.indexOf('_archived'));
};

export const getPaymentTextAndDates = (tariff: RCurrentUser['currentUser']['tariff'], locale: string) => {
  const manualPeriodStartDate = tariff?.manualControlData?.from ? moment(tariff?.manualControlData?.from).utc() : null;
  const nowDate = moment().utc();
  const isManualPeriodExists = manualPeriodStartDate;
  const isManualPeriodStarted = manualPeriodStartDate && nowDate >= manualPeriodStartDate;

  let paymentText = t('Paid before');
  let dueDate = moment(tariff?.dueDate).utc().subtract(1, 'days');
  let limitsUpdateDate = null;

  if (tariff?.price === 0 && !isManualPeriodStarted) {
    paymentText = t('TariffInfo:LimitsUpdateDate');
    dueDate = moment(dueDate).add(1, 'days').utc();
  }

  if (isManualPeriodStarted && tariff?.manualControlData?.periodType === 'FREE') {
    paymentText = t('TariffInfo:FreePeriodUntil');
  }

  if (isManualPeriodExists && tariff?.price === 0) {
    limitsUpdateDate = manualPeriodStartDate > dueDate ? dueDate : manualPeriodStartDate;
  }

  if (isManualPeriodExists && tariff?.price !== 0) {
    limitsUpdateDate = manualPeriodStartDate > dueDate ? moment(dueDate).add(1, 'days').utc() : manualPeriodStartDate;
  }

  if (isManualPeriodStarted && tariff?.manualControlData?.periodType === 'FREE' && tariff?.manualControlData?.to) {
    limitsUpdateDate = moment(tariff?.manualControlData?.to).utc();
  }

  if (
    isManualPeriodStarted &&
    tariff?.manualControlData?.periodType === 'MANUAL_CONTROL' &&
    tariff?.manualControlData?.to
  ) {
    dueDate = moment(tariff?.manualControlData?.to).utc().subtract(1, 'days');
    limitsUpdateDate = moment(tariff?.dueDate).utc();
  }

  const limitsUpdateDateString =
    limitsUpdateDate &&
    getDateOfPayment(
      tariff?.startDate,
      limitsUpdateDate,
      tariff?.blocked,
      locale,
      // @ts-ignore
      locale === 'EN' ? 'MM.DD.YYYY' : 'DD.MM.YYYY'
    );

  const paymentDateString = getDateOfPayment(
    tariff?.startDate,
    dueDate,
    tariff?.blocked,
    locale,
    // @ts-ignore
    locale === 'EN' ? 'MM.DD.YYYY' : 'DD.MM.YYYY'
  );

  return { paymentText, paymentDateString, limitsUpdateDateString };
};
