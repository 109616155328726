export var newprojectoverviewLocalization = {
    'ProjectOverview:Title': {
        eng: 'Project overview',
        ru: 'Обзор проекта',
    },
    'ProjectOverview:Filters:7Days': {
        eng: 'Week',
        ru: 'Неделя',
    },
    'ProjectOverview:Filters:30Days': {
        eng: 'Month',
        ru: 'Месяц',
    },
    'ProjectOverview:Filters:6Months': {
        eng: 'Six months',
        ru: 'Полгода',
    },
    'ProjectOverview:Filters:YourPeriod': {
        eng: 'Custom period',
        ru: 'Свой период',
    },
    'ProjectOverview:Filters:Day': {
        eng: 'Daily',
        ru: 'По дням',
    },
    'ProjectOverview:Filters:Week': {
        eng: 'Weekly',
        ru: 'По неделям',
    },
    'ProjectOverview:Filters:Month': {
        eng: 'Monthly',
        ru: 'По месяцам',
    },
    'ProjectOverview:Filters:Apply': {
        eng: 'Apply',
        ru: 'Применить',
    },
    'ProjectOverview:Filters:AllChannels': {
        eng: 'All channels',
        ru: 'Все каналы',
    },
    'ProjectOverview:Filters:RemoteChannels': {
        eng: 'Removed channels',
        ru: 'Удаленные каналы',
    },
    'ProjectOverview:Indicators:Title': {
        eng: 'Metrics',
        ru: 'Показатели',
    },
    'ProjectOverview:Indicators:users': {
        eng: 'Users',
        ru: 'Пользователи',
    },
    'ProjectOverview:Indicators:newUsers': {
        eng: 'New users',
        ru: 'Новые пользователи',
    },
    'ProjectOverview:Indicators:sessions': {
        eng: 'Sessions',
        ru: 'Сессии',
    },
    'ProjectOverview:Indicators:messages': {
        eng: 'Inbound messages',
        ru: 'Входящие сообщения',
    },
    'ProjectOverview:Indicators:Tooltip': {
        eng: '$[1] $[2]',
        ru: '$[1] $[2]',
    },
    'ProjectOverview:UserActivity:Title': {
        eng: 'Users',
        ru: 'Пользователи',
    },
    'ProjectOverview:UserActivity:OnAverage': {
        eng: 'on average </br> each day',
        ru: 'в среднем </br> каждый день',
    },
    'ProjectOverview:UserActivity:NewOnes': {
        eng: 'new ones every </br> day',
        ru: 'новых каждый </br> день',
    },
    'ProjectOverview:UserActivity:Checkbox:NewUsers': {
        eng: 'New',
        ru: 'Новые',
    },
    'ProjectOverview:UserActivity:Checkbox:Users': {
        eng: 'Total',
        ru: 'Всего',
    },
    'ProjectOverview:Indicators:users_one': {
        eng: 'user',
        ru: 'пользователь',
    },
    'ProjectOverview:Indicators:users_few': {
        eng: 'users',
        ru: 'пользователя',
    },
    'ProjectOverview:Indicators:users_many': {
        eng: 'users',
        ru: 'пользователей',
    },
    'ProjectOverview:Indicators:users_other': {
        eng: 'users',
        ru: 'пользователей',
    },
    'ProjectOverview:Indicators:newUsers_one': {
        eng: 'new user',
        ru: 'новый пользователь',
    },
    'ProjectOverview:Indicators:newUsers_few': {
        eng: 'new users',
        ru: 'новых пользователя',
    },
    'ProjectOverview:Indicators:newUsers_many': {
        eng: 'new users',
        ru: 'новых пользователей',
    },
    'ProjectOverview:Indicators:newUsers_other': {
        eng: 'new users',
        ru: 'новых пользователей',
    },
    'ProjectOverview:Indicators:sessions_one': {
        eng: 'session',
        ru: 'сессия',
    },
    'ProjectOverview:Indicators:sessions_few': {
        eng: 'sessions',
        ru: 'сессии',
    },
    'ProjectOverview:Indicators:sessions_many': {
        eng: 'sessions',
        ru: 'сессий',
    },
    'ProjectOverview:Indicators:sessions_other': {
        eng: 'sessions',
        ru: 'сессий',
    },
    'ProjectOverview:Indicators:messages_one': {
        eng: 'inbound message',
        ru: 'входящее сообщение',
    },
    'ProjectOverview:Indicators:messages_few': {
        eng: 'inbound messages',
        ru: 'входящих сообщения',
    },
    'ProjectOverview:Indicators:messages_many': {
        eng: 'inbound messages',
        ru: 'входящих сообщений',
    },
    'ProjectOverview:Indicators:messages_other': {
        eng: 'inbound messages',
        ru: 'входящих сообщений',
    },
    'ProjectOverview:AutomationChart:Stub:Link': {
        eng: '$[1]/JS_API/built_in_services/analytics/setAutomationStatus',
        ru: '$[1]/JS_API/built_in_services/analytics/setAutomationStatus',
    },
    'ProjectOverview:AutomationChart:Title': {
        eng: 'Automation',
        ru: 'Автоматизация',
    },
    'ProjectOverview:AutomationChart:Tooltip': {
        eng: 'dialogs </br> automated',
        ru: 'диалогов </br> автоматизировано',
    },
    'ProjectOverview:AutomationChart:Tooltip:Description': {
        eng: "The percentage of\u00A0automated dialogs. <br> You mark which bot actions can be considered as\u00A0automated successfully or\u00A0not. Use the\u00A0setSessionAutomation method for\u00A0this. <a href=\"#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setAutomationStatus\" target=\"_blank\">How to\u00A0set\u00A0up</a>",
        ru: "\u041F\u0440\u043E\u0446\u0435\u043D\u0442 \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0437\u0438\u0440\u043E\u0432\u0430\u043D\u043D\u044B\u0445 \u0434\u0438\u0430\u043B\u043E\u0433\u043E\u0432. <br> \u0412\u044B \u0441\u0430\u043C\u0438 \u0440\u0430\u0437\u043C\u0435\u0447\u0430\u0435\u0442\u0435, \u043A\u0430\u043A\u0438\u0435 \u0434\u0435\u0439\u0441\u0442\u0432\u0438\u044F \u0431\u043E\u0442\u0430 \u0441\u0447\u0438\u0442\u0430\u0442\u044C \u0443\u0441\u043F\u0435\u0448\u043D\u043E \u0430\u0432\u0442\u043E\u043C\u0430\u0442\u0438\u0437\u0438\u0440\u043E\u0432\u0430\u043D\u043D\u044B\u043C\u0438, \u0430\u00A0\u043A\u0430\u043A\u0438\u0435\u00A0\u043D\u0435\u0442. \u042D\u0442\u043E \u0434\u0435\u043B\u0430\u0435\u0442\u0441\u044F \u0441\u00A0\u043F\u043E\u043C\u043E\u0449\u044C\u044E \u043C\u0435\u0442\u043E\u0434\u0430 setSessionAutomation. <a href=\"#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setAutomationStatus\" target=\"_blank\">\u041A\u0430\u043A \u043D\u0430\u0441\u0442\u0440\u043E\u0438\u0442\u044C</a>",
    },
    'ProjectOverview:AutomationChart:Stub:Description': {
        eng: 'Get information on how many requests were automated with your bot.',
        ru: 'Получайте информацию о том, сколько обращений было автоматизировано с помощью бота.',
    },
    'ProjectOverview:Stub:Button': {
        eng: 'How to set up',
        ru: 'Как настроить',
    },
    'ProjectOverview:NpsChart:Title': {
        eng: 'Customer score',
        ru: 'Оценка от клиентов',
    },
    'ProjectOverview:NpsChart:Tooltip': {
        eng: 'average </br> score',
        ru: 'средняя </br> оценка',
    },
    'ProjectOverview:NpsChart:Tooltip:Description': {
        eng: "The chart depends on\u00A0the\u00A0way you set\u00A0up metric collection in\u00A0the script. For example, it\u00A0may\u00A0be CSAT, NPS, or\u00A0CSI. <a href=\"#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setNps\" target=\"_blank\">How to\u00A0set\u00A0up</a>",
        ru: "\u041E\u0442\u043E\u0431\u0440\u0430\u0436\u0435\u043D\u0438\u0435 \u0433\u0440\u0430\u0444\u0438\u043A\u0430 \u0437\u0430\u0432\u0438\u0441\u0438\u0442 \u043E\u0442\u00A0\u0442\u043E\u0433\u043E, \u043A\u0430\u043A \u0432\u044B \u0438\u0437\u043C\u0435\u0440\u044F\u0435\u0442\u0435 \u044D\u0442\u043E\u0442 \u043F\u043E\u043A\u0430\u0437\u0430\u0442\u0435\u043B\u044C \u0432\u00A0\u0441\u0446\u0435\u043D\u0430\u0440\u0438\u0438. \u042D\u0442\u043E \u043C\u043E\u0436\u0435\u0442 \u0431\u044B\u0442\u044C, \u043D\u0430\u043F\u0440\u0438\u043C\u0435\u0440, CSAT, NPS\u00A0\u0438\u043B\u0438 CSI. <a href=\"#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setNps\" target=\"_blank\">\u041A\u0430\u043A \u043D\u0430\u0441\u0442\u0440\u043E\u0438\u0442\u044C</a>",
    },
    'ProjectOverview:NpsChart:Stub:Link': {
        eng: "#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setNps",
        ru: "#{config.botadmin.helpUrl}/JS_API/built_in_services/analytics/setNps",
    },
    'ProjectOverview:NpsChart:Stub:Description': {
        eng: 'Set up your project to collect customer experience metrics: CSAT, NPS, or CSI.',
        ru: 'Измеряйте в вашем проекте показатели клиентского опыта: CSAT, NPS или CSI.',
    },
    'ProjectOverview:Topics:Stub:Link': {
        eng: "#{config.botadmin.helpUrl}/platform_ux/metrics-configuration",
        ru: "#{config.botadmin.helpUrl}/platform_ux/metrics-configuration",
    },
    'ProjectOverview:Topics:Title': {
        eng: 'Topics',
        ru: 'Темы обращений',
    },
    'ProjectOverview:Topics:Tooltip:Description': {
        eng: "When users get to\u00A0a\u00A0state marked with a\u00A0certain topic, JAICP considers it and\u00A0displays it on\u00A0the chart. You can manage topics in\u00A0the\u00A0Analytics\u00A0\u2192 Metrics configuration section. <a href=\"#{config.botadmin.helpUrl}/platform_ux/metrics-configuration\" target=\"_blank\">How to\u00A0set\u00A0up topics</a>",
        ru: "\u041A\u043E\u0433\u0434\u0430 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0438 \u043F\u043E\u043F\u0430\u0434\u0430\u044E\u0442 \u0432\u00A0\u0441\u0442\u0435\u0439\u0442 \u043E\u043F\u0440\u0435\u0434\u0435\u043B\u0435\u043D\u043D\u043E\u0439 \u0442\u0435\u043C\u044B, JAICP \u0443\u0447\u0438\u0442\u044B\u0432\u0430\u0435\u0442 \u044D\u0442\u043E \u0438\u00A0\u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442 \u043D\u0430\u00A0\u0433\u0440\u0430\u0444\u0438\u043A\u0435. \u0421\u0430\u043C\u0438\u043C\u0438 \u0442\u0435\u043C\u0430\u043C\u0438 \u043C\u043E\u0436\u043D\u043E \u0443\u043F\u0440\u0430\u0432\u043B\u044F\u0442\u044C \u0432\u00A0\u0440\u0430\u0437\u0434\u0435\u043B\u0435 \u0410\u043D\u0430\u043B\u0438\u0442\u0438\u043A\u0430\u00A0\u2192 \u041D\u0430\u0441\u0442\u0440\u043E\u0439\u043A\u0430 \u043C\u0435\u0442\u0440\u0438\u043A. <a href=\"#{config.botadmin.helpUrl}/platform_ux/metrics-configuration\" target=\"_blank\">\u041A\u0430\u043A \u043D\u0430\u0441\u0442\u0440\u043E\u0438\u0442\u044C \u0442\u0435\u043C\u044B</a>",
    },
    'ProjectOverview:Topics:Stub:Description': {
        eng: 'Set up topics to mark important script branches. When users get to the marked states in your script, JAICP will count the topics and display total distribution.',
        ru: 'Настройте темы обращений, чтобы обозначить важные ветки сценария. Когда пользователь попадет в отмеченные места в сценарии, JAICP учтет темы и подсчитает их общее распределение.',
    },
    'ProjectOverview:Result:Stub:Link': {
        eng: "#{config.botadmin.helpUrl}/platform_ux/metrics-configuration",
        ru: "#{config.botadmin.helpUrl}/platform_ux/metrics-configuration",
    },
    'ProjectOverview:Result:Title': {
        eng: 'Results',
        ru: 'Результаты',
    },
    'ProjectOverview:Result:Tooltip:Description': {
        eng: "Session results. When users get\u00A0to\u00A0the state where the\u00A0result is recorded, it is displayed on\u00A0the\u00A0chart. <a href=\"#{config.botadmin.helpUrl}/platform_ux/metrics-configuration\" target=\"_blank\">How to\u00A0record results</a>",
        ru: "\u0420\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B \u0441\u0435\u0441\u0441\u0438\u0439. \u041A\u043E\u0433\u0434\u0430 \u043F\u043E\u043B\u044C\u0437\u043E\u0432\u0430\u0442\u0435\u043B\u0438 \u043F\u043E\u043F\u0430\u0434\u0430\u044E\u0442 \u0432\u00A0\u0441\u0442\u0435\u0439\u0442, \u0433\u0434\u0435 \u0444\u0438\u043A\u0441\u0438\u0440\u0443\u0435\u0442\u0441\u044F \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442, \u043E\u043D \u043E\u0442\u043E\u0431\u0440\u0430\u0436\u0430\u0435\u0442\u0441\u044F \u043D\u0430\u00A0\u0433\u0440\u0430\u0444\u0438\u043A\u0435. <a href=\"#{config.botadmin.helpUrl}/platform_ux/metrics-configuration\" target=\"_blank\">\u041A\u0430\u043A \u0444\u0438\u043A\u0441\u0438\u0440\u043E\u0432\u0430\u0442\u044C \u0440\u0435\u0437\u0443\u043B\u044C\u0442\u0430\u0442\u044B</a>",
    },
    'ProjectOverview:Result:Stub:Description': {
        eng: 'Track the results of conversations with the bot to evaluate the effectiveness of your script.',
        ru: 'Отслеживайте итоги разговоров с ботом, чтобы оценить эффективность вашего сценария.',
    },
    'ProjectOverview:Progress:Other': {
        eng: 'Other',
        ru: 'Прочее',
    },
    'ProjectOverview:Banner:Title': {
        eng: 'Set up metrics while your bot has no users',
        ru: 'Настройте метрики проекта, пока у вашего бота нет пользователей',
    },
    'ProjectOverview:Banner:Link': {
        eng: "#{config.botadmin.helpUrl}/platform_ux/metrics-configuration",
        ru: "#{config.botadmin.helpUrl}/platform_ux/metrics-configuration",
    },
    'ProjectOverview:Banner:Description': {
        eng: 'You will see data here when users start communicate with your bot. </br> It’s a good time to set up your metrics now to get detailed statistics in the future.',
        ru: 'Когда у вашего бота появятся пользователи, здесь будут сводные данные по проекту. </br> Настройте метрики сейчас, чтобы в будущем получать полную статистику.',
    },
    'ProjectOverview:Banner:Button': {
        eng: 'How to set up metrics',
        ru: 'Как настроить метрики',
    },
    'ProjectOverview:Error:Title': {
        eng: 'No data for this report',
        ru: 'Нет данных для этого отчета',
    },
    'ProjectOverview:Error:Button': {
        eng: 'Update',
        ru: 'Обновить',
    },
};
