import React, { ReactText, useCallback, useEffect, useState } from 'react';
import { BotType } from '../dashboardTypes';
import BotsControls, { OrderTypes } from './BotsControls';
import './Bots.scss';
import classNames from 'classnames';
import Bot from './Bot';
import { Locale } from '@just-ai/just-ui';

import { Tag, TagsList } from '../TagsContext';

import { sortByOrder } from './botUtils';
import StudioBanner, { TypeEnum } from './StudioBanner';
import ChatGPTBanner from 'components/ChatGPTBanner';
import { BotTypeType } from 'types';

export interface BotsInterface {
  botList: BotType[];
  botType: BotTypeType;
  showCreateModal: () => void;
  deleteBot: (shortName: string) => void;
  cloneBot: (shortName: string) => void;
  selectBot: (shortName: string) => void;
  finalDelete: (id: number) => void;
  locale: Locale;
  countryIsoCode?: string;
}

const BotInterfaceStateStorage = {
  save: (key: string, value: string) => {
    localStorage.setItem(`BotInterfaceStateStorage_${key}`, value);
  },
  get: (key: string): string => {
    return localStorage.getItem(`BotInterfaceStateStorage_${key}`) as string;
  },
};

const isBotHasThatTags = (arrayOfProjectTags: TagsList, tagsIds: Tag['id'][]) => {
  const projectIds = arrayOfProjectTags.map(tag => tag.id);
  return tagsIds.some(v => projectIds.includes(v));
};

function Bots({
  botList,
  selectBot,
  showCreateModal,
  deleteBot,
  cloneBot,
  locale,
  finalDelete,
  countryIsoCode,
  botType,
}: BotsInterface) {
  const [order, setOrder] = useState<OrderTypes>(
    (BotInterfaceStateStorage.get('order') as OrderTypes) || OrderTypes['lastModified_desc']
  );
  const [orderedBotList, setOrderedBotList] = useState<BotType[]>(sortByOrder(botList, order));
  const [filteredBotList, setFilteredBotList] = useState<BotType[]>(orderedBotList);
  const [displayType, setDisplayType] = useState<'card' | 'list'>(
    (BotInterfaceStateStorage.get('displayType') as 'card') || 'card'
  );

  const [filters, setFilters] = useState<{ filterString: string; filterTags: (string | number)[] | null }>({
    filterTags: null,
    filterString: '',
  });

  const setFilterString = useCallback(
    (value: string) => {
      const { filterTags } = filters;
      setFilters({
        filterTags: filterTags,
        filterString: value,
      });
    },
    [filters]
  );

  const onSetDisplayType = useCallback(
    value => {
      setDisplayType(value);
      BotInterfaceStateStorage.save('displayType', value);
    },
    [setDisplayType]
  );

  const onSetOrder = useCallback(
    (value: OrderTypes) => {
      setOrder(value);
      BotInterfaceStateStorage.save('order', value);
    },
    [setOrder]
  );

  const setFilterTags = useCallback(
    (newFilterTags: ReactText[] | null) => {
      const { filterString } = filters;
      setFilters({
        filterString: filterString,
        filterTags: newFilterTags,
      });
    },
    [filters]
  );

  useEffect(() => {
    let orderedBotList = [...botList];
    sortByOrder(orderedBotList, order);
    setOrderedBotList(orderedBotList);
  }, [botList, order]);

  useEffect(() => {
    //Apply filters
    const { filterTags, filterString } = filters;
    let botListFiltered = [...orderedBotList];
    if (filterString) {
      botListFiltered = botListFiltered.filter(x => x.name?.toLowerCase().includes(filterString.toLowerCase()));
    }
    if (filterTags) {
      botListFiltered = botListFiltered.filter(bot => isBotHasThatTags(bot.tagLabels, filterTags as number[]));
    }

    setFilteredBotList(botListFiltered);
  }, [orderedBotList, filters]);

  return (
    <div>
      <BotsControls
        displayType={displayType}
        setDisplayType={onSetDisplayType}
        showCreateModal={showCreateModal}
        filterBots={setFilterString}
        setOrder={onSetOrder}
        setFilterTags={setFilterTags}
        order={order}
      />
      {botType === 'CHAT_BOT' && <ChatGPTBanner className='margin-bottom-6x' />}
      <StudioBanner countryIsoCode={countryIsoCode} type={TypeEnum.DASHBOARD} />
      <div className={classNames('botsList', displayType === 'list' && 'list')}>
        {filteredBotList.map((botItem, index) => (
          <Bot
            key={`botItem${botItem.shortName}${index}`}
            bot={botItem}
            pending={botItem.pending}
            deleting={botItem.deleting}
            locale={locale}
            selectBot={selectBot}
            deleteBot={deleteBot}
            finalDelete={finalDelete}
            cloneBot={cloneBot}
            displayType={displayType}
          />
        ))}
      </div>
    </div>
  );
}

export default React.memo(Bots);
