// tslint:disable
// @ts-nocheck
/**
 * Editor BE REST API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 0.0.0
 *
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import * as globalImportUrl from "url";
import globalAxios from "axios";
// Some imports not used depending on template conditions
// @ts-ignore
import { BASE_PATH, BaseAPI, RequiredError, } from "./base";
/**
 * Conflict resolution types:  * `integrate` - Automatically merge  * `ignoreRemote` - Make your version is active one  * `newBranch` - Save changes to another branch of the repository
 * @export
 * @enum {string}
 */
export var ConflictResolutionType;
(function (ConflictResolutionType) {
    ConflictResolutionType["Integrate"] = "integrate";
    ConflictResolutionType["IgnoreRemote"] = "ignoreRemote";
    ConflictResolutionType["NewBranch"] = "newBranch";
})(ConflictResolutionType || (ConflictResolutionType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var CustomTagParameterType;
(function (CustomTagParameterType) {
    CustomTagParameterType["String"] = "string";
    CustomTagParameterType["StringArray"] = "stringArray";
    CustomTagParameterType["Integer"] = "integer";
    CustomTagParameterType["State"] = "state";
    CustomTagParameterType["Bool"] = "bool";
    CustomTagParameterType["Intents"] = "intents";
    CustomTagParameterType["Parameters"] = "parameters";
    CustomTagParameterType["NameValueList"] = "nameValueList";
    CustomTagParameterType["Json"] = "json";
    CustomTagParameterType["Html"] = "html";
})(CustomTagParameterType || (CustomTagParameterType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var DeploymentBotStatusType;
(function (DeploymentBotStatusType) {
    DeploymentBotStatusType["Ok"] = "ok";
    DeploymentBotStatusType["TestFailed"] = "testFailed";
    DeploymentBotStatusType["DeployFailed"] = "deployFailed";
    DeploymentBotStatusType["InProgress"] = "inProgress";
    DeploymentBotStatusType["Pending"] = "pending";
    DeploymentBotStatusType["NoDeployments"] = "noDeployments";
})(DeploymentBotStatusType || (DeploymentBotStatusType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var DictionaryType;
(function (DictionaryType) {
    DictionaryType["Extended"] = "extended";
    DictionaryType["Small"] = "small";
    DictionaryType["Disabled"] = "disabled";
    DictionaryType["Custom"] = "custom";
})(DictionaryType || (DictionaryType = {}));
/**
 * Project environment. Defines where the JAICF project should be hosted.
 * @export
 * @enum {string}
 */
export var Environment;
(function (Environment) {
    Environment["JaicpCloud"] = "jaicpCloud";
    Environment["External"] = "external";
})(Environment || (Environment = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ExternalRepoAuthType;
(function (ExternalRepoAuthType) {
    ExternalRepoAuthType["PersonalUser"] = "personalUser";
    ExternalRepoAuthType["SystemUser"] = "systemUser";
})(ExternalRepoAuthType || (ExternalRepoAuthType = {}));
/**
 * @export
 * @enum {string}
 */
export var TariffEnum;
(function (TariffEnum) {
    TariffEnum["PAID"] = "PAID";
    TariffEnum["FREE"] = "FREE";
})(TariffEnum || (TariffEnum = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var GraphV2UpdateActionType;
(function (GraphV2UpdateActionType) {
    GraphV2UpdateActionType["Add"] = "add";
    GraphV2UpdateActionType["UpdateLayout"] = "updateLayout";
    GraphV2UpdateActionType["UpdateProps"] = "updateProps";
    GraphV2UpdateActionType["UpdateCode"] = "updateCode";
    GraphV2UpdateActionType["Delete"] = "delete";
})(GraphV2UpdateActionType || (GraphV2UpdateActionType = {}));
/**
 * @export
 * @enum {string}
 */
export var StorageTypeEnum;
(function (StorageTypeEnum) {
    StorageTypeEnum["Git"] = "git";
    StorageTypeEnum["Local"] = "local";
    StorageTypeEnum["System"] = "system";
})(StorageTypeEnum || (StorageTypeEnum = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ProductType;
(function (ProductType) {
    ProductType["Aimylogic"] = "aimylogic";
    ProductType["Jaicp"] = "jaicp";
})(ProductType || (ProductType = {}));
/**
 * @export
 * @enum {string}
 */
export var BotEngineEnum;
(function (BotEngineEnum) {
    BotEngineEnum["V1"] = "v1";
    BotEngineEnum["V2"] = "v2";
})(BotEngineEnum || (BotEngineEnum = {}));
/**
 * @export
 * @enum {string}
 */
export var StatusEnum;
(function (StatusEnum) {
    StatusEnum["CREATED"] = "CREATED";
    StatusEnum["FILLED"] = "FILLED";
    StatusEnum["FINISHED"] = "FINISHED";
})(StatusEnum || (StatusEnum = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var RolloutType;
(function (RolloutType) {
    RolloutType["Auto"] = "auto";
    RolloutType["Manual"] = "manual";
})(RolloutType || (RolloutType = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ServerLogApp;
(function (ServerLogApp) {
    ServerLogApp["Botserver"] = "botserver";
    ServerLogApp["Editorbe"] = "editorbe";
    ServerLogApp["Cailapub"] = "cailapub";
})(ServerLogApp || (ServerLogApp = {}));
/**
 * l0 - Emergency, l1 - Alert, l2 - Critical, l3 - Error, l4 - Warning, l5 - Notice, l6 - Informational, l7 - Debug
 * @export
 * @enum {string}
 */
export var ServerLogLevel;
(function (ServerLogLevel) {
    ServerLogLevel["L0"] = "l0";
    ServerLogLevel["L1"] = "l1";
    ServerLogLevel["L2"] = "l2";
    ServerLogLevel["L3"] = "l3";
    ServerLogLevel["L4"] = "l4";
    ServerLogLevel["L5"] = "l5";
    ServerLogLevel["L6"] = "l6";
    ServerLogLevel["L7"] = "l7";
})(ServerLogLevel || (ServerLogLevel = {}));
/**
 *
 * @export
 * @enum {string}
 */
export var ServerLogModule;
(function (ServerLogModule) {
    ServerLogModule["Engine"] = "engine";
    ServerLogModule["EngineV2"] = "engine_v2";
    ServerLogModule["Cailapub"] = "cailapub";
    ServerLogModule["Executor"] = "executor";
    ServerLogModule["Matcher"] = "matcher";
    ServerLogModule["Js"] = "js";
    ServerLogModule["Http"] = "http";
    ServerLogModule["Botapi"] = "botapi";
    ServerLogModule["Inflector"] = "inflector";
    ServerLogModule["Other"] = "other";
})(ServerLogModule || (ServerLogModule = {}));
/**
 * AimyApi - axios parameter creator
 * @export
 */
export const AimyApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Deploy all bots for project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildAndDeploy(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling buildAndDeploy.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling buildAndDeploy.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/deploy-for-aimylogic`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get internal state of deployment task queue for current project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeploymentTasks(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAllDeploymentTasks.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getAllDeploymentTasks.");
            }
            const localVarPath = `/api/editorbe-for-test/accounts/{accountId}/projects/{projectId}/get-tasks`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get internal state of deployment task queue for current project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentTasks(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getDeploymentTasks.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getDeploymentTasks.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/task/`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary history
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getHistory.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getHistory.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/history-aimylogic`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Nlu settings
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNluSettings(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getNluSettings.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getNluSettings.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/nlu-settings`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary On error
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnErrorMessages(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getOnErrorMessages.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getOnErrorMessages.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/on-error`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Restore aimy project. Switch main.sc with .main.sc.backup and remove `botEngine v2` property from chatbot.yaml.
         * @param {Array<RestoreProjectData>} restoreProjectData List of project and account ID\&#39;s.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restore(restoreProjectData, options = {}) {
            // verify required parameter 'restoreProjectData' is not null or undefined
            if (restoreProjectData === null || restoreProjectData === undefined) {
                throw new RequiredError("restoreProjectData", "Required parameter restoreProjectData was null or undefined when calling restore.");
            }
            const localVarPath = `/api/editorbe/restore`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof restoreProjectData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(restoreProjectData !== undefined ? restoreProjectData : {})
                : restoreProjectData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update nlu settings
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {NluSettingsData} nluSettingsData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveNluSettings(accountId, projectId, nluSettingsData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling saveNluSettings.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling saveNluSettings.");
            }
            // verify required parameter 'nluSettingsData' is not null or undefined
            if (nluSettingsData === null || nluSettingsData === undefined) {
                throw new RequiredError("nluSettingsData", "Required parameter nluSettingsData was null or undefined when calling saveNluSettings.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/nlu-settings`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof nluSettingsData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(nluSettingsData !== undefined ? nluSettingsData : {})
                : nluSettingsData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary On error
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOnErrorMessages(accountId, projectId, requestBody, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling saveOnErrorMessages.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling saveOnErrorMessages.");
            }
            // verify required parameter 'requestBody' is not null or undefined
            if (requestBody === null || requestBody === undefined) {
                throw new RequiredError("requestBody", "Required parameter requestBody was null or undefined when calling saveOnErrorMessages.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/on-error`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof requestBody !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(requestBody !== undefined ? requestBody : {})
                : requestBody || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary graphRequest
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {string} body
         * @param {boolean} [systemSave] System save.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGraphAimy(accountId, projectId, file, body, systemSave, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateGraphAimy.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling updateGraphAimy.");
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError("file", "Required parameter file was null or undefined when calling updateGraphAimy.");
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError("body", "Required parameter body was null or undefined when calling updateGraphAimy.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/graph-aimylogic`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (file !== undefined) {
                localVarQueryParameter["file"] = file;
            }
            if (systemSave !== undefined) {
                localVarQueryParameter["systemSave"] = systemSave;
            }
            localVarHeaderParameter["Content-Type"] =
                "application/json; charset=UTF-8";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * AimyApi - functional programming interface
 * @export
 */
export const AimyApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Deploy all bots for project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildAndDeploy(accountId, projectId, options) {
            const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).buildAndDeploy(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get internal state of deployment task queue for current project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeploymentTasks(accountId, projectId, options) {
            const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).getAllDeploymentTasks(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get internal state of deployment task queue for current project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentTasks(accountId, projectId, options) {
            const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).getDeploymentTasks(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary history
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(accountId, projectId, options) {
            const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).getHistory(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Nlu settings
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNluSettings(accountId, projectId, options) {
            const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).getNluSettings(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary On error
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnErrorMessages(accountId, projectId, options) {
            const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).getOnErrorMessages(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Restore aimy project. Switch main.sc with .main.sc.backup and remove `botEngine v2` property from chatbot.yaml.
         * @param {Array<RestoreProjectData>} restoreProjectData List of project and account ID\&#39;s.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restore(restoreProjectData, options) {
            const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).restore(restoreProjectData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update nlu settings
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {NluSettingsData} nluSettingsData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveNluSettings(accountId, projectId, nluSettingsData, options) {
            const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).saveNluSettings(accountId, projectId, nluSettingsData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary On error
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOnErrorMessages(accountId, projectId, requestBody, options) {
            const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).saveOnErrorMessages(accountId, projectId, requestBody, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary graphRequest
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {string} body
         * @param {boolean} [systemSave] System save.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGraphAimy(accountId, projectId, file, body, systemSave, options) {
            const localVarAxiosArgs = AimyApiAxiosParamCreator(configuration).updateGraphAimy(accountId, projectId, file, body, systemSave, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * AimyApi - factory interface
 * @export
 */
export const AimyApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Deploy all bots for project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        buildAndDeploy(accountId, projectId, options) {
            return AimyApiFp(configuration).buildAndDeploy(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get internal state of deployment task queue for current project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDeploymentTasks(accountId, projectId, options) {
            return AimyApiFp(configuration).getAllDeploymentTasks(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get internal state of deployment task queue for current project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentTasks(accountId, projectId, options) {
            return AimyApiFp(configuration).getDeploymentTasks(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary history
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(accountId, projectId, options) {
            return AimyApiFp(configuration).getHistory(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Nlu settings
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getNluSettings(accountId, projectId, options) {
            return AimyApiFp(configuration).getNluSettings(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary On error
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getOnErrorMessages(accountId, projectId, options) {
            return AimyApiFp(configuration).getOnErrorMessages(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Restore aimy project. Switch main.sc with .main.sc.backup and remove `botEngine v2` property from chatbot.yaml.
         * @param {Array<RestoreProjectData>} restoreProjectData List of project and account ID\&#39;s.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        restore(restoreProjectData, options) {
            return AimyApiFp(configuration).restore(restoreProjectData, options)(axios, basePath);
        },
        /**
         *
         * @summary Update nlu settings
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {NluSettingsData} nluSettingsData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveNluSettings(accountId, projectId, nluSettingsData, options) {
            return AimyApiFp(configuration).saveNluSettings(accountId, projectId, nluSettingsData, options)(axios, basePath);
        },
        /**
         *
         * @summary On error
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<string>} requestBody
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        saveOnErrorMessages(accountId, projectId, requestBody, options) {
            return AimyApiFp(configuration).saveOnErrorMessages(accountId, projectId, requestBody, options)(axios, basePath);
        },
        /**
         *
         * @summary graphRequest
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {string} body
         * @param {boolean} [systemSave] System save.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGraphAimy(accountId, projectId, file, body, systemSave, options) {
            return AimyApiFp(configuration).updateGraphAimy(accountId, projectId, file, body, systemSave, options)(axios, basePath);
        },
    };
};
/**
 * AimyApi - object-oriented interface
 * @export
 * @class AimyApi
 * @extends {BaseAPI}
 */
export class AimyApi extends BaseAPI {
    /**
     *
     * @summary Deploy all bots for project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AimyApi
     */
    buildAndDeploy(accountId, projectId, options) {
        return AimyApiFp(this.configuration).buildAndDeploy(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get internal state of deployment task queue for current project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AimyApi
     */
    getAllDeploymentTasks(accountId, projectId, options) {
        return AimyApiFp(this.configuration).getAllDeploymentTasks(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get internal state of deployment task queue for current project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AimyApi
     */
    getDeploymentTasks(accountId, projectId, options) {
        return AimyApiFp(this.configuration).getDeploymentTasks(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary history
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AimyApi
     */
    getHistory(accountId, projectId, options) {
        return AimyApiFp(this.configuration).getHistory(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Nlu settings
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AimyApi
     */
    getNluSettings(accountId, projectId, options) {
        return AimyApiFp(this.configuration).getNluSettings(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary On error
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AimyApi
     */
    getOnErrorMessages(accountId, projectId, options) {
        return AimyApiFp(this.configuration).getOnErrorMessages(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Restore aimy project. Switch main.sc with .main.sc.backup and remove `botEngine v2` property from chatbot.yaml.
     * @param {Array<RestoreProjectData>} restoreProjectData List of project and account ID\&#39;s.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AimyApi
     */
    restore(restoreProjectData, options) {
        return AimyApiFp(this.configuration).restore(restoreProjectData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update nlu settings
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {NluSettingsData} nluSettingsData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AimyApi
     */
    saveNluSettings(accountId, projectId, nluSettingsData, options) {
        return AimyApiFp(this.configuration).saveNluSettings(accountId, projectId, nluSettingsData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary On error
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<string>} requestBody
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AimyApi
     */
    saveOnErrorMessages(accountId, projectId, requestBody, options) {
        return AimyApiFp(this.configuration).saveOnErrorMessages(accountId, projectId, requestBody, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary graphRequest
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} body
     * @param {boolean} [systemSave] System save.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof AimyApi
     */
    updateGraphAimy(accountId, projectId, file, body, systemSave, options) {
        return AimyApiFp(this.configuration).updateGraphAimy(accountId, projectId, file, body, systemSave, options)(this.axios, this.basePath);
    }
}
/**
 * DependencyApi - axios parameter creator
 * @export
 */
export const DependencyApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary get content of dependency
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependency(accountId, projectId, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getDependency.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getDependency.");
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError("file", "Required parameter file was null or undefined when calling getDependency.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/dependency`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (file !== undefined) {
                localVarQueryParameter["file"] = file;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary get content of system dependency file
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemDependency(file, options = {}) {
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError("file", "Required parameter file was null or undefined when calling getSystemDependency.");
            }
            const localVarPath = `/api/editorbe/content/systemDependency`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (file !== undefined) {
                localVarQueryParameter["file"] = file;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary update user dependencies from chatbot.yaml
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDependencies(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateDependencies.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling updateDependencies.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/updateDependencies`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * DependencyApi - functional programming interface
 * @export
 */
export const DependencyApiFp = function (configuration) {
    return {
        /**
         *
         * @summary get content of dependency
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependency(accountId, projectId, file, options) {
            const localVarAxiosArgs = DependencyApiAxiosParamCreator(configuration).getDependency(accountId, projectId, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary get content of system dependency file
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemDependency(file, options) {
            const localVarAxiosArgs = DependencyApiAxiosParamCreator(configuration).getSystemDependency(file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary update user dependencies from chatbot.yaml
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDependencies(accountId, projectId, options) {
            const localVarAxiosArgs = DependencyApiAxiosParamCreator(configuration).updateDependencies(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * DependencyApi - factory interface
 * @export
 */
export const DependencyApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary get content of dependency
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDependency(accountId, projectId, file, options) {
            return DependencyApiFp(configuration).getDependency(accountId, projectId, file, options)(axios, basePath);
        },
        /**
         *
         * @summary get content of system dependency file
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getSystemDependency(file, options) {
            return DependencyApiFp(configuration).getSystemDependency(file, options)(axios, basePath);
        },
        /**
         *
         * @summary update user dependencies from chatbot.yaml
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateDependencies(accountId, projectId, options) {
            return DependencyApiFp(configuration).updateDependencies(accountId, projectId, options)(axios, basePath);
        },
    };
};
/**
 * DependencyApi - object-oriented interface
 * @export
 * @class DependencyApi
 * @extends {BaseAPI}
 */
export class DependencyApi extends BaseAPI {
    /**
     *
     * @summary get content of dependency
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependencyApi
     */
    getDependency(accountId, projectId, file, options) {
        return DependencyApiFp(this.configuration).getDependency(accountId, projectId, file, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary get content of system dependency file
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependencyApi
     */
    getSystemDependency(file, options) {
        return DependencyApiFp(this.configuration).getSystemDependency(file, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary update user dependencies from chatbot.yaml
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DependencyApi
     */
    updateDependencies(accountId, projectId, options) {
        return DependencyApiFp(this.configuration).updateDependencies(accountId, projectId, options)(this.axios, this.basePath);
    }
}
/**
 * DeploymentApi - axios parameter creator
 * @export
 */
export const DeploymentApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Cancel task if it doesn\'t start yet
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTask(accountId, projectId, taskId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling cancelTask.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling cancelTask.");
            }
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError("taskId", "Required parameter taskId was null or undefined when calling cancelTask.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/task/{taskId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Check system projects init completed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSystemProjectsInit(options = {}) {
            const localVarPath = `/api/editorbe-for-test/check-system-projects`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create task to deploy bot
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} botId Bot identifier.
         * @param {boolean} [forceDeploy] Force deploy flag.
         * @param {boolean} [skipNotifications] Skip notifications flag.
         * @param {boolean} [skipTests] Skip tests flag.
         * @param {boolean} [runTestsInBackground] Run tests in background.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deploy(accountId, projectId, botId, forceDeploy, skipNotifications, skipTests, runTestsInBackground, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deploy.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling deploy.");
            }
            // verify required parameter 'botId' is not null or undefined
            if (botId === null || botId === undefined) {
                throw new RequiredError("botId", "Required parameter botId was null or undefined when calling deploy.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/bot/{botId}/deploy`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"botId"}}`, encodeURIComponent(String(botId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (forceDeploy !== undefined) {
                localVarQueryParameter["forceDeploy"] = forceDeploy;
            }
            if (skipNotifications !== undefined) {
                localVarQueryParameter["skipNotifications"] = skipNotifications;
            }
            if (skipTests !== undefined) {
                localVarQueryParameter["skipTests"] = skipTests;
            }
            if (runTestsInBackground !== undefined) {
                localVarQueryParameter["runTestsInBackground"] = runTestsInBackground;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List deploy configs and status
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConfigs(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getAllConfigs.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getAllConfigs.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get global deploy config from application.yml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeployConfig(options = {}) {
            const localVarPath = `/api/editorbe-for-test/get-deploy-config`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get deployment task info
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentTask(accountId, projectId, taskId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getDeploymentTask.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getDeploymentTask.");
            }
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError("taskId", "Required parameter taskId was null or undefined when calling getDeploymentTask.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/task/{taskId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get deployment log for a task
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentTaskLog(accountId, projectId, taskId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getDeploymentTaskLog.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getDeploymentTaskLog.");
            }
            // verify required parameter 'taskId' is not null or undefined
            if (taskId === null || taskId === undefined) {
                throw new RequiredError("taskId", "Required parameter taskId was null or undefined when calling getDeploymentTaskLog.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/task/{taskId}/log`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"taskId"}}`, encodeURIComponent(String(taskId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get deployment history for bot
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} botId Bot identifier.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryByBot(accountId, projectId, botId, page, size, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getHistoryByBot.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getHistoryByBot.");
            }
            // verify required parameter 'botId' is not null or undefined
            if (botId === null || botId === undefined) {
                throw new RequiredError("botId", "Required parameter botId was null or undefined when calling getHistoryByBot.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/{botId}/history`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"botId"}}`, encodeURIComponent(String(botId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (page !== undefined) {
                localVarQueryParameter["page"] = page;
            }
            if (size !== undefined) {
                localVarQueryParameter["size"] = size;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get last deploy results for all bots by account.
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastDeployResultsForAccount(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getLastDeployResultsForAccount.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/deployment/history`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get status for single bot
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} botId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusByBot(accountId, projectId, botId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getStatusByBot.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getStatusByBot.");
            }
            // verify required parameter 'botId' is not null or undefined
            if (botId === null || botId === undefined) {
                throw new RequiredError("botId", "Required parameter botId was null or undefined when calling getStatusByBot.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/bot/{botId}/status`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"botId"}}`, encodeURIComponent(String(botId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get status for all bot configs
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusForAll(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getStatusForAll.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getStatusForAll.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/status`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Run tests
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} botId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTests(accountId, projectId, botId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling runTests.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling runTests.");
            }
            // verify required parameter 'botId' is not null or undefined
            if (botId === null || botId === undefined) {
                throw new RequiredError("botId", "Required parameter botId was null or undefined when calling runTests.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment/bot/{botId}/runTests`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"botId"}}`, encodeURIComponent(String(botId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update deploy configurations
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<DeploymentBotConfigData>} deploymentBotConfigData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllConfigs(accountId, projectId, deploymentBotConfigData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateAllConfigs.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling updateAllConfigs.");
            }
            // verify required parameter 'deploymentBotConfigData' is not null or undefined
            if (deploymentBotConfigData === null ||
                deploymentBotConfigData === undefined) {
                throw new RequiredError("deploymentBotConfigData", "Required parameter deploymentBotConfigData was null or undefined when calling updateAllConfigs.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/deployment`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof deploymentBotConfigData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(deploymentBotConfigData !== undefined ? deploymentBotConfigData : {})
                : deploymentBotConfigData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * DeploymentApi - functional programming interface
 * @export
 */
export const DeploymentApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Cancel task if it doesn\'t start yet
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTask(accountId, projectId, taskId, options) {
            const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).cancelTask(accountId, projectId, taskId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Check system projects init completed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSystemProjectsInit(options) {
            const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).checkSystemProjectsInit(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create task to deploy bot
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} botId Bot identifier.
         * @param {boolean} [forceDeploy] Force deploy flag.
         * @param {boolean} [skipNotifications] Skip notifications flag.
         * @param {boolean} [skipTests] Skip tests flag.
         * @param {boolean} [runTestsInBackground] Run tests in background.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deploy(accountId, projectId, botId, forceDeploy, skipNotifications, skipTests, runTestsInBackground, options) {
            const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).deploy(accountId, projectId, botId, forceDeploy, skipNotifications, skipTests, runTestsInBackground, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary List deploy configs and status
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConfigs(accountId, projectId, options) {
            const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getAllConfigs(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get global deploy config from application.yml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeployConfig(options) {
            const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getDeployConfig(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get deployment task info
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentTask(accountId, projectId, taskId, options) {
            const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getDeploymentTask(accountId, projectId, taskId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get deployment log for a task
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentTaskLog(accountId, projectId, taskId, options) {
            const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getDeploymentTaskLog(accountId, projectId, taskId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get deployment history for bot
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} botId Bot identifier.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryByBot(accountId, projectId, botId, page, size, options) {
            const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getHistoryByBot(accountId, projectId, botId, page, size, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get last deploy results for all bots by account.
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastDeployResultsForAccount(accountId, options) {
            const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getLastDeployResultsForAccount(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get status for single bot
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} botId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusByBot(accountId, projectId, botId, options) {
            const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getStatusByBot(accountId, projectId, botId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get status for all bot configs
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusForAll(accountId, projectId, options) {
            const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).getStatusForAll(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Run tests
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} botId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTests(accountId, projectId, botId, options) {
            const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).runTests(accountId, projectId, botId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update deploy configurations
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<DeploymentBotConfigData>} deploymentBotConfigData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllConfigs(accountId, projectId, deploymentBotConfigData, options) {
            const localVarAxiosArgs = DeploymentApiAxiosParamCreator(configuration).updateAllConfigs(accountId, projectId, deploymentBotConfigData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * DeploymentApi - factory interface
 * @export
 */
export const DeploymentApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Cancel task if it doesn\'t start yet
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cancelTask(accountId, projectId, taskId, options) {
            return DeploymentApiFp(configuration).cancelTask(accountId, projectId, taskId, options)(axios, basePath);
        },
        /**
         *
         * @summary Check system projects init completed
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkSystemProjectsInit(options) {
            return DeploymentApiFp(configuration).checkSystemProjectsInit(options)(axios, basePath);
        },
        /**
         *
         * @summary Create task to deploy bot
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} botId Bot identifier.
         * @param {boolean} [forceDeploy] Force deploy flag.
         * @param {boolean} [skipNotifications] Skip notifications flag.
         * @param {boolean} [skipTests] Skip tests flag.
         * @param {boolean} [runTestsInBackground] Run tests in background.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deploy(accountId, projectId, botId, forceDeploy, skipNotifications, skipTests, runTestsInBackground, options) {
            return DeploymentApiFp(configuration).deploy(accountId, projectId, botId, forceDeploy, skipNotifications, skipTests, runTestsInBackground, options)(axios, basePath);
        },
        /**
         *
         * @summary List deploy configs and status
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConfigs(accountId, projectId, options) {
            return DeploymentApiFp(configuration).getAllConfigs(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get global deploy config from application.yml
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeployConfig(options) {
            return DeploymentApiFp(configuration).getDeployConfig(options)(axios, basePath);
        },
        /**
         *
         * @summary Get deployment task info
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentTask(accountId, projectId, taskId, options) {
            return DeploymentApiFp(configuration).getDeploymentTask(accountId, projectId, taskId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get deployment log for a task
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} taskId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getDeploymentTaskLog(accountId, projectId, taskId, options) {
            return DeploymentApiFp(configuration).getDeploymentTaskLog(accountId, projectId, taskId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get deployment history for bot
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} botId Bot identifier.
         * @param {number} [page] Page.
         * @param {number} [size] Maximum number of items to return.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryByBot(accountId, projectId, botId, page, size, options) {
            return DeploymentApiFp(configuration).getHistoryByBot(accountId, projectId, botId, page, size, options)(axios, basePath);
        },
        /**
         *
         * @summary Get last deploy results for all bots by account.
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLastDeployResultsForAccount(accountId, options) {
            return DeploymentApiFp(configuration).getLastDeployResultsForAccount(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get status for single bot
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} botId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusByBot(accountId, projectId, botId, options) {
            return DeploymentApiFp(configuration).getStatusByBot(accountId, projectId, botId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get status for all bot configs
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getStatusForAll(accountId, projectId, options) {
            return DeploymentApiFp(configuration).getStatusForAll(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Run tests
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} botId Bot identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        runTests(accountId, projectId, botId, options) {
            return DeploymentApiFp(configuration).runTests(accountId, projectId, botId, options)(axios, basePath);
        },
        /**
         *
         * @summary Update deploy configurations
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<DeploymentBotConfigData>} deploymentBotConfigData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAllConfigs(accountId, projectId, deploymentBotConfigData, options) {
            return DeploymentApiFp(configuration).updateAllConfigs(accountId, projectId, deploymentBotConfigData, options)(axios, basePath);
        },
    };
};
/**
 * DeploymentApi - object-oriented interface
 * @export
 * @class DeploymentApi
 * @extends {BaseAPI}
 */
export class DeploymentApi extends BaseAPI {
    /**
     *
     * @summary Cancel task if it doesn\'t start yet
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    cancelTask(accountId, projectId, taskId, options) {
        return DeploymentApiFp(this.configuration).cancelTask(accountId, projectId, taskId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Check system projects init completed
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    checkSystemProjectsInit(options) {
        return DeploymentApiFp(this.configuration).checkSystemProjectsInit(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create task to deploy bot
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {boolean} [forceDeploy] Force deploy flag.
     * @param {boolean} [skipNotifications] Skip notifications flag.
     * @param {boolean} [skipTests] Skip tests flag.
     * @param {boolean} [runTestsInBackground] Run tests in background.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    deploy(accountId, projectId, botId, forceDeploy, skipNotifications, skipTests, runTestsInBackground, options) {
        return DeploymentApiFp(this.configuration).deploy(accountId, projectId, botId, forceDeploy, skipNotifications, skipTests, runTestsInBackground, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary List deploy configs and status
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    getAllConfigs(accountId, projectId, options) {
        return DeploymentApiFp(this.configuration).getAllConfigs(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get global deploy config from application.yml
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    getDeployConfig(options) {
        return DeploymentApiFp(this.configuration).getDeployConfig(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get deployment task info
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    getDeploymentTask(accountId, projectId, taskId, options) {
        return DeploymentApiFp(this.configuration).getDeploymentTask(accountId, projectId, taskId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get deployment log for a task
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} taskId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    getDeploymentTaskLog(accountId, projectId, taskId, options) {
        return DeploymentApiFp(this.configuration).getDeploymentTaskLog(accountId, projectId, taskId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get deployment history for bot
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {number} [page] Page.
     * @param {number} [size] Maximum number of items to return.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    getHistoryByBot(accountId, projectId, botId, page, size, options) {
        return DeploymentApiFp(this.configuration).getHistoryByBot(accountId, projectId, botId, page, size, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get last deploy results for all bots by account.
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    getLastDeployResultsForAccount(accountId, options) {
        return DeploymentApiFp(this.configuration).getLastDeployResultsForAccount(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get status for single bot
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    getStatusByBot(accountId, projectId, botId, options) {
        return DeploymentApiFp(this.configuration).getStatusByBot(accountId, projectId, botId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get status for all bot configs
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    getStatusForAll(accountId, projectId, options) {
        return DeploymentApiFp(this.configuration).getStatusForAll(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Run tests
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} botId Bot identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    runTests(accountId, projectId, botId, options) {
        return DeploymentApiFp(this.configuration).runTests(accountId, projectId, botId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update deploy configurations
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<DeploymentBotConfigData>} deploymentBotConfigData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DeploymentApi
     */
    updateAllConfigs(accountId, projectId, deploymentBotConfigData, options) {
        return DeploymentApiFp(this.configuration).updateAllConfigs(accountId, projectId, deploymentBotConfigData, options)(this.axios, this.basePath);
    }
}
/**
 * E2ECheckApi - axios parameter creator
 * @export
 */
export const E2ECheckApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary E2ECheck
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, options = {}) {
            // verify required parameter 'loggingEnabledE2ECheck' is not null or undefined
            if (loggingEnabledE2ECheck === null ||
                loggingEnabledE2ECheck === undefined) {
                throw new RequiredError("loggingEnabledE2ECheck", "Required parameter loggingEnabledE2ECheck was null or undefined when calling e2ECheck.");
            }
            const localVarPath = `/api/editorbe/e2e-check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (loggingEnabledE2ECheck !== undefined &&
                loggingEnabledE2ECheck !== null) {
                localVarHeaderParameter["loggingEnabledE2ECheck"] = String(JSON.stringify(loggingEnabledE2ECheck));
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * E2ECheckApi - functional programming interface
 * @export
 */
export const E2ECheckApiFp = function (configuration) {
    return {
        /**
         *
         * @summary E2ECheck
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, options) {
            const localVarAxiosArgs = E2ECheckApiAxiosParamCreator(configuration).e2ECheck(loggingEnabledE2ECheck, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * E2ECheckApi - factory interface
 * @export
 */
export const E2ECheckApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary E2ECheck
         * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        e2ECheck(loggingEnabledE2ECheck, options) {
            return E2ECheckApiFp(configuration).e2ECheck(loggingEnabledE2ECheck, options)(axios, basePath);
        },
    };
};
/**
 * E2ECheckApi - object-oriented interface
 * @export
 * @class E2ECheckApi
 * @extends {BaseAPI}
 */
export class E2ECheckApi extends BaseAPI {
    /**
     *
     * @summary E2ECheck
     * @param {boolean} loggingEnabledE2ECheck enable/disable logging for e2e check
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof E2ECheckApi
     */
    e2ECheck(loggingEnabledE2ECheck, options) {
        return E2ECheckApiFp(this.configuration).e2ECheck(loggingEnabledE2ECheck, options)(this.axios, this.basePath);
    }
}
/**
 * EnvironmentVariableApi - axios parameter creator
 * @export
 */
export const EnvironmentVariableApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Apply environment variables to bots
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyEnvVars(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling applyEnvVars.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling applyEnvVars.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/envvars/apply`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create environment variable
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} envVarName Project environment variable name
         * @param {EnvVarRequest} envVarRequest Environment variable value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvVar(accountId, projectId, envVarName, envVarRequest, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createEnvVar.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling createEnvVar.");
            }
            // verify required parameter 'envVarName' is not null or undefined
            if (envVarName === null || envVarName === undefined) {
                throw new RequiredError("envVarName", "Required parameter envVarName was null or undefined when calling createEnvVar.");
            }
            // verify required parameter 'envVarRequest' is not null or undefined
            if (envVarRequest === null || envVarRequest === undefined) {
                throw new RequiredError("envVarRequest", "Required parameter envVarRequest was null or undefined when calling createEnvVar.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/envvars/{envVarName}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"envVarName"}}`, encodeURIComponent(String(envVarName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof envVarRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(envVarRequest !== undefined ? envVarRequest : {})
                : envVarRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete environment variable
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} envVarName Project environment variable name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvVar(accountId, projectId, envVarName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteEnvVar.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling deleteEnvVar.");
            }
            // verify required parameter 'envVarName' is not null or undefined
            if (envVarName === null || envVarName === undefined) {
                throw new RequiredError("envVarName", "Required parameter envVarName was null or undefined when calling deleteEnvVar.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/envvars/{envVarName}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"envVarName"}}`, encodeURIComponent(String(envVarName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get list of environment variables
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvVars(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getEnvVars.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getEnvVars.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/envvars`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update environment variable
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} envVarName Project environment variable name
         * @param {EnvVarRequest} envVarRequest Environment variable value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnvVar(accountId, projectId, envVarName, envVarRequest, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateEnvVar.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling updateEnvVar.");
            }
            // verify required parameter 'envVarName' is not null or undefined
            if (envVarName === null || envVarName === undefined) {
                throw new RequiredError("envVarName", "Required parameter envVarName was null or undefined when calling updateEnvVar.");
            }
            // verify required parameter 'envVarRequest' is not null or undefined
            if (envVarRequest === null || envVarRequest === undefined) {
                throw new RequiredError("envVarRequest", "Required parameter envVarRequest was null or undefined when calling updateEnvVar.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/envvars/{envVarName}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"envVarName"}}`, encodeURIComponent(String(envVarName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof envVarRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(envVarRequest !== undefined ? envVarRequest : {})
                : envVarRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * EnvironmentVariableApi - functional programming interface
 * @export
 */
export const EnvironmentVariableApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Apply environment variables to bots
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyEnvVars(accountId, projectId, options) {
            const localVarAxiosArgs = EnvironmentVariableApiAxiosParamCreator(configuration).applyEnvVars(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create environment variable
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} envVarName Project environment variable name
         * @param {EnvVarRequest} envVarRequest Environment variable value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvVar(accountId, projectId, envVarName, envVarRequest, options) {
            const localVarAxiosArgs = EnvironmentVariableApiAxiosParamCreator(configuration).createEnvVar(accountId, projectId, envVarName, envVarRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete environment variable
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} envVarName Project environment variable name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvVar(accountId, projectId, envVarName, options) {
            const localVarAxiosArgs = EnvironmentVariableApiAxiosParamCreator(configuration).deleteEnvVar(accountId, projectId, envVarName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get list of environment variables
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvVars(accountId, projectId, options) {
            const localVarAxiosArgs = EnvironmentVariableApiAxiosParamCreator(configuration).getEnvVars(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update environment variable
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} envVarName Project environment variable name
         * @param {EnvVarRequest} envVarRequest Environment variable value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnvVar(accountId, projectId, envVarName, envVarRequest, options) {
            const localVarAxiosArgs = EnvironmentVariableApiAxiosParamCreator(configuration).updateEnvVar(accountId, projectId, envVarName, envVarRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * EnvironmentVariableApi - factory interface
 * @export
 */
export const EnvironmentVariableApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Apply environment variables to bots
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        applyEnvVars(accountId, projectId, options) {
            return EnvironmentVariableApiFp(configuration).applyEnvVars(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Create environment variable
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} envVarName Project environment variable name
         * @param {EnvVarRequest} envVarRequest Environment variable value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createEnvVar(accountId, projectId, envVarName, envVarRequest, options) {
            return EnvironmentVariableApiFp(configuration).createEnvVar(accountId, projectId, envVarName, envVarRequest, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete environment variable
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} envVarName Project environment variable name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteEnvVar(accountId, projectId, envVarName, options) {
            return EnvironmentVariableApiFp(configuration).deleteEnvVar(accountId, projectId, envVarName, options)(axios, basePath);
        },
        /**
         *
         * @summary Get list of environment variables
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getEnvVars(accountId, projectId, options) {
            return EnvironmentVariableApiFp(configuration).getEnvVars(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Update environment variable
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} envVarName Project environment variable name
         * @param {EnvVarRequest} envVarRequest Environment variable value
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateEnvVar(accountId, projectId, envVarName, envVarRequest, options) {
            return EnvironmentVariableApiFp(configuration).updateEnvVar(accountId, projectId, envVarName, envVarRequest, options)(axios, basePath);
        },
    };
};
/**
 * EnvironmentVariableApi - object-oriented interface
 * @export
 * @class EnvironmentVariableApi
 * @extends {BaseAPI}
 */
export class EnvironmentVariableApi extends BaseAPI {
    /**
     *
     * @summary Apply environment variables to bots
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentVariableApi
     */
    applyEnvVars(accountId, projectId, options) {
        return EnvironmentVariableApiFp(this.configuration).applyEnvVars(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create environment variable
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} envVarName Project environment variable name
     * @param {EnvVarRequest} envVarRequest Environment variable value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentVariableApi
     */
    createEnvVar(accountId, projectId, envVarName, envVarRequest, options) {
        return EnvironmentVariableApiFp(this.configuration).createEnvVar(accountId, projectId, envVarName, envVarRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete environment variable
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} envVarName Project environment variable name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentVariableApi
     */
    deleteEnvVar(accountId, projectId, envVarName, options) {
        return EnvironmentVariableApiFp(this.configuration).deleteEnvVar(accountId, projectId, envVarName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get list of environment variables
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentVariableApi
     */
    getEnvVars(accountId, projectId, options) {
        return EnvironmentVariableApiFp(this.configuration).getEnvVars(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update environment variable
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} envVarName Project environment variable name
     * @param {EnvVarRequest} envVarRequest Environment variable value
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EnvironmentVariableApi
     */
    updateEnvVar(accountId, projectId, envVarName, envVarRequest, options) {
        return EnvironmentVariableApiFp(this.configuration).updateEnvVar(accountId, projectId, envVarName, envVarRequest, options)(this.axios, this.basePath);
    }
}
/**
 * ForTestsApi - axios parameter creator
 * @export
 */
export const ForTestsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Check if editorbe has spesific feature
         * @param {number} accountId User identifier.
         * @param {string} feature Feature name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasFeature(accountId, feature, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling hasFeature.");
            }
            // verify required parameter 'feature' is not null or undefined
            if (feature === null || feature === undefined) {
                throw new RequiredError("feature", "Required parameter feature was null or undefined when calling hasFeature.");
            }
            const localVarPath = `/api/editorbe-for-test/accounts/{accountId}/has-feature/{feature}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"feature"}}`, encodeURIComponent(String(feature)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ForTestsApi - functional programming interface
 * @export
 */
export const ForTestsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Check if editorbe has spesific feature
         * @param {number} accountId User identifier.
         * @param {string} feature Feature name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasFeature(accountId, feature, options) {
            const localVarAxiosArgs = ForTestsApiAxiosParamCreator(configuration).hasFeature(accountId, feature, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ForTestsApi - factory interface
 * @export
 */
export const ForTestsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Check if editorbe has spesific feature
         * @param {number} accountId User identifier.
         * @param {string} feature Feature name.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasFeature(accountId, feature, options) {
            return ForTestsApiFp(configuration).hasFeature(accountId, feature, options)(axios, basePath);
        },
    };
};
/**
 * ForTestsApi - object-oriented interface
 * @export
 * @class ForTestsApi
 * @extends {BaseAPI}
 */
export class ForTestsApi extends BaseAPI {
    /**
     *
     * @summary Check if editorbe has spesific feature
     * @param {number} accountId User identifier.
     * @param {string} feature Feature name.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ForTestsApi
     */
    hasFeature(accountId, feature, options) {
        return ForTestsApiFp(this.configuration).hasFeature(accountId, feature, options)(this.axios, this.basePath);
    }
}
/**
 * GPTAIApi - axios parameter creator
 * @export
 */
export const GPTAIApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Check that account has quota for GPTAI request.
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasQuota(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling hasQuota.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/gptai/hasQuota`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * GPTAIApi - functional programming interface
 * @export
 */
export const GPTAIApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Check that account has quota for GPTAI request.
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasQuota(accountId, options) {
            const localVarAxiosArgs = GPTAIApiAxiosParamCreator(configuration).hasQuota(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * GPTAIApi - factory interface
 * @export
 */
export const GPTAIApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Check that account has quota for GPTAI request.
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        hasQuota(accountId, options) {
            return GPTAIApiFp(configuration).hasQuota(accountId, options)(axios, basePath);
        },
    };
};
/**
 * GPTAIApi - object-oriented interface
 * @export
 * @class GPTAIApi
 * @extends {BaseAPI}
 */
export class GPTAIApi extends BaseAPI {
    /**
     *
     * @summary Check that account has quota for GPTAI request.
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GPTAIApi
     */
    hasQuota(accountId, options) {
        return GPTAIApiFp(this.configuration).hasQuota(accountId, options)(this.axios, this.basePath);
    }
}
/**
 * GraphApi - axios parameter creator
 * @export
 */
export const GraphApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary graphRequest
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {string} [modificationId] Revision.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraph(accountId, projectId, file, modificationId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getGraph.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getGraph.");
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError("file", "Required parameter file was null or undefined when calling getGraph.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/graph`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (file !== undefined) {
                localVarQueryParameter["file"] = file;
            }
            if (modificationId !== undefined) {
                localVarQueryParameter["modificationId"] = modificationId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * GraphApi - functional programming interface
 * @export
 */
export const GraphApiFp = function (configuration) {
    return {
        /**
         *
         * @summary graphRequest
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {string} [modificationId] Revision.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraph(accountId, projectId, file, modificationId, options) {
            const localVarAxiosArgs = GraphApiAxiosParamCreator(configuration).getGraph(accountId, projectId, file, modificationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * GraphApi - factory interface
 * @export
 */
export const GraphApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary graphRequest
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {string} [modificationId] Revision.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraph(accountId, projectId, file, modificationId, options) {
            return GraphApiFp(configuration).getGraph(accountId, projectId, file, modificationId, options)(axios, basePath);
        },
    };
};
/**
 * GraphApi - object-oriented interface
 * @export
 * @class GraphApi
 * @extends {BaseAPI}
 */
export class GraphApi extends BaseAPI {
    /**
     *
     * @summary graphRequest
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} [modificationId] Revision.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphApi
     */
    getGraph(accountId, projectId, file, modificationId, options) {
        return GraphApiFp(this.configuration).getGraph(accountId, projectId, file, modificationId, options)(this.axios, this.basePath);
    }
}
/**
 * GraphV2Api - axios parameter creator
 * @export
 */
export const GraphV2ApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileTree(accountId, projectId, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getFileTree.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getFileTree.");
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError("file", "Required parameter file was null or undefined when calling getFileTree.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/graph2/tree`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (file !== undefined) {
                localVarQueryParameter["file"] = file;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {string} [context] Context path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraph(accountId, projectId, file, context, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getGraph.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getGraph.");
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError("file", "Required parameter file was null or undefined when calling getGraph.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/graph2`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (file !== undefined) {
                localVarQueryParameter["file"] = file;
            }
            if (context !== undefined) {
                localVarQueryParameter["context"] = context;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {GraphV2UpdateData} graphV2UpdateData
         * @param {boolean} [force] Force.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGraph(accountId, projectId, graphV2UpdateData, force, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateGraph.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling updateGraph.");
            }
            // verify required parameter 'graphV2UpdateData' is not null or undefined
            if (graphV2UpdateData === null || graphV2UpdateData === undefined) {
                throw new RequiredError("graphV2UpdateData", "Required parameter graphV2UpdateData was null or undefined when calling updateGraph.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/graph2/update`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (force !== undefined) {
                localVarQueryParameter["force"] = force;
            }
            localVarHeaderParameter["Content-Type"] =
                "application/json; charset=UTF-8";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof graphV2UpdateData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(graphV2UpdateData !== undefined ? graphV2UpdateData : {})
                : graphV2UpdateData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * GraphV2Api - functional programming interface
 * @export
 */
export const GraphV2ApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileTree(accountId, projectId, file, options) {
            const localVarAxiosArgs = GraphV2ApiAxiosParamCreator(configuration).getFileTree(accountId, projectId, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {string} [context] Context path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraph(accountId, projectId, file, context, options) {
            const localVarAxiosArgs = GraphV2ApiAxiosParamCreator(configuration).getGraph(accountId, projectId, file, context, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {GraphV2UpdateData} graphV2UpdateData
         * @param {boolean} [force] Force.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGraph(accountId, projectId, graphV2UpdateData, force, options) {
            const localVarAxiosArgs = GraphV2ApiAxiosParamCreator(configuration).updateGraph(accountId, projectId, graphV2UpdateData, force, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * GraphV2Api - factory interface
 * @export
 */
export const GraphV2ApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileTree(accountId, projectId, file, options) {
            return GraphV2ApiFp(configuration).getFileTree(accountId, projectId, file, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {string} [context] Context path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraph(accountId, projectId, file, context, options) {
            return GraphV2ApiFp(configuration).getGraph(accountId, projectId, file, context, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {GraphV2UpdateData} graphV2UpdateData
         * @param {boolean} [force] Force.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateGraph(accountId, projectId, graphV2UpdateData, force, options) {
            return GraphV2ApiFp(configuration).updateGraph(accountId, projectId, graphV2UpdateData, force, options)(axios, basePath);
        },
    };
};
/**
 * GraphV2Api - object-oriented interface
 * @export
 * @class GraphV2Api
 * @extends {BaseAPI}
 */
export class GraphV2Api extends BaseAPI {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphV2Api
     */
    getFileTree(accountId, projectId, file, options) {
        return GraphV2ApiFp(this.configuration).getFileTree(accountId, projectId, file, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} [context] Context path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphV2Api
     */
    getGraph(accountId, projectId, file, context, options) {
        return GraphV2ApiFp(this.configuration).getGraph(accountId, projectId, file, context, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {GraphV2UpdateData} graphV2UpdateData
     * @param {boolean} [force] Force.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof GraphV2Api
     */
    updateGraph(accountId, projectId, graphV2UpdateData, force, options) {
        return GraphV2ApiFp(this.configuration).updateGraph(accountId, projectId, graphV2UpdateData, force, options)(this.axios, this.basePath);
    }
}
/**
 * HealthApi - axios parameter creator
 * @export
 */
export const HealthApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Health check of mongo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options = {}) {
            const localVarPath = `/api/editorbe/health-check`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * HealthApi - functional programming interface
 * @export
 */
export const HealthApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Health check of mongo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            const localVarAxiosArgs = HealthApiAxiosParamCreator(configuration).healthCheck(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * HealthApi - factory interface
 * @export
 */
export const HealthApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Health check of mongo
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        healthCheck(options) {
            return HealthApiFp(configuration).healthCheck(options)(axios, basePath);
        },
    };
};
/**
 * HealthApi - object-oriented interface
 * @export
 * @class HealthApi
 * @extends {BaseAPI}
 */
export class HealthApi extends BaseAPI {
    /**
     *
     * @summary Health check of mongo
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HealthApi
     */
    healthCheck(options) {
        return HealthApiFp(this.configuration).healthCheck(options)(this.axios, this.basePath);
    }
}
/**
 * JGraphApi - axios parameter creator
 * @export
 */
export const JGraphApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ComplexRenameStateData} complexRenameStateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complexRenameState(accountId, projectId, complexRenameStateData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling complexRenameState.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling complexRenameState.");
            }
            // verify required parameter 'complexRenameStateData' is not null or undefined
            if (complexRenameStateData === null ||
                complexRenameStateData === undefined) {
                throw new RequiredError("complexRenameStateData", "Required parameter complexRenameStateData was null or undefined when calling complexRenameState.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/complex-rename-state`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] =
                "application/json; charset=UTF-8";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof complexRenameStateData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(complexRenameStateData !== undefined ? complexRenameStateData : {})
                : complexRenameStateData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {CreateJStateData} createJStateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createState(accountId, projectId, createJStateData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createState.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling createState.");
            }
            // verify required parameter 'createJStateData' is not null or undefined
            if (createJStateData === null || createJStateData === undefined) {
                throw new RequiredError("createJStateData", "Required parameter createJStateData was null or undefined when calling createState.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/create`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] =
                "application/json; charset=UTF-8";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createJStateData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createJStateData !== undefined ? createJStateData : {})
                : createJStateData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DeleteJStateData} deleteJStateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteState(accountId, projectId, deleteJStateData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteState.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling deleteState.");
            }
            // verify required parameter 'deleteJStateData' is not null or undefined
            if (deleteJStateData === null || deleteJStateData === undefined) {
                throw new RequiredError("deleteJStateData", "Required parameter deleteJStateData was null or undefined when calling deleteState.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/delete`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] =
                "application/json; charset=UTF-8";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof deleteJStateData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(deleteJStateData !== undefined ? deleteJStateData : {})
                : deleteJStateData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraph(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getGraph.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getGraph.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/get`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagLocators(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTagLocators.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getTagLocators.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/tagLocators`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {JStateMovementsData} jStateMovementsData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveStates(accountId, projectId, jStateMovementsData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling moveStates.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling moveStates.");
            }
            // verify required parameter 'jStateMovementsData' is not null or undefined
            if (jStateMovementsData === null || jStateMovementsData === undefined) {
                throw new RequiredError("jStateMovementsData", "Required parameter jStateMovementsData was null or undefined when calling moveStates.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/move`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] =
                "application/json; charset=UTF-8";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof jStateMovementsData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(jStateMovementsData !== undefined ? jStateMovementsData : {})
                : jStateMovementsData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {RenameIntentData} renameIntentData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameIntent(accountId, projectId, renameIntentData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling renameIntent.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling renameIntent.");
            }
            // verify required parameter 'renameIntentData' is not null or undefined
            if (renameIntentData === null || renameIntentData === undefined) {
                throw new RequiredError("renameIntentData", "Required parameter renameIntentData was null or undefined when calling renameIntent.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/rename-intent`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] =
                "application/json; charset=UTF-8";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof renameIntentData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(renameIntentData !== undefined ? renameIntentData : {})
                : renameIntentData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {UpdateJStateData} updateJStateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateState(accountId, projectId, updateJStateData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateState.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling updateState.");
            }
            // verify required parameter 'updateJStateData' is not null or undefined
            if (updateJStateData === null || updateJStateData === undefined) {
                throw new RequiredError("updateJStateData", "Required parameter updateJStateData was null or undefined when calling updateState.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/update`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] =
                "application/json; charset=UTF-8";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof updateJStateData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(updateJStateData !== undefined ? updateJStateData : {})
                : updateJStateData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * JGraphApi - functional programming interface
 * @export
 */
export const JGraphApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ComplexRenameStateData} complexRenameStateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complexRenameState(accountId, projectId, complexRenameStateData, options) {
            const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).complexRenameState(accountId, projectId, complexRenameStateData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {CreateJStateData} createJStateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createState(accountId, projectId, createJStateData, options) {
            const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).createState(accountId, projectId, createJStateData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DeleteJStateData} deleteJStateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteState(accountId, projectId, deleteJStateData, options) {
            const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).deleteState(accountId, projectId, deleteJStateData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraph(accountId, projectId, options) {
            const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).getGraph(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagLocators(accountId, projectId, options) {
            const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).getTagLocators(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {JStateMovementsData} jStateMovementsData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveStates(accountId, projectId, jStateMovementsData, options) {
            const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).moveStates(accountId, projectId, jStateMovementsData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {RenameIntentData} renameIntentData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameIntent(accountId, projectId, renameIntentData, options) {
            const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).renameIntent(accountId, projectId, renameIntentData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {UpdateJStateData} updateJStateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateState(accountId, projectId, updateJStateData, options) {
            const localVarAxiosArgs = JGraphApiAxiosParamCreator(configuration).updateState(accountId, projectId, updateJStateData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * JGraphApi - factory interface
 * @export
 */
export const JGraphApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ComplexRenameStateData} complexRenameStateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        complexRenameState(accountId, projectId, complexRenameStateData, options) {
            return JGraphApiFp(configuration).complexRenameState(accountId, projectId, complexRenameStateData, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {CreateJStateData} createJStateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createState(accountId, projectId, createJStateData, options) {
            return JGraphApiFp(configuration).createState(accountId, projectId, createJStateData, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {DeleteJStateData} deleteJStateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteState(accountId, projectId, deleteJStateData, options) {
            return JGraphApiFp(configuration).deleteState(accountId, projectId, deleteJStateData, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getGraph(accountId, projectId, options) {
            return JGraphApiFp(configuration).getGraph(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagLocators(accountId, projectId, options) {
            return JGraphApiFp(configuration).getTagLocators(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {JStateMovementsData} jStateMovementsData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        moveStates(accountId, projectId, jStateMovementsData, options) {
            return JGraphApiFp(configuration).moveStates(accountId, projectId, jStateMovementsData, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {RenameIntentData} renameIntentData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameIntent(accountId, projectId, renameIntentData, options) {
            return JGraphApiFp(configuration).renameIntent(accountId, projectId, renameIntentData, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {UpdateJStateData} updateJStateData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateState(accountId, projectId, updateJStateData, options) {
            return JGraphApiFp(configuration).updateState(accountId, projectId, updateJStateData, options)(axios, basePath);
        },
    };
};
/**
 * JGraphApi - object-oriented interface
 * @export
 * @class JGraphApi
 * @extends {BaseAPI}
 */
export class JGraphApi extends BaseAPI {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ComplexRenameStateData} complexRenameStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JGraphApi
     */
    complexRenameState(accountId, projectId, complexRenameStateData, options) {
        return JGraphApiFp(this.configuration).complexRenameState(accountId, projectId, complexRenameStateData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {CreateJStateData} createJStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JGraphApi
     */
    createState(accountId, projectId, createJStateData, options) {
        return JGraphApiFp(this.configuration).createState(accountId, projectId, createJStateData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {DeleteJStateData} deleteJStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JGraphApi
     */
    deleteState(accountId, projectId, deleteJStateData, options) {
        return JGraphApiFp(this.configuration).deleteState(accountId, projectId, deleteJStateData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JGraphApi
     */
    getGraph(accountId, projectId, options) {
        return JGraphApiFp(this.configuration).getGraph(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JGraphApi
     */
    getTagLocators(accountId, projectId, options) {
        return JGraphApiFp(this.configuration).getTagLocators(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {JStateMovementsData} jStateMovementsData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JGraphApi
     */
    moveStates(accountId, projectId, jStateMovementsData, options) {
        return JGraphApiFp(this.configuration).moveStates(accountId, projectId, jStateMovementsData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {RenameIntentData} renameIntentData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JGraphApi
     */
    renameIntent(accountId, projectId, renameIntentData, options) {
        return JGraphApiFp(this.configuration).renameIntent(accountId, projectId, renameIntentData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {UpdateJStateData} updateJStateData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JGraphApi
     */
    updateState(accountId, projectId, updateJStateData, options) {
        return JGraphApiFp(this.configuration).updateState(accountId, projectId, updateJStateData, options)(this.axios, this.basePath);
    }
}
/**
 * JGraphStickersApi - axios parameter creator
 * @export
 */
export const JGraphStickersApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {JGraphStickerBatchUpdateRequest} jGraphStickerBatchUpdateRequest Stickers to add or update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpsertJGraphSticker(accountId, projectId, jGraphStickerBatchUpdateRequest, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling batchUpsertJGraphSticker.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling batchUpsertJGraphSticker.");
            }
            // verify required parameter 'jGraphStickerBatchUpdateRequest' is not null or undefined
            if (jGraphStickerBatchUpdateRequest === null ||
                jGraphStickerBatchUpdateRequest === undefined) {
                throw new RequiredError("jGraphStickerBatchUpdateRequest", "Required parameter jGraphStickerBatchUpdateRequest was null or undefined when calling batchUpsertJGraphSticker.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/stickers`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof jGraphStickerBatchUpdateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(jGraphStickerBatchUpdateRequest !== undefined
                    ? jGraphStickerBatchUpdateRequest
                    : {})
                : jGraphStickerBatchUpdateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} stickerId Sticker identifier. Should unique within a single project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJGraphSticker(accountId, projectId, stickerId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteJGraphSticker.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling deleteJGraphSticker.");
            }
            // verify required parameter 'stickerId' is not null or undefined
            if (stickerId === null || stickerId === undefined) {
                throw new RequiredError("stickerId", "Required parameter stickerId was null or undefined when calling deleteJGraphSticker.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/stickers/{stickerId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"stickerId"}}`, encodeURIComponent(String(stickerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} stickerId Sticker identifier. Should unique within a single project
         * @param {JGraphStickerUpdateRequest} jGraphStickerUpdateRequest Sticker to add or update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertJGraphSticker(accountId, projectId, stickerId, jGraphStickerUpdateRequest, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling upsertJGraphSticker.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling upsertJGraphSticker.");
            }
            // verify required parameter 'stickerId' is not null or undefined
            if (stickerId === null || stickerId === undefined) {
                throw new RequiredError("stickerId", "Required parameter stickerId was null or undefined when calling upsertJGraphSticker.");
            }
            // verify required parameter 'jGraphStickerUpdateRequest' is not null or undefined
            if (jGraphStickerUpdateRequest === null ||
                jGraphStickerUpdateRequest === undefined) {
                throw new RequiredError("jGraphStickerUpdateRequest", "Required parameter jGraphStickerUpdateRequest was null or undefined when calling upsertJGraphSticker.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/stickers/{stickerId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"stickerId"}}`, encodeURIComponent(String(stickerId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof jGraphStickerUpdateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(jGraphStickerUpdateRequest !== undefined
                    ? jGraphStickerUpdateRequest
                    : {})
                : jGraphStickerUpdateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * JGraphStickersApi - functional programming interface
 * @export
 */
export const JGraphStickersApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {JGraphStickerBatchUpdateRequest} jGraphStickerBatchUpdateRequest Stickers to add or update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpsertJGraphSticker(accountId, projectId, jGraphStickerBatchUpdateRequest, options) {
            const localVarAxiosArgs = JGraphStickersApiAxiosParamCreator(configuration).batchUpsertJGraphSticker(accountId, projectId, jGraphStickerBatchUpdateRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} stickerId Sticker identifier. Should unique within a single project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJGraphSticker(accountId, projectId, stickerId, options) {
            const localVarAxiosArgs = JGraphStickersApiAxiosParamCreator(configuration).deleteJGraphSticker(accountId, projectId, stickerId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} stickerId Sticker identifier. Should unique within a single project
         * @param {JGraphStickerUpdateRequest} jGraphStickerUpdateRequest Sticker to add or update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertJGraphSticker(accountId, projectId, stickerId, jGraphStickerUpdateRequest, options) {
            const localVarAxiosArgs = JGraphStickersApiAxiosParamCreator(configuration).upsertJGraphSticker(accountId, projectId, stickerId, jGraphStickerUpdateRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * JGraphStickersApi - factory interface
 * @export
 */
export const JGraphStickersApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {JGraphStickerBatchUpdateRequest} jGraphStickerBatchUpdateRequest Stickers to add or update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpsertJGraphSticker(accountId, projectId, jGraphStickerBatchUpdateRequest, options) {
            return JGraphStickersApiFp(configuration).batchUpsertJGraphSticker(accountId, projectId, jGraphStickerBatchUpdateRequest, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} stickerId Sticker identifier. Should unique within a single project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteJGraphSticker(accountId, projectId, stickerId, options) {
            return JGraphStickersApiFp(configuration).deleteJGraphSticker(accountId, projectId, stickerId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} stickerId Sticker identifier. Should unique within a single project
         * @param {JGraphStickerUpdateRequest} jGraphStickerUpdateRequest Sticker to add or update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        upsertJGraphSticker(accountId, projectId, stickerId, jGraphStickerUpdateRequest, options) {
            return JGraphStickersApiFp(configuration).upsertJGraphSticker(accountId, projectId, stickerId, jGraphStickerUpdateRequest, options)(axios, basePath);
        },
    };
};
/**
 * JGraphStickersApi - object-oriented interface
 * @export
 * @class JGraphStickersApi
 * @extends {BaseAPI}
 */
export class JGraphStickersApi extends BaseAPI {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {JGraphStickerBatchUpdateRequest} jGraphStickerBatchUpdateRequest Stickers to add or update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JGraphStickersApi
     */
    batchUpsertJGraphSticker(accountId, projectId, jGraphStickerBatchUpdateRequest, options) {
        return JGraphStickersApiFp(this.configuration).batchUpsertJGraphSticker(accountId, projectId, jGraphStickerBatchUpdateRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} stickerId Sticker identifier. Should unique within a single project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JGraphStickersApi
     */
    deleteJGraphSticker(accountId, projectId, stickerId, options) {
        return JGraphStickersApiFp(this.configuration).deleteJGraphSticker(accountId, projectId, stickerId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} stickerId Sticker identifier. Should unique within a single project
     * @param {JGraphStickerUpdateRequest} jGraphStickerUpdateRequest Sticker to add or update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JGraphStickersApi
     */
    upsertJGraphSticker(accountId, projectId, stickerId, jGraphStickerUpdateRequest, options) {
        return JGraphStickersApiFp(this.configuration).upsertJGraphSticker(accountId, projectId, stickerId, jGraphStickerUpdateRequest, options)(this.axios, this.basePath);
    }
}
/**
 * JGraphVisualsApi - axios parameter creator
 * @export
 */
export const JGraphVisualsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJGraphVisuals(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getJGraphVisuals.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getJGraphVisuals.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/visuals`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {JGraphVisuals} jGraphVisuals Updated settings
         * @param {boolean} [force] Force.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJGraphVisuals(accountId, projectId, jGraphVisuals, force, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateJGraphVisuals.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling updateJGraphVisuals.");
            }
            // verify required parameter 'jGraphVisuals' is not null or undefined
            if (jGraphVisuals === null || jGraphVisuals === undefined) {
                throw new RequiredError("jGraphVisuals", "Required parameter jGraphVisuals was null or undefined when calling updateJGraphVisuals.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/jgraph/visuals`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (force !== undefined) {
                localVarQueryParameter["force"] = force;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof jGraphVisuals !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(jGraphVisuals !== undefined ? jGraphVisuals : {})
                : jGraphVisuals || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * JGraphVisualsApi - functional programming interface
 * @export
 */
export const JGraphVisualsApiFp = function (configuration) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJGraphVisuals(accountId, projectId, options) {
            const localVarAxiosArgs = JGraphVisualsApiAxiosParamCreator(configuration).getJGraphVisuals(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {JGraphVisuals} jGraphVisuals Updated settings
         * @param {boolean} [force] Force.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJGraphVisuals(accountId, projectId, jGraphVisuals, force, options) {
            const localVarAxiosArgs = JGraphVisualsApiAxiosParamCreator(configuration).updateJGraphVisuals(accountId, projectId, jGraphVisuals, force, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * JGraphVisualsApi - factory interface
 * @export
 */
export const JGraphVisualsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getJGraphVisuals(accountId, projectId, options) {
            return JGraphVisualsApiFp(configuration).getJGraphVisuals(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {JGraphVisuals} jGraphVisuals Updated settings
         * @param {boolean} [force] Force.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateJGraphVisuals(accountId, projectId, jGraphVisuals, force, options) {
            return JGraphVisualsApiFp(configuration).updateJGraphVisuals(accountId, projectId, jGraphVisuals, force, options)(axios, basePath);
        },
    };
};
/**
 * JGraphVisualsApi - object-oriented interface
 * @export
 * @class JGraphVisualsApi
 * @extends {BaseAPI}
 */
export class JGraphVisualsApi extends BaseAPI {
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JGraphVisualsApi
     */
    getJGraphVisuals(accountId, projectId, options) {
        return JGraphVisualsApiFp(this.configuration).getJGraphVisuals(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {JGraphVisuals} jGraphVisuals Updated settings
     * @param {boolean} [force] Force.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof JGraphVisualsApi
     */
    updateJGraphVisuals(accountId, projectId, jGraphVisuals, force, options) {
        return JGraphVisualsApiFp(this.configuration).updateJGraphVisuals(accountId, projectId, jGraphVisuals, force, options)(this.axios, this.basePath);
    }
}
/**
 * LogsApi - axios parameter creator
 * @export
 */
export const LogsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Method for getting logs.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Date} from Start time for requesting logs. UTC.
         * @param {string} [botId] Bot identifier.
         * @param {Date} [to] End time for requesting logs. If null - to last log. UTC.
         * @param {Array<ServerLogLevel>} [level] Level of logs. If null - all levels.
         * @param {Array<ServerLogModule>} [module] Module of logs. If null - all modules.
         * @param {Array<ServerLogApp>} [app] App of Logs. If null - all logs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs(accountId, projectId, from, botId, to, level, module, app, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getLogs.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getLogs.");
            }
            // verify required parameter 'from' is not null or undefined
            if (from === null || from === undefined) {
                throw new RequiredError("from", "Required parameter from was null or undefined when calling getLogs.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/server-logs`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (botId !== undefined) {
                localVarQueryParameter["botId"] = botId;
            }
            if (from !== undefined) {
                localVarQueryParameter["from"] =
                    from instanceof Date ? from.toISOString() : from;
            }
            if (to !== undefined) {
                localVarQueryParameter["to"] =
                    to instanceof Date ? to.toISOString() : to;
            }
            if (level) {
                localVarQueryParameter["level"] = level;
            }
            if (module) {
                localVarQueryParameter["module"] = module;
            }
            if (app) {
                localVarQueryParameter["app"] = app;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * LogsApi - functional programming interface
 * @export
 */
export const LogsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Method for getting logs.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Date} from Start time for requesting logs. UTC.
         * @param {string} [botId] Bot identifier.
         * @param {Date} [to] End time for requesting logs. If null - to last log. UTC.
         * @param {Array<ServerLogLevel>} [level] Level of logs. If null - all levels.
         * @param {Array<ServerLogModule>} [module] Module of logs. If null - all modules.
         * @param {Array<ServerLogApp>} [app] App of Logs. If null - all logs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs(accountId, projectId, from, botId, to, level, module, app, options) {
            const localVarAxiosArgs = LogsApiAxiosParamCreator(configuration).getLogs(accountId, projectId, from, botId, to, level, module, app, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * LogsApi - factory interface
 * @export
 */
export const LogsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Method for getting logs.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Date} from Start time for requesting logs. UTC.
         * @param {string} [botId] Bot identifier.
         * @param {Date} [to] End time for requesting logs. If null - to last log. UTC.
         * @param {Array<ServerLogLevel>} [level] Level of logs. If null - all levels.
         * @param {Array<ServerLogModule>} [module] Module of logs. If null - all modules.
         * @param {Array<ServerLogApp>} [app] App of Logs. If null - all logs.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLogs(accountId, projectId, from, botId, to, level, module, app, options) {
            return LogsApiFp(configuration).getLogs(accountId, projectId, from, botId, to, level, module, app, options)(axios, basePath);
        },
    };
};
/**
 * LogsApi - object-oriented interface
 * @export
 * @class LogsApi
 * @extends {BaseAPI}
 */
export class LogsApi extends BaseAPI {
    /**
     *
     * @summary Method for getting logs.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Date} from Start time for requesting logs. UTC.
     * @param {string} [botId] Bot identifier.
     * @param {Date} [to] End time for requesting logs. If null - to last log. UTC.
     * @param {Array<ServerLogLevel>} [level] Level of logs. If null - all levels.
     * @param {Array<ServerLogModule>} [module] Module of logs. If null - all modules.
     * @param {Array<ServerLogApp>} [app] App of Logs. If null - all logs.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LogsApi
     */
    getLogs(accountId, projectId, from, botId, to, level, module, app, options) {
        return LogsApiFp(this.configuration).getLogs(accountId, projectId, from, botId, to, level, module, app, options)(this.axios, this.basePath);
    }
}
/**
 * ProjectApi - axios parameter creator
 * @export
 */
export const ProjectApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Create project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} newProjectId New project id.
         * @param {boolean} [isJGraphLite] Flag which means that zenflow project should be opened in jgraph.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clone(accountId, projectId, newProjectId, isJGraphLite, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling clone.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling clone.");
            }
            // verify required parameter 'newProjectId' is not null or undefined
            if (newProjectId === null || newProjectId === undefined) {
                throw new RequiredError("newProjectId", "Required parameter newProjectId was null or undefined when calling clone.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/clone`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (newProjectId !== undefined) {
                localVarQueryParameter["newProjectId"] = newProjectId;
            }
            if (isJGraphLite !== undefined) {
                localVarQueryParameter["isJGraphLite"] = isJGraphLite;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create project
         * @param {number} accountId User identifier.
         * @param {CreateProjectData} createProjectData New project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(accountId, createProjectData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createProject.");
            }
            // verify required parameter 'createProjectData' is not null or undefined
            if (createProjectData === null || createProjectData === undefined) {
                throw new RequiredError("createProjectData", "Required parameter createProjectData was null or undefined when calling createProject.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createProjectData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createProjectData !== undefined ? createProjectData : {})
                : createProjectData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Create new state in root theme inside entrypoint
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {CreateRootStateRequest} createRootStateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRootState(accountId, projectId, createRootStateRequest, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling createRootState.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling createRootState.");
            }
            // verify required parameter 'createRootStateRequest' is not null or undefined
            if (createRootStateRequest === null ||
                createRootStateRequest === undefined) {
                throw new RequiredError("createRootStateRequest", "Required parameter createRootStateRequest was null or undefined when calling createRootState.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/scenario/state`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof createRootStateRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(createRootStateRequest !== undefined ? createRootStateRequest : {})
                : createRootStateRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteProject.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling deleteProject.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary history
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryForAccount(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getHistoryForAccount.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/content/history`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getProject.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getProject.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get project settings from chatbot.yaml. If snapshot parameters has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [snapshot] Snapshot flag.
         * @param {string} [refType] Can be [revision, tag, branch].
         * @param {string} [refValue] refValue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSettings(accountId, projectId, snapshot, refType, refValue, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getProjectSettings.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getProjectSettings.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/settings`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (snapshot !== undefined) {
                localVarQueryParameter["snapshot"] = snapshot;
            }
            if (refType !== undefined) {
                localVarQueryParameter["refType"] = refType;
            }
            if (refValue !== undefined) {
                localVarQueryParameter["refValue"] = refValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get project statistic.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectStatistic(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getProjectStatistic.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getProjectStatistic.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/statistic`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectStatus(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getProjectStatus.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getProjectStatus.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/status`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get tags list
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {TagsListFilter} [tagsListFilter] Tags list filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsList(accountId, projectId, tagsListFilter, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTagsList.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getTagsList.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/scenario/tags`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof tagsListFilter !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(tagsListFilter !== undefined ? tagsListFilter : {})
                : tagsListFilter || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get single template by name
         * @param {number} accountId User identifier.
         * @param {string} templateName Template identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(accountId, templateName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTemplate.");
            }
            // verify required parameter 'templateName' is not null or undefined
            if (templateName === null || templateName === undefined) {
                throw new RequiredError("templateName", "Required parameter templateName was null or undefined when calling getTemplate.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/templates/{templateName}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"templateName"}}`, encodeURIComponent(String(templateName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get single template description by name
         * @param {number} accountId User identifier.
         * @param {string} templateName Template identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateDescription(accountId, templateName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTemplateDescription.");
            }
            // verify required parameter 'templateName' is not null or undefined
            if (templateName === null || templateName === undefined) {
                throw new RequiredError("templateName", "Required parameter templateName was null or undefined when calling getTemplateDescription.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/templates/{templateName}/description`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"templateName"}}`, encodeURIComponent(String(templateName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get single template variables by name
         * @param {number} accountId User identifier.
         * @param {string} templateName Template identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateVariables(accountId, templateName, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTemplateVariables.");
            }
            // verify required parameter 'templateName' is not null or undefined
            if (templateName === null || templateName === undefined) {
                throw new RequiredError("templateName", "Required parameter templateName was null or undefined when calling getTemplateVariables.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/templates/{templateName}/variables`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"templateName"}}`, encodeURIComponent(String(templateName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List custom tags
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomTags(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling listCustomTags.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling listCustomTags.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/custom-tags`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Custom text campaign blocks list from chatbot.yml If snapshot parameter has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [snapshot] Snapshot flag.
         * @param {string} [refType] Can be [revision, tag, branch].
         * @param {string} [refValue] refValue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomTextCampaignBlocks(accountId, projectId, snapshot, refType, refValue, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling listCustomTextCampaignBlocks.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling listCustomTextCampaignBlocks.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/custom-blocks`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (snapshot !== undefined) {
                localVarQueryParameter["snapshot"] = snapshot;
            }
            if (refType !== undefined) {
                localVarQueryParameter["refType"] = refType;
            }
            if (refValue !== undefined) {
                localVarQueryParameter["refValue"] = refValue;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Return list of projects
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects(accountId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling listProjects.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List templates
         * @param {number} accountId User identifier.
         * @param {string} namespace Namespace.
         * @param {string} [language] Language.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplates(accountId, namespace, language, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling listTemplates.");
            }
            // verify required parameter 'namespace' is not null or undefined
            if (namespace === null || namespace === undefined) {
                throw new RequiredError("namespace", "Required parameter namespace was null or undefined when calling listTemplates.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/templates`.replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (namespace !== undefined) {
                localVarQueryParameter["namespace"] = namespace;
            }
            if (language !== undefined) {
                localVarQueryParameter["language"] = language;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update project.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} accountId User identifier.
         * @param {ProjectData} projectData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId, accountId, projectData, options = {}) {
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling updateProject.");
            }
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateProject.");
            }
            // verify required parameter 'projectData' is not null or undefined
            if (projectData === null || projectData === undefined) {
                throw new RequiredError("projectData", "Required parameter projectData was null or undefined when calling updateProject.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}`
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)))
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof projectData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(projectData !== undefined ? projectData : {})
                : projectData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update custom tags
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<CustomTagPreferencesData>} customTagPreferencesData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagsUserPreferences(accountId, projectId, customTagPreferencesData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateTagsUserPreferences.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling updateTagsUserPreferences.");
            }
            // verify required parameter 'customTagPreferencesData' is not null or undefined
            if (customTagPreferencesData === null ||
                customTagPreferencesData === undefined) {
                throw new RequiredError("customTagPreferencesData", "Required parameter customTagPreferencesData was null or undefined when calling updateTagsUserPreferences.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/custom-tags/preferences`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof customTagPreferencesData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(customTagPreferencesData !== undefined
                    ? customTagPreferencesData
                    : {})
                : customTagPreferencesData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ProjectApi - functional programming interface
 * @export
 */
export const ProjectApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Create project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} newProjectId New project id.
         * @param {boolean} [isJGraphLite] Flag which means that zenflow project should be opened in jgraph.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clone(accountId, projectId, newProjectId, isJGraphLite, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).clone(accountId, projectId, newProjectId, isJGraphLite, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create project
         * @param {number} accountId User identifier.
         * @param {CreateProjectData} createProjectData New project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(accountId, createProjectData, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).createProject(accountId, createProjectData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Create new state in root theme inside entrypoint
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {CreateRootStateRequest} createRootStateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRootState(accountId, projectId, createRootStateRequest, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).createRootState(accountId, projectId, createRootStateRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).deleteProject(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary history
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryForAccount(accountId, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getHistoryForAccount(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getProject(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get project settings from chatbot.yaml. If snapshot parameters has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [snapshot] Snapshot flag.
         * @param {string} [refType] Can be [revision, tag, branch].
         * @param {string} [refValue] refValue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSettings(accountId, projectId, snapshot, refType, refValue, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getProjectSettings(accountId, projectId, snapshot, refType, refValue, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get project statistic.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectStatistic(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getProjectStatistic(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectStatus(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getProjectStatus(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get tags list
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {TagsListFilter} [tagsListFilter] Tags list filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsList(accountId, projectId, tagsListFilter, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getTagsList(accountId, projectId, tagsListFilter, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get single template by name
         * @param {number} accountId User identifier.
         * @param {string} templateName Template identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(accountId, templateName, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getTemplate(accountId, templateName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get single template description by name
         * @param {number} accountId User identifier.
         * @param {string} templateName Template identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateDescription(accountId, templateName, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getTemplateDescription(accountId, templateName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get single template variables by name
         * @param {number} accountId User identifier.
         * @param {string} templateName Template identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateVariables(accountId, templateName, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).getTemplateVariables(accountId, templateName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary List custom tags
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomTags(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).listCustomTags(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Custom text campaign blocks list from chatbot.yml If snapshot parameter has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [snapshot] Snapshot flag.
         * @param {string} [refType] Can be [revision, tag, branch].
         * @param {string} [refValue] refValue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomTextCampaignBlocks(accountId, projectId, snapshot, refType, refValue, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).listCustomTextCampaignBlocks(accountId, projectId, snapshot, refType, refValue, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Return list of projects
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects(accountId, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).listProjects(accountId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary List templates
         * @param {number} accountId User identifier.
         * @param {string} namespace Namespace.
         * @param {string} [language] Language.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplates(accountId, namespace, language, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).listTemplates(accountId, namespace, language, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update project.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} accountId User identifier.
         * @param {ProjectData} projectData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId, accountId, projectData, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).updateProject(projectId, accountId, projectData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update custom tags
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<CustomTagPreferencesData>} customTagPreferencesData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagsUserPreferences(accountId, projectId, customTagPreferencesData, options) {
            const localVarAxiosArgs = ProjectApiAxiosParamCreator(configuration).updateTagsUserPreferences(accountId, projectId, customTagPreferencesData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ProjectApi - factory interface
 * @export
 */
export const ProjectApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Create project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} newProjectId New project id.
         * @param {boolean} [isJGraphLite] Flag which means that zenflow project should be opened in jgraph.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        clone(accountId, projectId, newProjectId, isJGraphLite, options) {
            return ProjectApiFp(configuration).clone(accountId, projectId, newProjectId, isJGraphLite, options)(axios, basePath);
        },
        /**
         *
         * @summary Create project
         * @param {number} accountId User identifier.
         * @param {CreateProjectData} createProjectData New project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createProject(accountId, createProjectData, options) {
            return ProjectApiFp(configuration).createProject(accountId, createProjectData, options)(axios, basePath);
        },
        /**
         *
         * @summary Create new state in root theme inside entrypoint
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {CreateRootStateRequest} createRootStateRequest
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createRootState(accountId, projectId, createRootStateRequest, options) {
            return ProjectApiFp(configuration).createRootState(accountId, projectId, createRootStateRequest, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete project
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteProject(accountId, projectId, options) {
            return ProjectApiFp(configuration).deleteProject(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary history
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistoryForAccount(accountId, options) {
            return ProjectApiFp(configuration).getHistoryForAccount(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProject(accountId, projectId, options) {
            return ProjectApiFp(configuration).getProject(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get project settings from chatbot.yaml. If snapshot parameters has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [snapshot] Snapshot flag.
         * @param {string} [refType] Can be [revision, tag, branch].
         * @param {string} [refValue] refValue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSettings(accountId, projectId, snapshot, refType, refValue, options) {
            return ProjectApiFp(configuration).getProjectSettings(accountId, projectId, snapshot, refType, refValue, options)(axios, basePath);
        },
        /**
         *
         * @summary Get project statistic.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectStatistic(accountId, projectId, options) {
            return ProjectApiFp(configuration).getProjectStatistic(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectStatus(accountId, projectId, options) {
            return ProjectApiFp(configuration).getProjectStatus(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get tags list
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {TagsListFilter} [tagsListFilter] Tags list filter
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTagsList(accountId, projectId, tagsListFilter, options) {
            return ProjectApiFp(configuration).getTagsList(accountId, projectId, tagsListFilter, options)(axios, basePath);
        },
        /**
         *
         * @summary Get single template by name
         * @param {number} accountId User identifier.
         * @param {string} templateName Template identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplate(accountId, templateName, options) {
            return ProjectApiFp(configuration).getTemplate(accountId, templateName, options)(axios, basePath);
        },
        /**
         *
         * @summary Get single template description by name
         * @param {number} accountId User identifier.
         * @param {string} templateName Template identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateDescription(accountId, templateName, options) {
            return ProjectApiFp(configuration).getTemplateDescription(accountId, templateName, options)(axios, basePath);
        },
        /**
         *
         * @summary Get single template variables by name
         * @param {number} accountId User identifier.
         * @param {string} templateName Template identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTemplateVariables(accountId, templateName, options) {
            return ProjectApiFp(configuration).getTemplateVariables(accountId, templateName, options)(axios, basePath);
        },
        /**
         *
         * @summary List custom tags
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomTags(accountId, projectId, options) {
            return ProjectApiFp(configuration).listCustomTags(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Custom text campaign blocks list from chatbot.yml If snapshot parameter has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [snapshot] Snapshot flag.
         * @param {string} [refType] Can be [revision, tag, branch].
         * @param {string} [refValue] refValue
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listCustomTextCampaignBlocks(accountId, projectId, snapshot, refType, refValue, options) {
            return ProjectApiFp(configuration).listCustomTextCampaignBlocks(accountId, projectId, snapshot, refType, refValue, options)(axios, basePath);
        },
        /**
         *
         * @summary Return list of projects
         * @param {number} accountId User identifier.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listProjects(accountId, options) {
            return ProjectApiFp(configuration).listProjects(accountId, options)(axios, basePath);
        },
        /**
         *
         * @summary List templates
         * @param {number} accountId User identifier.
         * @param {string} namespace Namespace.
         * @param {string} [language] Language.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        listTemplates(accountId, namespace, language, options) {
            return ProjectApiFp(configuration).listTemplates(accountId, namespace, language, options)(axios, basePath);
        },
        /**
         *
         * @summary Update project.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {number} accountId User identifier.
         * @param {ProjectData} projectData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProject(projectId, accountId, projectData, options) {
            return ProjectApiFp(configuration).updateProject(projectId, accountId, projectData, options)(axios, basePath);
        },
        /**
         *
         * @summary Update custom tags
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {Array<CustomTagPreferencesData>} customTagPreferencesData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateTagsUserPreferences(accountId, projectId, customTagPreferencesData, options) {
            return ProjectApiFp(configuration).updateTagsUserPreferences(accountId, projectId, customTagPreferencesData, options)(axios, basePath);
        },
    };
};
/**
 * ProjectApi - object-oriented interface
 * @export
 * @class ProjectApi
 * @extends {BaseAPI}
 */
export class ProjectApi extends BaseAPI {
    /**
     *
     * @summary Create project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} newProjectId New project id.
     * @param {boolean} [isJGraphLite] Flag which means that zenflow project should be opened in jgraph.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    clone(accountId, projectId, newProjectId, isJGraphLite, options) {
        return ProjectApiFp(this.configuration).clone(accountId, projectId, newProjectId, isJGraphLite, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create project
     * @param {number} accountId User identifier.
     * @param {CreateProjectData} createProjectData New project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    createProject(accountId, createProjectData, options) {
        return ProjectApiFp(this.configuration).createProject(accountId, createProjectData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Create new state in root theme inside entrypoint
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {CreateRootStateRequest} createRootStateRequest
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    createRootState(accountId, projectId, createRootStateRequest, options) {
        return ProjectApiFp(this.configuration).createRootState(accountId, projectId, createRootStateRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete project
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    deleteProject(accountId, projectId, options) {
        return ProjectApiFp(this.configuration).deleteProject(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary history
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    getHistoryForAccount(accountId, options) {
        return ProjectApiFp(this.configuration).getHistoryForAccount(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    getProject(accountId, projectId, options) {
        return ProjectApiFp(this.configuration).getProject(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get project settings from chatbot.yaml. If snapshot parameters has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [snapshot] Snapshot flag.
     * @param {string} [refType] Can be [revision, tag, branch].
     * @param {string} [refValue] refValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    getProjectSettings(accountId, projectId, snapshot, refType, refValue, options) {
        return ProjectApiFp(this.configuration).getProjectSettings(accountId, projectId, snapshot, refType, refValue, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get project statistic.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    getProjectStatistic(accountId, projectId, options) {
        return ProjectApiFp(this.configuration).getProjectStatistic(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    getProjectStatus(accountId, projectId, options) {
        return ProjectApiFp(this.configuration).getProjectStatus(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get tags list
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {TagsListFilter} [tagsListFilter] Tags list filter
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    getTagsList(accountId, projectId, tagsListFilter, options) {
        return ProjectApiFp(this.configuration).getTagsList(accountId, projectId, tagsListFilter, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get single template by name
     * @param {number} accountId User identifier.
     * @param {string} templateName Template identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    getTemplate(accountId, templateName, options) {
        return ProjectApiFp(this.configuration).getTemplate(accountId, templateName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get single template description by name
     * @param {number} accountId User identifier.
     * @param {string} templateName Template identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    getTemplateDescription(accountId, templateName, options) {
        return ProjectApiFp(this.configuration).getTemplateDescription(accountId, templateName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get single template variables by name
     * @param {number} accountId User identifier.
     * @param {string} templateName Template identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    getTemplateVariables(accountId, templateName, options) {
        return ProjectApiFp(this.configuration).getTemplateVariables(accountId, templateName, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary List custom tags
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    listCustomTags(accountId, projectId, options) {
        return ProjectApiFp(this.configuration).listCustomTags(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Custom text campaign blocks list from chatbot.yml If snapshot parameter has value true or is missing, then return settings from snapshot, refType and refValue parameters are ignored. If snapshot parameter has value false, but refType and refValue has null, then refType and ref value will be taken from the project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [snapshot] Snapshot flag.
     * @param {string} [refType] Can be [revision, tag, branch].
     * @param {string} [refValue] refValue
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    listCustomTextCampaignBlocks(accountId, projectId, snapshot, refType, refValue, options) {
        return ProjectApiFp(this.configuration).listCustomTextCampaignBlocks(accountId, projectId, snapshot, refType, refValue, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Return list of projects
     * @param {number} accountId User identifier.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    listProjects(accountId, options) {
        return ProjectApiFp(this.configuration).listProjects(accountId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary List templates
     * @param {number} accountId User identifier.
     * @param {string} namespace Namespace.
     * @param {string} [language] Language.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    listTemplates(accountId, namespace, language, options) {
        return ProjectApiFp(this.configuration).listTemplates(accountId, namespace, language, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update project.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {number} accountId User identifier.
     * @param {ProjectData} projectData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    updateProject(projectId, accountId, projectData, options) {
        return ProjectApiFp(this.configuration).updateProject(projectId, accountId, projectData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update custom tags
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {Array<CustomTagPreferencesData>} customTagPreferencesData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectApi
     */
    updateTagsUserPreferences(accountId, projectId, customTagPreferencesData, options) {
        return ProjectApiFp(this.configuration).updateTagsUserPreferences(accountId, projectId, customTagPreferencesData, options)(this.axios, this.basePath);
    }
}
/**
 * ProjectSkillsApi - axios parameter creator
 * @export
 */
export const ProjectSkillsApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Get script url for skill chat widget. Create if widget does not exist.
         * @param {number} accountId User identifier.
         * @param {string} skillName Skill name.
         * @param {string} languagePath Language.
         * @param {string} body Widget options. To get the options use /api/botadmin/accounts/{accountId}/widgetOptions/{language}.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatWidgetScriptUrl(accountId, skillName, languagePath, body, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getChatWidgetScriptUrl.");
            }
            // verify required parameter 'skillName' is not null or undefined
            if (skillName === null || skillName === undefined) {
                throw new RequiredError("skillName", "Required parameter skillName was null or undefined when calling getChatWidgetScriptUrl.");
            }
            // verify required parameter 'languagePath' is not null or undefined
            if (languagePath === null || languagePath === undefined) {
                throw new RequiredError("languagePath", "Required parameter languagePath was null or undefined when calling getChatWidgetScriptUrl.");
            }
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError("body", "Required parameter body was null or undefined when calling getChatWidgetScriptUrl.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/project-skill/{skillName}/{languagePath}/chatWidget`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"skillName"}}`, encodeURIComponent(String(skillName)))
                .replace(`{${"languagePath"}}`, encodeURIComponent(String(languagePath)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "text/plain";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary List of project skills
         * @param {number} accountId User identifier.
         * @param {string} languagePath Language.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListProjectSkills(accountId, languagePath, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getListProjectSkills.");
            }
            // verify required parameter 'languagePath' is not null or undefined
            if (languagePath === null || languagePath === undefined) {
                throw new RequiredError("languagePath", "Required parameter languagePath was null or undefined when calling getListProjectSkills.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/project-skills/{languagePath}`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"languagePath"}}`, encodeURIComponent(String(languagePath)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Return skill names and envs of project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSkill(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getProjectSkill.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getProjectSkill.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/project-skills`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update project skill.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ProjectSkillDataUpdate} projectSkillDataUpdate List of skill names and their variables.
         * @param {boolean} [commit] If true, commit on update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectSkill(accountId, projectId, projectSkillDataUpdate, commit, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling updateProjectSkill.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling updateProjectSkill.");
            }
            // verify required parameter 'projectSkillDataUpdate' is not null or undefined
            if (projectSkillDataUpdate === null ||
                projectSkillDataUpdate === undefined) {
                throw new RequiredError("projectSkillDataUpdate", "Required parameter projectSkillDataUpdate was null or undefined when calling updateProjectSkill.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/project-skills`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (commit !== undefined) {
                localVarQueryParameter["commit"] = commit;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof projectSkillDataUpdate !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(projectSkillDataUpdate !== undefined ? projectSkillDataUpdate : {})
                : projectSkillDataUpdate || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ProjectSkillsApi - functional programming interface
 * @export
 */
export const ProjectSkillsApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Get script url for skill chat widget. Create if widget does not exist.
         * @param {number} accountId User identifier.
         * @param {string} skillName Skill name.
         * @param {string} languagePath Language.
         * @param {string} body Widget options. To get the options use /api/botadmin/accounts/{accountId}/widgetOptions/{language}.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatWidgetScriptUrl(accountId, skillName, languagePath, body, options) {
            const localVarAxiosArgs = ProjectSkillsApiAxiosParamCreator(configuration).getChatWidgetScriptUrl(accountId, skillName, languagePath, body, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary List of project skills
         * @param {number} accountId User identifier.
         * @param {string} languagePath Language.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListProjectSkills(accountId, languagePath, options) {
            const localVarAxiosArgs = ProjectSkillsApiAxiosParamCreator(configuration).getListProjectSkills(accountId, languagePath, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Return skill names and envs of project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSkill(accountId, projectId, options) {
            const localVarAxiosArgs = ProjectSkillsApiAxiosParamCreator(configuration).getProjectSkill(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update project skill.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ProjectSkillDataUpdate} projectSkillDataUpdate List of skill names and their variables.
         * @param {boolean} [commit] If true, commit on update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectSkill(accountId, projectId, projectSkillDataUpdate, commit, options) {
            const localVarAxiosArgs = ProjectSkillsApiAxiosParamCreator(configuration).updateProjectSkill(accountId, projectId, projectSkillDataUpdate, commit, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ProjectSkillsApi - factory interface
 * @export
 */
export const ProjectSkillsApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Get script url for skill chat widget. Create if widget does not exist.
         * @param {number} accountId User identifier.
         * @param {string} skillName Skill name.
         * @param {string} languagePath Language.
         * @param {string} body Widget options. To get the options use /api/botadmin/accounts/{accountId}/widgetOptions/{language}.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getChatWidgetScriptUrl(accountId, skillName, languagePath, body, options) {
            return ProjectSkillsApiFp(configuration).getChatWidgetScriptUrl(accountId, skillName, languagePath, body, options)(axios, basePath);
        },
        /**
         *
         * @summary List of project skills
         * @param {number} accountId User identifier.
         * @param {string} languagePath Language.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getListProjectSkills(accountId, languagePath, options) {
            return ProjectSkillsApiFp(configuration).getListProjectSkills(accountId, languagePath, options)(axios, basePath);
        },
        /**
         *
         * @summary Return skill names and envs of project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getProjectSkill(accountId, projectId, options) {
            return ProjectSkillsApiFp(configuration).getProjectSkill(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Update project skill.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ProjectSkillDataUpdate} projectSkillDataUpdate List of skill names and their variables.
         * @param {boolean} [commit] If true, commit on update
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateProjectSkill(accountId, projectId, projectSkillDataUpdate, commit, options) {
            return ProjectSkillsApiFp(configuration).updateProjectSkill(accountId, projectId, projectSkillDataUpdate, commit, options)(axios, basePath);
        },
    };
};
/**
 * ProjectSkillsApi - object-oriented interface
 * @export
 * @class ProjectSkillsApi
 * @extends {BaseAPI}
 */
export class ProjectSkillsApi extends BaseAPI {
    /**
     *
     * @summary Get script url for skill chat widget. Create if widget does not exist.
     * @param {number} accountId User identifier.
     * @param {string} skillName Skill name.
     * @param {string} languagePath Language.
     * @param {string} body Widget options. To get the options use /api/botadmin/accounts/{accountId}/widgetOptions/{language}.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSkillsApi
     */
    getChatWidgetScriptUrl(accountId, skillName, languagePath, body, options) {
        return ProjectSkillsApiFp(this.configuration).getChatWidgetScriptUrl(accountId, skillName, languagePath, body, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary List of project skills
     * @param {number} accountId User identifier.
     * @param {string} languagePath Language.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSkillsApi
     */
    getListProjectSkills(accountId, languagePath, options) {
        return ProjectSkillsApiFp(this.configuration).getListProjectSkills(accountId, languagePath, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Return skill names and envs of project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSkillsApi
     */
    getProjectSkill(accountId, projectId, options) {
        return ProjectSkillsApiFp(this.configuration).getProjectSkill(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update project skill.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ProjectSkillDataUpdate} projectSkillDataUpdate List of skill names and their variables.
     * @param {boolean} [commit] If true, commit on update
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ProjectSkillsApi
     */
    updateProjectSkill(accountId, projectId, projectSkillDataUpdate, commit, options) {
        return ProjectSkillsApiFp(this.configuration).updateProjectSkill(accountId, projectId, projectSkillDataUpdate, commit, options)(this.axios, this.basePath);
    }
}
/**
 * SearchApi - axios parameter creator
 * @export
 */
export const SearchApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Run migration to elasticsearch for all projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateToElasticsearch(options = {}) {
            const localVarPath = `/api/editorbe-for-test/migrate-to-elasticsearch`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Replace in all files
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ReplaceRequest} replaceRequest Replace in all files request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replace(accountId, projectId, replaceRequest, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling replace.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling replace.");
            }
            // verify required parameter 'replaceRequest' is not null or undefined
            if (replaceRequest === null || replaceRequest === undefined) {
                throw new RequiredError("replaceRequest", "Required parameter replaceRequest was null or undefined when calling replace.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/replace`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "PUT" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof replaceRequest !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(replaceRequest !== undefined ? replaceRequest : {})
                : replaceRequest || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary search in all files
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query
         * @param {Array<string>} [fileExtensions]
         * @param {boolean} [isRegex]
         * @param {boolean} [isCaseSensitive]
         * @param {boolean} [isWholeWord]
         * @param {boolean} [isHiddenFiles]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling search.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling search.");
            }
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError("query", "Required parameter query was null or undefined when calling search.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/search`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (query !== undefined) {
                localVarQueryParameter["query"] = query;
            }
            if (fileExtensions) {
                localVarQueryParameter["fileExtensions"] = fileExtensions;
            }
            if (isRegex !== undefined) {
                localVarQueryParameter["isRegex"] = isRegex;
            }
            if (isCaseSensitive !== undefined) {
                localVarQueryParameter["isCaseSensitive"] = isCaseSensitive;
            }
            if (isWholeWord !== undefined) {
                localVarQueryParameter["isWholeWord"] = isWholeWord;
            }
            if (isHiddenFiles !== undefined) {
                localVarQueryParameter["isHiddenFiles"] = isHiddenFiles;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary whole search stats for all files
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query
         * @param {Array<string>} [fileExtensions]
         * @param {boolean} [isRegex]
         * @param {boolean} [isCaseSensitive]
         * @param {boolean} [isWholeWord]
         * @param {boolean} [isHiddenFiles]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchStatistics(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling searchStatistics.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling searchStatistics.");
            }
            // verify required parameter 'query' is not null or undefined
            if (query === null || query === undefined) {
                throw new RequiredError("query", "Required parameter query was null or undefined when calling searchStatistics.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/search/stats`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (query !== undefined) {
                localVarQueryParameter["query"] = query;
            }
            if (fileExtensions) {
                localVarQueryParameter["fileExtensions"] = fileExtensions;
            }
            if (isRegex !== undefined) {
                localVarQueryParameter["isRegex"] = isRegex;
            }
            if (isCaseSensitive !== undefined) {
                localVarQueryParameter["isCaseSensitive"] = isCaseSensitive;
            }
            if (isWholeWord !== undefined) {
                localVarQueryParameter["isWholeWord"] = isWholeWord;
            }
            if (isHiddenFiles !== undefined) {
                localVarQueryParameter["isHiddenFiles"] = isHiddenFiles;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SearchApi - functional programming interface
 * @export
 */
export const SearchApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Run migration to elasticsearch for all projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateToElasticsearch(options) {
            const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).migrateToElasticsearch(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Replace in all files
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ReplaceRequest} replaceRequest Replace in all files request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replace(accountId, projectId, replaceRequest, options) {
            const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).replace(accountId, projectId, replaceRequest, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary search in all files
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query
         * @param {Array<string>} [fileExtensions]
         * @param {boolean} [isRegex]
         * @param {boolean} [isCaseSensitive]
         * @param {boolean} [isWholeWord]
         * @param {boolean} [isHiddenFiles]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options) {
            const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).search(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary whole search stats for all files
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query
         * @param {Array<string>} [fileExtensions]
         * @param {boolean} [isRegex]
         * @param {boolean} [isCaseSensitive]
         * @param {boolean} [isWholeWord]
         * @param {boolean} [isHiddenFiles]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchStatistics(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options) {
            const localVarAxiosArgs = SearchApiAxiosParamCreator(configuration).searchStatistics(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SearchApi - factory interface
 * @export
 */
export const SearchApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Run migration to elasticsearch for all projects
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        migrateToElasticsearch(options) {
            return SearchApiFp(configuration).migrateToElasticsearch(options)(axios, basePath);
        },
        /**
         *
         * @summary Replace in all files
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {ReplaceRequest} replaceRequest Replace in all files request.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        replace(accountId, projectId, replaceRequest, options) {
            return SearchApiFp(configuration).replace(accountId, projectId, replaceRequest, options)(axios, basePath);
        },
        /**
         *
         * @summary search in all files
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query
         * @param {Array<string>} [fileExtensions]
         * @param {boolean} [isRegex]
         * @param {boolean} [isCaseSensitive]
         * @param {boolean} [isWholeWord]
         * @param {boolean} [isHiddenFiles]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options) {
            return SearchApiFp(configuration).search(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options)(axios, basePath);
        },
        /**
         *
         * @summary whole search stats for all files
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} query
         * @param {Array<string>} [fileExtensions]
         * @param {boolean} [isRegex]
         * @param {boolean} [isCaseSensitive]
         * @param {boolean} [isWholeWord]
         * @param {boolean} [isHiddenFiles]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        searchStatistics(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options) {
            return SearchApiFp(configuration).searchStatistics(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options)(axios, basePath);
        },
    };
};
/**
 * SearchApi - object-oriented interface
 * @export
 * @class SearchApi
 * @extends {BaseAPI}
 */
export class SearchApi extends BaseAPI {
    /**
     *
     * @summary Run migration to elasticsearch for all projects
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    migrateToElasticsearch(options) {
        return SearchApiFp(this.configuration).migrateToElasticsearch(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Replace in all files
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {ReplaceRequest} replaceRequest Replace in all files request.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    replace(accountId, projectId, replaceRequest, options) {
        return SearchApiFp(this.configuration).replace(accountId, projectId, replaceRequest, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary search in all files
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query
     * @param {Array<string>} [fileExtensions]
     * @param {boolean} [isRegex]
     * @param {boolean} [isCaseSensitive]
     * @param {boolean} [isWholeWord]
     * @param {boolean} [isHiddenFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    search(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options) {
        return SearchApiFp(this.configuration).search(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary whole search stats for all files
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} query
     * @param {Array<string>} [fileExtensions]
     * @param {boolean} [isRegex]
     * @param {boolean} [isCaseSensitive]
     * @param {boolean} [isWholeWord]
     * @param {boolean} [isHiddenFiles]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SearchApi
     */
    searchStatistics(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options) {
        return SearchApiFp(this.configuration).searchStatistics(accountId, projectId, query, fileExtensions, isRegex, isCaseSensitive, isWholeWord, isHiddenFiles, options)(this.axios, this.basePath);
    }
}
/**
 * StorageApi - axios parameter creator
 * @export
 */
export const StorageApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Update project content for backward compatibility with BotAdmin
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {BatchUpdateContentData} batchUpdateContentData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdate(accountId, projectId, batchUpdateContentData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling batchUpdate.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling batchUpdate.");
            }
            // verify required parameter 'batchUpdateContentData' is not null or undefined
            if (batchUpdateContentData === null ||
                batchUpdateContentData === undefined) {
                throw new RequiredError("batchUpdateContentData", "Required parameter batchUpdateContentData was null or undefined when calling batchUpdate.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/batch-update`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof batchUpdateContentData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(batchUpdateContentData !== undefined ? batchUpdateContentData : {})
                : batchUpdateContentData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Cleanup mirror folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanupMirrorFolder(options = {}) {
            const localVarPath = `/api/editorbe-for-test/cleanup-mirror-folder`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Push
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {CommitAndPushData} commitAndPushData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitAndPush(accountId, projectId, commitAndPushData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling commitAndPush.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling commitAndPush.");
            }
            // verify required parameter 'commitAndPushData' is not null or undefined
            if (commitAndPushData === null || commitAndPushData === undefined) {
                throw new RequiredError("commitAndPushData", "Required parameter commitAndPushData was null or undefined when calling commitAndPush.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/push`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof commitAndPushData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(commitAndPushData !== undefined ? commitAndPushData : {})
                : commitAndPushData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Copy file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {RenameFileData} renameFileData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyFileOrFolder(accountId, projectId, renameFileData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling copyFileOrFolder.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling copyFileOrFolder.");
            }
            // verify required parameter 'renameFileData' is not null or undefined
            if (renameFileData === null || renameFileData === undefined) {
                throw new RequiredError("renameFileData", "Required parameter renameFileData was null or undefined when calling copyFileOrFolder.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/file/copy`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof renameFileData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(renameFileData !== undefined ? renameFileData : {})
                : renameFileData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(accountId, projectId, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteFile.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling deleteFile.");
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError("file", "Required parameter file was null or undefined when calling deleteFile.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/file`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (file !== undefined) {
                localVarQueryParameter["file"] = file;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Delete folder
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} folder Folder path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder(accountId, projectId, folder, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling deleteFolder.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling deleteFolder.");
            }
            // verify required parameter 'folder' is not null or undefined
            if (folder === null || folder === undefined) {
                throw new RequiredError("folder", "Required parameter folder was null or undefined when calling deleteFolder.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/folder`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "DELETE" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (folder !== undefined) {
                localVarQueryParameter["folder"] = folder;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Reset project state to the previous sync state
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} [modificationId] Revision.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discardChanges(accountId, projectId, modificationId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling discardChanges.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling discardChanges.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/reset`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (modificationId !== undefined) {
                localVarQueryParameter["modificationId"] = modificationId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Export zip archive with bot content.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportZip(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling exportZip.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling exportZip.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/export`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get binary file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBinaryFile(accountId, projectId, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getBinaryFile.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getBinaryFile.");
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError("file", "Required parameter file was null or undefined when calling getBinaryFile.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/binaryFile`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (file !== undefined) {
                localVarQueryParameter["file"] = file;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary branches
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranches(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getBranches.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getBranches.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/branches`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {number} [lastModified] Last modified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(accountId, projectId, file, lastModified, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getFile.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getFile.");
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError("file", "Required parameter file was null or undefined when calling getFile.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/file`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (file !== undefined) {
                localVarQueryParameter["file"] = file;
            }
            if (lastModified !== undefined) {
                localVarQueryParameter["lastModified"] = lastModified;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {string} [modificationId] Revision.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileByRevision(accountId, projectId, file, modificationId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getFileByRevision.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getFileByRevision.");
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError("file", "Required parameter file was null or undefined when calling getFileByRevision.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/file-by-revision`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (file !== undefined) {
                localVarQueryParameter["file"] = file;
            }
            if (modificationId !== undefined) {
                localVarQueryParameter["modificationId"] = modificationId;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary history
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getHistory.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getHistory.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/history`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary refs
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefs(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getRefs.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getRefs.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/refs`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Get file list
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTree(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling getTree.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling getTree.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/tree`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Pull
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [commit] If true, importing a project as a ZIP file commits the changes to history (local storage or Git).
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importZip(accountId, projectId, commit, file, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling importZip.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling importZip.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/import`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            const localVarFormParams = new FormData();
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (commit !== undefined) {
                localVarQueryParameter["commit"] = commit;
            }
            if (file !== undefined) {
                localVarFormParams.append("file", file);
            }
            localVarHeaderParameter["Content-Type"] = "multipart/form-data";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            localVarRequestOptions.data = localVarFormParams;
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Pull
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pull(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling pull.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling pull.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/pull`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Rename file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {RenameFileData} renameFileData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameFileOrFolder(accountId, projectId, renameFileData, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling renameFileOrFolder.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling renameFileOrFolder.");
            }
            // verify required parameter 'renameFileData' is not null or undefined
            if (renameFileData === null || renameFileData === undefined) {
                throw new RequiredError("renameFileData", "Required parameter renameFileData was null or undefined when calling renameFileOrFolder.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/file/rename`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof renameFileData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(renameFileData !== undefined ? renameFileData : {})
                : renameFileData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         *
         * @summary Update file content
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {FileContentData} fileContentData
         * @param {boolean} [force] Force.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeFile(accountId, projectId, file, fileContentData, force, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling writeFile.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling writeFile.");
            }
            // verify required parameter 'file' is not null or undefined
            if (file === null || file === undefined) {
                throw new RequiredError("file", "Required parameter file was null or undefined when calling writeFile.");
            }
            // verify required parameter 'fileContentData' is not null or undefined
            if (fileContentData === null || fileContentData === undefined) {
                throw new RequiredError("fileContentData", "Required parameter fileContentData was null or undefined when calling writeFile.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/content/file`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            if (file !== undefined) {
                localVarQueryParameter["file"] = file;
            }
            if (force !== undefined) {
                localVarQueryParameter["force"] = force;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof fileContentData !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(fileContentData !== undefined ? fileContentData : {})
                : fileContentData || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * StorageApi - functional programming interface
 * @export
 */
export const StorageApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Update project content for backward compatibility with BotAdmin
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {BatchUpdateContentData} batchUpdateContentData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdate(accountId, projectId, batchUpdateContentData, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).batchUpdate(accountId, projectId, batchUpdateContentData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Cleanup mirror folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanupMirrorFolder(options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).cleanupMirrorFolder(options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Push
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {CommitAndPushData} commitAndPushData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitAndPush(accountId, projectId, commitAndPushData, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).commitAndPush(accountId, projectId, commitAndPushData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Copy file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {RenameFileData} renameFileData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyFileOrFolder(accountId, projectId, renameFileData, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).copyFileOrFolder(accountId, projectId, renameFileData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(accountId, projectId, file, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).deleteFile(accountId, projectId, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Delete folder
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} folder Folder path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder(accountId, projectId, folder, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).deleteFolder(accountId, projectId, folder, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Reset project state to the previous sync state
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} [modificationId] Revision.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discardChanges(accountId, projectId, modificationId, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).discardChanges(accountId, projectId, modificationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Export zip archive with bot content.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportZip(accountId, projectId, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).exportZip(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get binary file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBinaryFile(accountId, projectId, file, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getBinaryFile(accountId, projectId, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary branches
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranches(accountId, projectId, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getBranches(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {number} [lastModified] Last modified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(accountId, projectId, file, lastModified, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getFile(accountId, projectId, file, lastModified, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {string} [modificationId] Revision.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileByRevision(accountId, projectId, file, modificationId, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getFileByRevision(accountId, projectId, file, modificationId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary history
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(accountId, projectId, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getHistory(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary refs
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefs(accountId, projectId, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getRefs(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Get file list
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTree(accountId, projectId, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).getTree(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Pull
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [commit] If true, importing a project as a ZIP file commits the changes to history (local storage or Git).
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importZip(accountId, projectId, commit, file, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).importZip(accountId, projectId, commit, file, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Pull
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pull(accountId, projectId, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).pull(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Rename file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {RenameFileData} renameFileData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameFileOrFolder(accountId, projectId, renameFileData, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).renameFileOrFolder(accountId, projectId, renameFileData, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
        /**
         *
         * @summary Update file content
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {FileContentData} fileContentData
         * @param {boolean} [force] Force.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeFile(accountId, projectId, file, fileContentData, force, options) {
            const localVarAxiosArgs = StorageApiAxiosParamCreator(configuration).writeFile(accountId, projectId, file, fileContentData, force, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * StorageApi - factory interface
 * @export
 */
export const StorageApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Update project content for backward compatibility with BotAdmin
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {BatchUpdateContentData} batchUpdateContentData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        batchUpdate(accountId, projectId, batchUpdateContentData, options) {
            return StorageApiFp(configuration).batchUpdate(accountId, projectId, batchUpdateContentData, options)(axios, basePath);
        },
        /**
         *
         * @summary Cleanup mirror folder
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        cleanupMirrorFolder(options) {
            return StorageApiFp(configuration).cleanupMirrorFolder(options)(axios, basePath);
        },
        /**
         *
         * @summary Push
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {CommitAndPushData} commitAndPushData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        commitAndPush(accountId, projectId, commitAndPushData, options) {
            return StorageApiFp(configuration).commitAndPush(accountId, projectId, commitAndPushData, options)(axios, basePath);
        },
        /**
         *
         * @summary Copy file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {RenameFileData} renameFileData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        copyFileOrFolder(accountId, projectId, renameFileData, options) {
            return StorageApiFp(configuration).copyFileOrFolder(accountId, projectId, renameFileData, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFile(accountId, projectId, file, options) {
            return StorageApiFp(configuration).deleteFile(accountId, projectId, file, options)(axios, basePath);
        },
        /**
         *
         * @summary Delete folder
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} folder Folder path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFolder(accountId, projectId, folder, options) {
            return StorageApiFp(configuration).deleteFolder(accountId, projectId, folder, options)(axios, basePath);
        },
        /**
         *
         * @summary Reset project state to the previous sync state
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} [modificationId] Revision.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        discardChanges(accountId, projectId, modificationId, options) {
            return StorageApiFp(configuration).discardChanges(accountId, projectId, modificationId, options)(axios, basePath);
        },
        /**
         *
         * @summary Export zip archive with bot content.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        exportZip(accountId, projectId, options) {
            return StorageApiFp(configuration).exportZip(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get binary file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBinaryFile(accountId, projectId, file, options) {
            return StorageApiFp(configuration).getBinaryFile(accountId, projectId, file, options)(axios, basePath);
        },
        /**
         *
         * @summary branches
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getBranches(accountId, projectId, options) {
            return StorageApiFp(configuration).getBranches(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {number} [lastModified] Last modified.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFile(accountId, projectId, file, lastModified, options) {
            return StorageApiFp(configuration).getFile(accountId, projectId, file, lastModified, options)(axios, basePath);
        },
        /**
         *
         * @summary Get file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {string} [modificationId] Revision.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileByRevision(accountId, projectId, file, modificationId, options) {
            return StorageApiFp(configuration).getFileByRevision(accountId, projectId, file, modificationId, options)(axios, basePath);
        },
        /**
         *
         * @summary history
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getHistory(accountId, projectId, options) {
            return StorageApiFp(configuration).getHistory(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary refs
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getRefs(accountId, projectId, options) {
            return StorageApiFp(configuration).getRefs(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Get file list
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTree(accountId, projectId, options) {
            return StorageApiFp(configuration).getTree(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Pull
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {boolean} [commit] If true, importing a project as a ZIP file commits the changes to history (local storage or Git).
         * @param {any} [file]
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        importZip(accountId, projectId, commit, file, options) {
            return StorageApiFp(configuration).importZip(accountId, projectId, commit, file, options)(axios, basePath);
        },
        /**
         *
         * @summary Pull
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        pull(accountId, projectId, options) {
            return StorageApiFp(configuration).pull(accountId, projectId, options)(axios, basePath);
        },
        /**
         *
         * @summary Rename file
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {RenameFileData} renameFileData
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        renameFileOrFolder(accountId, projectId, renameFileData, options) {
            return StorageApiFp(configuration).renameFileOrFolder(accountId, projectId, renameFileData, options)(axios, basePath);
        },
        /**
         *
         * @summary Update file content
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {string} file File path inside bot project
         * @param {FileContentData} fileContentData
         * @param {boolean} [force] Force.
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        writeFile(accountId, projectId, file, fileContentData, force, options) {
            return StorageApiFp(configuration).writeFile(accountId, projectId, file, fileContentData, force, options)(axios, basePath);
        },
    };
};
/**
 * StorageApi - object-oriented interface
 * @export
 * @class StorageApi
 * @extends {BaseAPI}
 */
export class StorageApi extends BaseAPI {
    /**
     *
     * @summary Update project content for backward compatibility with BotAdmin
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {BatchUpdateContentData} batchUpdateContentData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    batchUpdate(accountId, projectId, batchUpdateContentData, options) {
        return StorageApiFp(this.configuration).batchUpdate(accountId, projectId, batchUpdateContentData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Cleanup mirror folder
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    cleanupMirrorFolder(options) {
        return StorageApiFp(this.configuration).cleanupMirrorFolder(options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Push
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {CommitAndPushData} commitAndPushData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    commitAndPush(accountId, projectId, commitAndPushData, options) {
        return StorageApiFp(this.configuration).commitAndPush(accountId, projectId, commitAndPushData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Copy file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {RenameFileData} renameFileData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    copyFileOrFolder(accountId, projectId, renameFileData, options) {
        return StorageApiFp(this.configuration).copyFileOrFolder(accountId, projectId, renameFileData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    deleteFile(accountId, projectId, file, options) {
        return StorageApiFp(this.configuration).deleteFile(accountId, projectId, file, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Delete folder
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} folder Folder path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    deleteFolder(accountId, projectId, folder, options) {
        return StorageApiFp(this.configuration).deleteFolder(accountId, projectId, folder, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Reset project state to the previous sync state
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} [modificationId] Revision.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    discardChanges(accountId, projectId, modificationId, options) {
        return StorageApiFp(this.configuration).discardChanges(accountId, projectId, modificationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Export zip archive with bot content.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    exportZip(accountId, projectId, options) {
        return StorageApiFp(this.configuration).exportZip(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get binary file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    getBinaryFile(accountId, projectId, file, options) {
        return StorageApiFp(this.configuration).getBinaryFile(accountId, projectId, file, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary branches
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    getBranches(accountId, projectId, options) {
        return StorageApiFp(this.configuration).getBranches(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {number} [lastModified] Last modified.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    getFile(accountId, projectId, file, lastModified, options) {
        return StorageApiFp(this.configuration).getFile(accountId, projectId, file, lastModified, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {string} [modificationId] Revision.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    getFileByRevision(accountId, projectId, file, modificationId, options) {
        return StorageApiFp(this.configuration).getFileByRevision(accountId, projectId, file, modificationId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary history
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    getHistory(accountId, projectId, options) {
        return StorageApiFp(this.configuration).getHistory(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary refs
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    getRefs(accountId, projectId, options) {
        return StorageApiFp(this.configuration).getRefs(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Get file list
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    getTree(accountId, projectId, options) {
        return StorageApiFp(this.configuration).getTree(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Pull
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {boolean} [commit] If true, importing a project as a ZIP file commits the changes to history (local storage or Git).
     * @param {any} [file]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    importZip(accountId, projectId, commit, file, options) {
        return StorageApiFp(this.configuration).importZip(accountId, projectId, commit, file, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Pull
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    pull(accountId, projectId, options) {
        return StorageApiFp(this.configuration).pull(accountId, projectId, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Rename file
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {RenameFileData} renameFileData
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    renameFileOrFolder(accountId, projectId, renameFileData, options) {
        return StorageApiFp(this.configuration).renameFileOrFolder(accountId, projectId, renameFileData, options)(this.axios, this.basePath);
    }
    /**
     *
     * @summary Update file content
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {string} file File path inside bot project
     * @param {FileContentData} fileContentData
     * @param {boolean} [force] Force.
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof StorageApi
     */
    writeFile(accountId, projectId, file, fileContentData, force, options) {
        return StorageApiFp(this.configuration).writeFile(accountId, projectId, file, fileContentData, force, options)(this.axios, this.basePath);
    }
}
/**
 * SystemEntityApi - axios parameter creator
 * @export
 */
export const SystemEntityApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Download csv entity file
         * @param {string} entityName system entity name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSystemEntity(entityName, options = {}) {
            // verify required parameter 'entityName' is not null or undefined
            if (entityName === null || entityName === undefined) {
                throw new RequiredError("entityName", "Required parameter entityName was null or undefined when calling downloadSystemEntity.");
            }
            const localVarPath = `/api/editorbe/system/entity/{entityName}.csv`.replace(`{${"entityName"}}`, encodeURIComponent(String(entityName)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * SystemEntityApi - functional programming interface
 * @export
 */
export const SystemEntityApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Download csv entity file
         * @param {string} entityName system entity name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSystemEntity(entityName, options) {
            const localVarAxiosArgs = SystemEntityApiAxiosParamCreator(configuration).downloadSystemEntity(entityName, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * SystemEntityApi - factory interface
 * @export
 */
export const SystemEntityApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Download csv entity file
         * @param {string} entityName system entity name
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        downloadSystemEntity(entityName, options) {
            return SystemEntityApiFp(configuration).downloadSystemEntity(entityName, options)(axios, basePath);
        },
    };
};
/**
 * SystemEntityApi - object-oriented interface
 * @export
 * @class SystemEntityApi
 * @extends {BaseAPI}
 */
export class SystemEntityApi extends BaseAPI {
    /**
     *
     * @summary Download csv entity file
     * @param {string} entityName system entity name
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SystemEntityApi
     */
    downloadSystemEntity(entityName, options) {
        return SystemEntityApiFp(this.configuration).downloadSystemEntity(entityName, options)(this.axios, this.basePath);
    }
}
/**
 * ValidationApi - axios parameter creator
 * @export
 */
export const ValidationApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Validates the project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProject(accountId, projectId, options = {}) {
            // verify required parameter 'accountId' is not null or undefined
            if (accountId === null || accountId === undefined) {
                throw new RequiredError("accountId", "Required parameter accountId was null or undefined when calling validateProject.");
            }
            // verify required parameter 'projectId' is not null or undefined
            if (projectId === null || projectId === undefined) {
                throw new RequiredError("projectId", "Required parameter projectId was null or undefined when calling validateProject.");
            }
            const localVarPath = `/api/editorbe/accounts/{accountId}/projects/{projectId}/validate`
                .replace(`{${"accountId"}}`, encodeURIComponent(String(accountId)))
                .replace(`{${"projectId"}}`, encodeURIComponent(String(projectId)));
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "GET" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * ValidationApi - functional programming interface
 * @export
 */
export const ValidationApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Validates the project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProject(accountId, projectId, options) {
            const localVarAxiosArgs = ValidationApiAxiosParamCreator(configuration).validateProject(accountId, projectId, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * ValidationApi - factory interface
 * @export
 */
export const ValidationApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Validates the project.
         * @param {number} accountId User identifier.
         * @param {string} projectId Project identifier. Use project-short-name here
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        validateProject(accountId, projectId, options) {
            return ValidationApiFp(configuration).validateProject(accountId, projectId, options)(axios, basePath);
        },
    };
};
/**
 * ValidationApi - object-oriented interface
 * @export
 * @class ValidationApi
 * @extends {BaseAPI}
 */
export class ValidationApi extends BaseAPI {
    /**
     *
     * @summary Validates the project.
     * @param {number} accountId User identifier.
     * @param {string} projectId Project identifier. Use project-short-name here
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ValidationApi
     */
    validateProject(accountId, projectId, options) {
        return ValidationApiFp(this.configuration).validateProject(accountId, projectId, options)(this.axios, this.basePath);
    }
}
/**
 * WebhooksApi - axios parameter creator
 * @export
 */
export const WebhooksApiAxiosParamCreator = function (configuration) {
    return {
        /**
         *
         * @summary Notify system about repository push event.
         * @param {string} body Push data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notify(body, options = {}) {
            // verify required parameter 'body' is not null or undefined
            if (body === null || body === undefined) {
                throw new RequiredError("body", "Required parameter body was null or undefined when calling notify.");
            }
            const localVarPath = `/api/editorbe/repository-webhook`;
            const localVarUrlObj = globalImportUrl.parse(localVarPath, true);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }
            const localVarRequestOptions = Object.assign(Object.assign({ method: "POST" }, baseOptions), options);
            const localVarHeaderParameter = {};
            const localVarQueryParameter = {};
            // authentication bearerAuth required
            // http bearer authentication required
            if (configuration && configuration.accessToken) {
                const accessToken = typeof configuration.accessToken === "function"
                    ? configuration.accessToken()
                    : configuration.accessToken;
                localVarHeaderParameter["Authorization"] = "Bearer " + accessToken;
            }
            localVarHeaderParameter["Content-Type"] = "application/json";
            localVarUrlObj.query = Object.assign(Object.assign(Object.assign({}, localVarUrlObj.query), localVarQueryParameter), options.query);
            // fix override query string Detail: https://stackoverflow.com/a/7517673/1077943
            delete localVarUrlObj.search;
            localVarRequestOptions.headers = Object.assign(Object.assign({}, localVarHeaderParameter), options.headers);
            const needsSerialization = typeof body !== "string" ||
                localVarRequestOptions.headers["Content-Type"] === "application/json";
            localVarRequestOptions.data = needsSerialization
                ? JSON.stringify(body !== undefined ? body : {})
                : body || "";
            return {
                url: globalImportUrl.format(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    };
};
/**
 * WebhooksApi - functional programming interface
 * @export
 */
export const WebhooksApiFp = function (configuration) {
    return {
        /**
         *
         * @summary Notify system about repository push event.
         * @param {string} body Push data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notify(body, options) {
            const localVarAxiosArgs = WebhooksApiAxiosParamCreator(configuration).notify(body, options);
            return (axios = globalAxios, basePath = BASE_PATH) => {
                const axiosRequestArgs = Object.assign(Object.assign({}, localVarAxiosArgs.options), { url: basePath + localVarAxiosArgs.url });
                return axios.request(axiosRequestArgs);
            };
        },
    };
};
/**
 * WebhooksApi - factory interface
 * @export
 */
export const WebhooksApiFactory = function (configuration, basePath, axios) {
    return {
        /**
         *
         * @summary Notify system about repository push event.
         * @param {string} body Push data
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        notify(body, options) {
            return WebhooksApiFp(configuration).notify(body, options)(axios, basePath);
        },
    };
};
/**
 * WebhooksApi - object-oriented interface
 * @export
 * @class WebhooksApi
 * @extends {BaseAPI}
 */
export class WebhooksApi extends BaseAPI {
    /**
     *
     * @summary Notify system about repository push event.
     * @param {string} body Push data
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof WebhooksApi
     */
    notify(body, options) {
        return WebhooksApiFp(this.configuration).notify(body, options)(this.axios, this.basePath);
    }
}
