export var reportsLocalization = {
    'Reports:Header:Title': {
        eng: 'Reports',
        ru: 'Отчеты',
    },
    'Reports:Header:Subtitle': {
        eng: 'How to use reports',
        ru: 'Как работать с отчетами',
    },
    'Reports:Header:Subtitle:Link': {
        eng: '#{config.botadmin.helpUrl}/platform_ux/reports',
        ru: '#{config.botadmin.helpUrl}/platform_ux/reports',
    },
    'Reports:Tabs:activity': {
        eng: 'Activity',
        ru: 'Активность',
    },
    'Reports:Tabs:efficiency': {
        eng: 'Efficiency',
        ru: 'Эффективность',
    },
    'Reports:Tabs:session_topic': {
        eng: 'Topics',
        ru: 'Темы',
    },
    'Reports:Tabs:session_result': {
        eng: 'Results',
        ru: 'Результаты',
    },
    'Reports:Tabs:scenario_action': {
        eng: 'Actions',
        ru: 'Действия',
    },
    'Reports:Metrics:Title:Users': {
        eng: 'Users',
        ru: 'Пользователи',
    },
    'Reports:Metrics:Title:Sessions': {
        eng: 'Sessions',
        ru: 'Сессии',
    },
    'Reports:Metrics:Title:Messages': {
        eng: 'Messages',
        ru: 'Сообщения',
    },
    'Reports:Metrics:Title:Automation': {
        eng: 'Automation',
        ru: 'Автоматизация',
    },
    'Reports:Metrics:Title:Evaluation': {
        eng: 'Evaluation from users',
        ru: 'Оценка от пользователей',
    },
    'Reports:Button:Table': {
        eng: 'Show table',
        ru: 'Показать таблицу',
    },
    'Reports:Button:Export': {
        eng: 'Export data',
        ru: 'Экспортировать данные',
    },
    'Reports:Table:Date': {
        eng: 'Date',
        ru: 'Дата',
    },
    'Reports:Table:Empty': {
        eng: 'No data for the table',
        ru: 'Нет данных для таблицы',
    },
    'Reports:Progress:Other': {
        eng: 'Other',
        ru: 'Прочее',
    },
    'Reports:Metrics:Title:Evaluation:Distribution': {
        eng: 'Distribution',
        ru: 'Распределение',
    },
    'Reports:Metrics:Title:Evaluation:Medium': {
        eng: 'Medium',
        ru: 'Средняя',
    },
    'Reports:Metrics:Title:Dynamics': {
        eng: 'Dynamics',
        ru: 'Динамика',
    },
    'Reports:Metrics:Title:Distribution': {
        eng: 'Distribution',
        ru: 'Распределение',
    },
    'Reports:Metrics:NotSelected:session_topic': {
        eng: 'Topic not selected',
        ru: 'Тема не выбрана',
    },
    'Reports:Metrics:NotSelected:session_result': {
        eng: 'Result not selected',
        ru: 'Результат не выбран',
    },
    'Reports:Metrics:NotSelected:scenario_action': {
        eng: 'Action not selected',
        ru: 'Действие не выбрано',
    },
    'Reports:Table:Header:Topics': {
        eng: 'Topics',
        ru: 'Темы',
    },
    'Reports:Table:Header:Results': {
        eng: 'Results',
        ru: 'Результаты',
    },
    'Reports:Table:Header:Actions': {
        eng: 'Actions',
        ru: 'Действия',
    },
    'Reports:Table:Header:Session': {
        eng: 'Number of sessions',
        ru: 'Количество сессий',
    },
    'Reports:Table:Header:Total': {
        eng: 'Total',
        ru: 'Всего',
    },
    'Reports:Table:Header:ProportionSession': {
        eng: 'Percentage of all <br> sessions',
        ru: 'Процент от всех <br> сессий',
    },
    'Reports:Table:Header:ProportionActions': {
        eng: 'Percentage of all <br> sessions',
        ru: 'Процент от всех <br> сессий',
    },
    'Reports:Table:Header:Others': {
        eng: 'Others',
        ru: 'Остальные',
    },
    'Reports:Metrics:Digit:newCount_one': {
        eng: 'new',
        ru: 'новый',
    },
    'Reports:Metrics:Digit:newCount_few': {
        eng: 'new',
        ru: 'новых',
    },
    'Reports:Metrics:Digit:newCount_many': {
        eng: 'new',
        ru: 'новых',
    },
    'Reports:Metrics:Digit:newCount_other': {
        eng: 'new',
        ru: 'новых',
    },
    'Reports:Metrics:Digit:returnedCount_one': {
        eng: 'returning',
        ru: 'вернувшийся',
    },
    'Reports:Metrics:Digit:returnedCount_few': {
        eng: 'returning',
        ru: 'вернувшихся',
    },
    'Reports:Metrics:Digit:returnedCount_many': {
        eng: 'returning',
        ru: 'вернувшихся',
    },
    'Reports:Metrics:Digit:returnedCount_other': {
        eng: 'returning',
        ru: 'вернувшихся',
    },
    'Reports:Metrics:Digit:totalCount_one': {
        eng: 'total',
        ru: 'всего',
    },
    'Reports:Metrics:Digit:totalCount_few': {
        eng: 'total',
        ru: 'всего',
    },
    'Reports:Metrics:Digit:totalCount_many': {
        eng: 'total',
        ru: 'всего',
    },
    'Reports:Metrics:Digit:totalCount_other': {
        eng: 'total',
        ru: 'всего',
    },
    'Reports:Metrics:Digit:withMessagesCount_one': {
        eng: 'with messages <br> from users',
        ru: 'с сообщениями <br> от пользователей',
    },
    'Reports:Metrics:Digit:withMessagesCount_few': {
        eng: 'with messages <br> from users',
        ru: 'с сообщениями <br> от пользователей',
    },
    'Reports:Metrics:Digit:withMessagesCount_many': {
        eng: 'with messages <br> from users',
        ru: 'с сообщениями <br> от пользователей',
    },
    'Reports:Metrics:Digit:withMessagesCount_other': {
        eng: 'with messages <br> from users',
        ru: 'с сообщениями <br> от пользователей',
    },
    'Reports:Metrics:Digit:withoutMessagesCount_one': {
        eng: 'without messages',
        ru: 'без сообщений',
    },
    'Reports:Metrics:Digit:withoutMessagesCount_few': {
        eng: 'without messages',
        ru: 'без сообщений',
    },
    'Reports:Metrics:Digit:withoutMessagesCount_many': {
        eng: 'without messages',
        ru: 'без сообщений',
    },
    'Reports:Metrics:Digit:withoutMessagesCount_other': {
        eng: 'without messages',
        ru: 'без сообщений',
    },
    'Reports:Metrics:Digit:recognizedCount_one': {
        eng: 'recognized',
        ru: 'распознанное',
    },
    'Reports:Metrics:Digit:recognizedCount_few': {
        eng: 'recognized',
        ru: 'распознанных',
    },
    'Reports:Metrics:Digit:recognizedCount_many': {
        eng: 'recognized',
        ru: 'распознанных',
    },
    'Reports:Metrics:Digit:recognizedCount_other': {
        eng: 'recognized',
        ru: 'распознанных',
    },
    'Reports:Metrics:Digit:systemCount_one': {
        eng: 'system',
        ru: 'системное',
    },
    'Reports:Metrics:Digit:systemCount_few': {
        eng: 'system',
        ru: 'системных',
    },
    'Reports:Metrics:Digit:systemCount_many': {
        eng: 'system',
        ru: 'системных',
    },
    'Reports:Metrics:Digit:systemCount_other': {
        eng: 'system',
        ru: 'системных',
    },
    'Reports:Metrics:Digit:unrecognizedCount_one': {
        eng: 'unrecognized',
        ru: 'нераспознанное',
    },
    'Reports:Metrics:Digit:unrecognizedCount_few': {
        eng: 'unrecognized',
        ru: 'нераспознанных',
    },
    'Reports:Metrics:Digit:unrecognizedCount_many': {
        eng: 'unrecognized',
        ru: 'нераспознанных',
    },
    'Reports:Metrics:Digit:unrecognizedCount_other': {
        eng: 'unrecognized',
        ru: 'нераспознанных',
    },
    'Reports:Metrics:Digit:automatedSessions_one': {
        eng: 'automated <br> session',
        ru: 'автоматизированная <br> сессия',
    },
    'Reports:Metrics:Digit:automatedSessions_few': {
        eng: 'automated <br> sessions',
        ru: 'автоматизированные <br> сессии',
    },
    'Reports:Metrics:Digit:automatedSessions_many': {
        eng: 'automated <br> sessions',
        ru: 'автоматизированных <br> сессий',
    },
    'Reports:Metrics:Digit:automatedSessions_other': {
        eng: 'automated <br> sessions',
        ru: 'автоматизированных <br> сессий',
    },
    'Reports:Metrics:Digit:automationPercentage_one': {
        eng: 'session <br> automated',
        ru: 'сессия <br> автоматизирована',
    },
    'Reports:Metrics:Digit:automationPercentage_few': {
        eng: 'sessions <br> automated',
        ru: 'сессии <br> автоматизировано',
    },
    'Reports:Metrics:Digit:automationPercentage_many': {
        eng: 'sessions <br> automated',
        ru: 'сессий <br> автоматизировано',
    },
    'Reports:Metrics:Digit:automationPercentage_other': {
        eng: 'sessions <br> automated',
        ru: 'сессий <br> автоматизировано',
    },
    'Reports:Metrics:Digit:nonAutomatedSessions_one': {
        eng: 'non-automated <br> session',
        ru: 'неавтоматизированная <br> сессия',
    },
    'Reports:Metrics:Digit:nonAutomatedSessions_few': {
        eng: 'non-automated <br> sessions',
        ru: 'неавтоматизированные <br> сессии',
    },
    'Reports:Metrics:Digit:nonAutomatedSessions_many': {
        eng: 'non-automated <br> sessions',
        ru: 'неавтоматизированных <br> сессий',
    },
    'Reports:Metrics:Digit:nonAutomatedSessions_other': {
        eng: 'non-automated <br> sessions',
        ru: 'неавтоматизированных <br> сессий',
    },
    'Reports:Metrics:Digit:averageNps_one': {
        eng: 'average score <br> for the period',
        ru: 'средняя оценка <br> за период',
    },
    'Reports:Metrics:Digit:averageNps_few': {
        eng: 'average score <br> for the period',
        ru: 'средняя оценка <br> за период',
    },
    'Reports:Metrics:Digit:averageNps_many': {
        eng: 'average score <br> for the period',
        ru: 'средняя оценка <br> за период',
    },
    'Reports:Metrics:Digit:averageNps_other': {
        eng: 'average score <br> for the period',
        ru: 'средняя оценка <br> за период',
    },
    'Reports:Table:SelectChart': {
        eng: 'Select a topic in the table to view the dynamic',
        ru: 'Выберите тему в таблице, чтобы просмотреть динамику',
    },
    'Reports:Metrics:NotSelected:Chart:session_topic': {
        eng: 'Select a topic in the table to view the dynamic',
        ru: 'Выберите тему в таблице, чтобы просмотреть динамику',
    },
    'Reports:Metrics:NotSelected:Chart:session_result': {
        eng: 'Select a result in the table to view the dynamics',
        ru: 'Выберите результат в таблице, чтобы просмотреть динамику',
    },
    'Reports:Metrics:NotSelected:Chart:scenario_action': {
        eng: 'Select an action in the table to view the dynamics',
        ru: 'Выберите действие в таблице, чтобы просмотреть динамику',
    },
    'Reports:Checkbox:stats.clients.activity.new': {
        eng: 'New',
        ru: 'Новые',
    },
    'Reports:Checkbox:stats.clients.activity.returned': {
        eng: 'Returning',
        ru: 'Вернувшиеся',
    },
    'Reports:Checkbox:stats.clients.activity': {
        eng: 'Total',
        ru: 'Всего',
    },
    'Reports:Checkbox:stats.sessions.activity.with.messages': {
        eng: 'With messages from users',
        ru: 'С сообщениями от пользователей',
    },
    'Reports:Checkbox:stats.sessions.activity.without.messages': {
        eng: 'Without messages',
        ru: 'Без сообщений',
    },
    'Reports:Checkbox:stats.messages.activity.recognized': {
        eng: 'Recognized',
        ru: 'Распознанные',
    },
    'Reports:Checkbox:stats.messages.activity.unrecognized': {
        eng: 'Unrecognized',
        ru: 'Нераспознанные',
    },
    'Reports:Checkbox:stats.messages.activity.system': {
        eng: 'System',
        ru: 'Системные',
    },
    'Reports:Checkbox:stats.automated.automation.efficiency': {
        eng: 'Automated',
        ru: 'Автоматизированные',
    },
    'Reports:Checkbox:stats.not.automated.automation.efficiency': {
        eng: 'Non-automated',
        ru: 'Неавтоматизированные',
    },
    'Reports:Checkbox:stats.automated.percentage.automation.efficiency': {
        eng: '% of automation',
        ru: '% автоматизации',
    },
    'Reports:Checkbox:stats.nps.average.automation.efficiency': {
        eng: 'Average score',
        ru: 'Средняя оценка',
    },
    'Reports:Checkbox:stats.session.topic.distribution.dynamic': {
        eng: 'Quantity',
        ru: 'Количество',
    },
    'Reports:Checkbox:stats.session.result.distribution.dynamic': {
        eng: 'Quantity',
        ru: 'Количество',
    },
    'Reports:Checkbox:stats.scenario.action.distribution.dynamic': {
        eng: 'Total',
        ru: 'Всего',
    },
    'Reports:Table:stats.clients.activity.new': {
        eng: 'New',
        ru: 'Новые',
    },
    'Reports:Table:stats.clients.activity.returned': {
        eng: 'Returning',
        ru: 'Вернувшиеся',
    },
    'Reports:Table:stats.clients.activity': {
        eng: 'Total',
        ru: 'Всего',
    },
    'Reports:Table:stats.sessions.activity.with.messages': {
        eng: 'With messages <br> <span class="gray-500">(per user)</span>',
        ru: 'С сообщениями <br> <span class="gray-500">(на пользователя)</span>',
    },
    'Reports:Table:stats.sessions.activity.without.messages': {
        eng: 'Without messages <br> <span class="gray-500">(per user)</span>',
        ru: 'Без сообщений <br> <span class="gray-500">(на пользователя)</span>',
    },
    'Reports:Table:stats.sessions.activity': {
        eng: 'Total <br> <span class="gray-500">(per user)</span>',
        ru: 'Всего <br> <span class="gray-500">(на пользователя)</span>',
    },
    'Reports:Table:stats.messages.activity.recognized': {
        eng: 'Recognized<br> <span class="gray-500">(per user)</span>',
        ru: 'Распознанные<br> <span class="gray-500">(на пользователя)</span>',
    },
    'Reports:Table:stats.messages.activity.unrecognized': {
        eng: 'Unrecognized<br> <span class="gray-500">(per user)</span>',
        ru: 'Нераспознанные<br> <span class="gray-500">(на пользователя)</span>',
    },
    'Reports:Table:stats.messages.activity.system': {
        eng: 'System<br> <span class="gray-500">(per user)</span>',
        ru: 'Системные<br> <span class="gray-500">(на пользователя)</span>',
    },
    'Reports:Table:stats.messages.activity': {
        eng: 'Total<br> <span class="gray-500">(per user)</span>',
        ru: 'Всего<br> <span class="gray-500">(на пользователя)</span>',
    },
    'Reports:Table:stats.automated.automation.efficiency': {
        eng: 'Automated',
        ru: 'Автоматизированные',
    },
    'Reports:Table:stats.not.automated.automation.efficiency': {
        eng: 'Non-automated',
        ru: 'Неавтоматизированные',
    },
    'Reports:Table:stats.automated.percentage.automation.efficiency': {
        eng: 'Automation',
        ru: 'Автоматизация',
    },
    'Reports:Table:stats.nps.average.automation.efficiency': {
        eng: 'Medium',
        ru: 'Средняя',
    },
    'Reports:Table:stats.session.topic.distribution.dynamic': {
        eng: 'Amount',
        ru: 'Кол-во',
    },
    'Reports:Table:stats.session.result.distribution.dynamic': {
        eng: 'Amount',
        ru: 'Кол-во',
    },
    'Reports:Table:stats.scenario.action.distribution.dynamic': {
        eng: 'Total',
        ru: 'Всего',
    },
    'Reports:Table:stats.scenario.action.distribution.dynamic.share.of.sessions': {
        eng: 'Percentage of all sessions',
        ru: 'Процент от всех сессий',
    },
    'Reports:Table:stats.session.topic.distribution.dynamic.share.of.sessions': {
        eng: 'Percentage of all sessions',
        ru: 'Процент от всех сессий',
    },
    'Reports:Table:stats.session.result.distribution.dynamic.share.of.sessions': {
        eng: 'Percentage of all sessions',
        ru: 'Процент от всех сессий',
    },
};
