import { axios } from './functions';

export const saveAudioToServer = async (file: File, { signal }: { signal: AbortSignal }): Promise<string> => {
  const fd = new FormData();
  fd.append('files', file);

  const payload = await axios.post('/restapi/file/upload/audios', fd, { signal });
  const payloadAudios = payload.data;
  const key = Object.keys(payloadAudios)[0];
  return payloadAudios[key];
};

export const saveFileToServer = async (file: File, { signal }: { signal: AbortSignal }): Promise<string> => {
  const fd = new FormData();
  fd.append('file', file);

  const payload = await axios.post('/restapi/file/upload', fd, { signal });
  return payload.data;
};
