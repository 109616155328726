import React, { useState, useCallback, useMemo, useEffect, useRef } from 'react';
import DOMPurify from 'dompurify';
import classNames from 'classnames';
import { Button, DropDownHint, Icon, useToggle } from '../index';
import './styles.scss';
import ErrorText from '../ErrorText';
const RichTextArea = function ({ onChange, value = '', richValue = '', placeholder, onKeyDown, id, dataTestId, autoFocus, className = '', name = 'text', invalid, errorText, minRows = 1, maxRows, innerClassName, onFocus, disabled, onBlur: onBlurProp, readOnly, clearable, maxLength, append, dropDownHint, hint, }) {
    const wrapperDivRef = useRef(null);
    const [innerValue, setInnerValue] = useState(value);
    const [isDisableRichValue, enableRichValue, disableRichValue] = useToggle(false);
    const replicatedValue = useMemo(() => innerValue === null || innerValue === void 0 ? void 0 : innerValue.replaceAll(/\n/g, '$&_'), [innerValue]);
    if (!richValue) {
        richValue = value;
    }
    const onInnerInput = useCallback(e => {
        // @ts-ignore
        const el = e === null || e === void 0 ? void 0 : e.target;
        setInnerValue(el.value);
        onChange(el.value, name, e);
    }, [onChange, name]);
    useEffect(() => {
        if (!wrapperDivRef.current || !maxRows)
            return;
        const paddingValues = {
            top: Number.parseFloat(window.getComputedStyle(wrapperDivRef.current).paddingTop),
            bottom: Number.parseFloat(window.getComputedStyle(wrapperDivRef.current).paddingBottom),
        };
        wrapperDivRef.current.style.maxHeight =
            String(maxRows * Number.parseFloat(window.getComputedStyle(wrapperDivRef.current).lineHeight) +
                paddingValues.top +
                paddingValues.bottom) + 'px';
        wrapperDivRef.current.style.overflowY = 'auto';
    }, [maxRows]);
    const sanitizedRichText = useMemo(() => DOMPurify.sanitize(richValue, { ALLOWED_TAGS: ['span'], ALLOWED_ATTR: ['class'] }), [richValue]);
    const handleFocus = useCallback((event) => {
        enableRichValue();
        onFocus && onFocus(event);
    }, [enableRichValue, onFocus]);
    const handleLocalBlur = useCallback(event => {
        disableRichValue();
        onBlurProp && onBlurProp(event);
    }, [disableRichValue, onBlurProp]);
    useEffect(() => {
        setInnerValue(value);
    }, [value]);
    const clearValue = useCallback(() => {
        setInnerValue('');
        onChange('', name);
    }, [name, onChange]);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: classNames('textAreaLikeElement', { 'is-invalid': invalid, disabled, clearable }, className), "data-replicated-value": replicatedValue, ref: wrapperDivRef },
            richValue && !isDisableRichValue && (React.createElement("div", { className: 'textAreaLikeElement_text', dangerouslySetInnerHTML: { __html: sanitizedRichText } })),
            React.createElement("textarea", { name: name, id: id, rows: minRows, onFocus: handleFocus, onBlur: handleLocalBlur, onKeyDown: onKeyDown, onInput: onInnerInput, placeholder: placeholder, value: innerValue, "data-test-id": dataTestId, className: innerClassName, autoFocus: autoFocus, disabled: disabled, readOnly: readOnly, maxLength: maxLength }),
            append && React.createElement("div", { className: 'inner-append' }, append),
            clearable && !disabled && (React.createElement(Button, { onClick: clearValue, flat: true, className: 'clear d-flex justify-content-center', withoutPadding: true, color: 'secondary' },
                React.createElement(Icon, { wrapperClassName: 'd-flex', name: 'faTimesCircle', color: 'secondary' })))),
        errorText && React.createElement(ErrorText, { text: errorText }),
        hint && !errorText && React.createElement("span", { className: 'hint', dangerouslySetInnerHTML: { __html: hint } }),
        dropDownHint ? React.createElement(DropDownHint, { dropDownHint: dropDownHint }) : null));
};
export const TextAreaLikeElement = React.memo(RichTextArea);
export default React.memo(RichTextArea);
