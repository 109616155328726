import React, { useContext, useMemo } from 'react';
import { Route, Switch } from 'react-router-dom';
import OAuthCallback from '../OAuthCallback';
import { OAuthBehaviour } from '../index';
export const OAuthContext = React.createContext({
    redirectUrl: '/oauth/callback',
});
export function useOAuthContext() {
    return useContext(OAuthContext);
}
export function useOAuthBehaviour() {
    const context = useContext(OAuthContext);
    return useMemo(() => new OAuthBehaviour(context.redirectUrl), [context]);
}
function CallbackWrapper({ children }) {
    const context = useContext(OAuthContext);
    return (React.createElement(Switch, null,
        React.createElement(Route, { exact: true, path: context.redirectUrl, component: OAuthCallback }),
        ";",
        React.createElement(Route, { render: () => children })));
}
const OAuthProvider = function ({ children }) {
    return (React.createElement(OAuthContext.Provider, { value: {
            redirectUrl: '/oauth/callback',
        } },
        React.createElement(CallbackWrapper, null, children)));
};
export default OAuthProvider;
